import React, { useEffect, useState } from "react";
import AxiosNavigate from "../../../../library/axios-navigate";
import { FormProvider, useForm } from "react-hook-form";
import { Header } from "../../../../components/Header";
import SearchBox from "../../../../components/Search";
import { PagingFooter } from "../../../../components/PagingFooter";
import Pagination from "react-js-pagination";
import PagesList from "../../../../components/PagesList";
import styled from "@emotion/styled";
import ButtonComponent from "../../../../components/Button";
import SideModal from "../../../../components/SideModal";
import { Content } from "../../../../library/styled-components";
import { useMutation } from "@tanstack/react-query";
import { TableStyle } from "../../../../library/styled-components";
import {
  getPartnersUserInfo,
  getPartnersUserList,
} from "../../../../library/admin-react-query";
import ImageEnlargedModal from "../../../../components/ImageEnlagedModal";
import moment from "moment";
import { BlockScrolling } from "../../../../bin/common.js";

const PartnersAdmin = () => {
  const methods = useForm();

  const [searchTxt, setSearchTxt] = useState(null); // 엔터
  const [searchValue, setSearchValue] = useState(""); // 키보드
  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [opendImageIndex, setOpendImageIndex] = useState(1);

  const [openedDetail, setOpenedDetail] = useState(false);
  const [opendImage, setOpendImage] = useState(false);

  BlockScrolling([openedDetail]);
  // 파트너 리스트 가져오기
  const { mutate: getPartnersUserListMutate, data: getPartnersUserListData } =
    useMutation({
      mutationKey: ["getPartnersUserList"],
      mutationFn: getPartnersUserList,
      onSuccess: async (data) => {
        setTotalCount(data.count);
      },
      onError: (error) => {
        console.log(error);
      },
    });

  // 파트너 상세 정보 가져오기
  const { mutate: getPartnersUserInfoMutate, data: getPartnersUserInfoData } =
    useMutation({
      mutationKey: ["getPartnersUserInfo"],
      mutationFn: getPartnersUserInfo,
      onSuccess: async (data) => {},
      onError: (error) => {
        console.log(error);
      },
    });

  useEffect(() => {
    const items = {
      searchTxt: searchTxt,
      withdrawYn: "Y",
      page: page,
      limit: limit,
    };
    getPartnersUserListMutate(items);
  }, [searchTxt, limit, page]);
  const handleImageIndex = (direction) => {
    if (direction === "right") {
      setOpendImageIndex(2);
    } else {
      setOpendImageIndex(1);
    }
  };
  return (
    <AxiosNavigate>
      <div style={{ paddingBottom: "30px" }}>
        <FormProvider {...methods}>
          <Header>
            <div>파트너 관리</div>
            <div style={{ display: "flex", columnGap: "16px" }}>
              <SearchBox
                placeholder={"ID, 이름, 전화번호"}
                onKeyDown={(e) => {
                  if (
                    e.key === "Enter" &&
                    e.nativeEvent.isComposing === false
                  ) {
                    if (
                      e.target.value.length > 0 &&
                      e.target.value.trim().length === 0
                    ) {
                      alert("제목을 입력해 주세요");
                      setSearchValue(e.target.value.trim());
                      return;
                    }
                    // handleBoard(null);
                    setSearchTxt(e.target.value);
                    setSearchValue(e.target.value.trim());
                    setPage(1);
                  }
                }}
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
              ></SearchBox>
            </div>
          </Header>
          <TableStyle>
            <TableWrapper>
              <table>
                <thead>
                  <tr>
                    <th width={"70px"}>구분</th>
                    <th width={"300px"}>파트너 ID</th>
                    <th>ID</th>
                    <th>이름</th>
                    <th>전화번호</th>
                    <th>유치고객사</th>
                    <th>가입일</th>
                  </tr>
                </thead>
                <tbody>
                  {getPartnersUserListData?.list &&
                    getPartnersUserListData?.list?.map((e, i) => (
                      <tr key={i}>
                        <td>{i + limit * (page - 1) + 1}</td>
                        <td
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <span
                            style={{
                              whiteSpace: "break-spaces",
                              textAlign: "left",
                            }}
                          >
                            {e.partners_cd}
                          </span>
                          <ButtonComponent
                            style={{
                              backgroundColor: "#fff",
                              color: "#4A5568",
                              border: "1.125px solid #E2E8F0",
                              height: "32px",
                              width: "68px",
                              marginLeft: "8px",
                            }}
                            onClick={() => {
                              getPartnersUserInfoMutate(e.partners_seq);
                              setOpenedDetail(true);
                            }}
                          >
                            {"상세보기"}
                          </ButtonComponent>
                        </td>
                        <td>{e.partners_id}</td>
                        <td>{e.partners_nm}</td>
                        <td>{e.phone_num}</td>
                        <td>{e.client_cnt}개</td>
                        <td>
                          {moment().utc(e.crt_dt).format("YYYY년 M월 D일")}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </TableWrapper>
          </TableStyle>
          <PagingFooter>
            <Pagination
              prevPageText={<Icon src="/assets/icons/chevron-left.svg" />}
              nextPageText={<Icon src="/assets/icons/chevron-right.svg" />}
              activePage={page}
              itemsCountPerPage={limit}
              totalItemsCount={totalCount === 0 ? 1 : totalCount}
              pageRangeDisplayed={5}
              hideFirstLastPages={true}
              onChange={(e) => {
                setPage(e);
                // handleBoard();
              }}
            />
            <PagesList
              onChange={(e) => {
                setLimit(e.value);
                setPage(1);
                // handleBoard();
              }}
            ></PagesList>
          </PagingFooter>
          <SideModal
            opend={openedDetail}
            closeModal={() => setOpenedDetail(false)}
            header={"파트너 상세보기"}
          >
            <Side>
              <Content>
                <figure>
                  <h6>파트너 ID</h6>
                  <h5>{getPartnersUserInfoData?.partners_cd}</h5>
                </figure>
                <figure>
                  <h6>ID</h6>
                  <h5>{getPartnersUserInfoData?.partners_id}</h5>
                </figure>
                <figure>
                  <h6>이름</h6>
                  <h5>{getPartnersUserInfoData?.partners_nm}</h5>
                </figure>
                <figure>
                  <h6>전화번호</h6>
                  <h5>{getPartnersUserInfoData?.phone_num}</h5>
                </figure>
                <figure>
                  <h6>이메일</h6>
                  <h5>{getPartnersUserInfoData?.email_addr}</h5>
                </figure>
                <figure>
                  <h6>가입일</h6>
                  <h5>
                    {moment()
                      .utc(getPartnersUserInfoData?.crt_dt)
                      .format("YYYY년 M월 D일")}
                  </h5>
                </figure>
                <figure>
                  <h6>계좌정보</h6>
                  {getPartnersUserInfoData?.bank_nm &&
                    getPartnersUserInfoData?.bank_acct &&
                    getPartnersUserInfoData?.bank_user_nm && (
                      <h5>
                        {getPartnersUserInfoData?.bank_nm}/
                        {getPartnersUserInfoData?.bank_acct}/
                        {getPartnersUserInfoData?.bank_user_nm}
                      </h5>
                    )}
                </figure>
                <figure>
                  <h6>탈퇴 여부</h6>
                  <h5>{getPartnersUserInfoData?.withdrawal_yn}</h5>
                </figure>
                <figure
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2,1fr)",
                    columnGap: "20px",
                  }}
                >
                  <div>
                    <h6>주민등록증/운전면허증</h6>
                    <div
                      style={{
                        width: "178px",
                        height: "178px",
                        position: "relative",
                        marginTop: "10px",
                        overflow: "hidden",
                      }}
                      onClick={() => {
                        if (!getPartnersUserInfoData?.id_photo) return;
                        setOpendImageIndex(1);
                        setOpendImage(true);
                      }}
                    >
                      {getPartnersUserInfoData?.id_photo ? (
                        <img
                          src={getPartnersUserInfoData?.id_photo}
                          alt="id_img"
                          style={{
                            width: "inherit",
                            position: "absolute",
                            left: "0",
                            right: "0",
                            top: "0",
                            bottom: "0",
                            margin: "auto",
                          }}
                        />
                      ) : (
                        <ImgText>
                          등록된
                          <br />
                          사진이 없습니다
                        </ImgText>
                      )}
                    </div>
                  </div>
                  <div>
                    <h6>통장사본</h6>
                    <div
                      style={{
                        width: "178px",
                        height: "178px",
                        position: "relative",
                        marginTop: "10px",
                        overflow: "hidden",
                      }}
                      onClick={() => {
                        if (!getPartnersUserInfoData?.bank_photo) return;

                        setOpendImageIndex(2);
                        setOpendImage(true);
                      }}
                    >
                      {getPartnersUserInfoData?.bank_photo ? (
                        <img
                          src={getPartnersUserInfoData?.bank_photo}
                          alt="bank_photo"
                          style={{
                            width: "inherit",
                            position: "absolute",
                            left: "0",
                            right: "0",
                            top: "0",
                            bottom: "0",
                            margin: "auto",
                          }}
                        />
                      ) : (
                        <ImgText>
                          등록된
                          <br />
                          사진이 없습니다
                        </ImgText>
                      )}
                    </div>
                  </div>
                </figure>

                <figure style={{ flexDirection: "column", rowGap: "10px" }}>
                  <h6>고객사 목록</h6>
                  <TableStyle
                    style={{ margin: "0px", padding: "0px", width: "100%" }}
                  >
                    <TableWrapper style={{ width: "100%" }}>
                      <table>
                        <thead style={{ height: "20px" }}>
                          <tr>
                            <th>고객사명</th>
                            <th width="50px">
                              리베이트
                              <br /> 비율
                            </th>
                            <th width="80px">유치일</th>
                            <th width="60px">누적수익</th>
                          </tr>
                        </thead>
                        <tbody>
                          {getPartnersUserInfoData?.clientInfo?.map((e, i) => (
                            <tr key={i}>
                              <td
                                style={{ whiteSpace: "break-spaces" }}
                                className="table-left"
                              >
                                {e.corpNm}
                              </td>
                              <td
                                style={{
                                  width: "50px",
                                  minWidth: "50px",
                                }}
                              >
                                {e.rebateRate}%
                              </td>
                              <td
                                style={{
                                  width: "80px",
                                  minWidth: "80px",
                                }}
                              >
                                {moment().utc(e.crtDt).format("YYYY-MM-DD")}
                              </td>
                              <td
                                style={{ width: "60px", minWidth: "60px" }}
                                className="table-right"
                              >
                                {e.totalAmt.toLocaleString()}원
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </TableWrapper>
                  </TableStyle>
                </figure>
              </Content>
            </Side>
          </SideModal>
        </FormProvider>
        <ImageEnlargedModal
          opend={opendImage}
          closeModal={() => {
            setOpendImage(false);
          }}
          handleImageIndex={handleImageIndex}
          imageIndex={opendImageIndex}
          imageLength={
            (!getPartnersUserInfoData?.bank_photo ? 0 : 1) +
            (!getPartnersUserInfoData?.id_photo ? 0 : 1)
          }
        >
          <div
            style={{
              height: "calc(100vh - 62px)",
              width: "calc(100vw - 200px)",
            }}
          >
            <Header
              style={{
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                lineHeight: "37px",
                fontSize: "25px",
                padding: "0",
                marginBottom: "10px",
              }}
            >
              {" "}
              {opendImageIndex === 1 ? "주민등록증/운전면허증" : "통장사본"}
            </Header>
            <div
              style={{
                height: "calc(100% - 78px)",
                position: "relative",
                overflow: "auto",
              }}
            >
              <img
                src={
                  opendImageIndex === 1
                    ? getPartnersUserInfoData?.id_photo
                    : getPartnersUserInfoData?.bank_photo
                }
                alt="EnlargedImage"
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  position: "absolute",
                  top: "0",
                  bottom: "0",
                  right: "0",
                  left: "0",
                  margin: "auto",
                }}
              />
            </div>
          </div>
        </ImageEnlargedModal>
      </div>
    </AxiosNavigate>
  );
};

const Side = styled.div`
  //height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* padding-bottom: 20px; */
  box-sizing: border-box;

  .grid {
    grid-template-columns: repeat(2, 100px);
    margin-left: auto;
    padding-bottom: 20px;
  }

  > div > figure {
    h6 {
      min-width: 80px;
    }

    a {
      text-decoration: none;
      color: #3279f5;
    }

    h5 p {
      margin: 0;
    }
  }

  figure:nth-of-type(3) {
    * {
      width: auto !important;
      max-width: 100% !important;
    }

    table {
      width: 100% !important;
    }
  }
`;
const TableContainer = styled.div`
  padding: 0px 32px;
  margin-top: 28px;
`;

const TableWrapper = styled.div`
  /* border: 1px solid #cbd5e0;
  border-radius: 8px;
  overflow: hidden;
  overflow-x: auto; */

  th,
  td {
    text-align: center;
  }
`;

const Table = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;

  th,
  td {
    text-align: center;
    padding: 2px;
    font-size: 13px;
    font-weight: 300;
    border-right: 1px solid #cbd5e0;
    border-bottom: 1px solid #cbd5e0;
    white-space: nowrap;
  }

  th {
    background-color: #f7fafc;
  }

  tr:last-child td {
    border-bottom: none;
  }

  td:first-of-type {
    text-align: center;
  }

  tr {
    //height: 31px;
  }

  thead {
    height: 31px;
  }
`;

const Icon = styled.img`
  width: 16px;
`;

const ImgText = styled.span`
  max-width: 178px;
  color: #718096;
  font-size: 13px;
  font-weight: normal;
  cursor: pointer;
  text-align: center;
  height: 178px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default PartnersAdmin;
