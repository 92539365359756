import React, { useState } from "react";
import styled from "@emotion/styled";
import { useForm } from "react-hook-form";
import CustomChecked from "../../../components/CustomChecked.js";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel, {
  FormControlLabelProps,
} from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { numberOnly } from "../../../bin/common.js";
import { InputBox } from "../../../library/styled-components.js";

const MAX = 30;
const MIN = 0;
const marks = [
  {
    value: MIN,
    label: "",
  },
  {
    value: MAX,
    label: "",
  },
];
const WorkingForm = () => {
  const minDistance = 0.5;
  //   const [workWeekSelect, setWorkWeekSelect] = useState(0);
  const [workTimeSelect, setWorkTimeSelect] = useState("S"); // 같음 - s , 다름 - d
  const [sameSlider, setSameSlider] = useState([20, 37]);
  //   const [workWeek, setWorkWeek] = useState({
  //     월: false,
  //     화: false,
  //     수: false,
  //     목: false,
  //     금: false,
  //     토: false,
  //     일: false,
  //   });
  const [workWeek, setWorkWeek] = useState({
    월: { checked: false, startSlider: 10, endSlider: 30 },
    화: { checked: false, startSlider: 10, endSlider: 30 },
    수: { checked: false, startSlider: 10, endSlider: 30 },
    목: { checked: false, startSlider: 10, endSlider: 30 },
    금: { checked: false, startSlider: 10, endSlider: 30 },
    토: { checked: false, startSlider: 10, endSlider: 30 },
    일: { checked: false, startSlider: 10, endSlider: 30 },
  });
  const {
    watch,
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    setFocus,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });
  const handleCheck = (checked, name) => {
    // const weekLen = Object.values(workWeek).filter(
    //   (item) => item === true
    // ).length;
    // if (weekLen + workWeekSelect >= 7 && checked) {
    //   alert("근무일와 휴일 합이 7일 이어야 합니다");
    //   return 1;
    // } else {
    //   if (!name) return;
    //   return 0;
    // }
    setWorkWeek((prevState) => ({
      ...prevState,
      [name]: { ...prevState[name], checked: checked },
    }));
  };
  function valuetext(value) {
    return `${value}안녕`;
  }

  const handleSameChange = (event, newValue, activeThumb) => {
    console.log(activeThumb);
    if (activeThumb === 0) {
      setSameSlider([
        Math.min(newValue[0], sameSlider[1] - minDistance),
        sameSlider[1],
      ]);
    } else {
      setSameSlider([
        sameSlider[0],
        Math.max(newValue[1], sameSlider[0] + minDistance),
      ]);
    }
  };

  const handledDiffChange = (event, newValue, activeThumb, name) => {
    console.log(activeThumb);
    if (activeThumb === 0) {
      setWorkWeek((prevState) => ({
        ...prevState,
        [name]: {
          ...prevState[name],
          startSlider: Math.min(
            newValue[0],
            workWeek[name].endSlider - minDistance
          ),
          endSlider: workWeek[name].endSlider,
        },
      }));
      //   setSameSlider([
      //     Math.min(newValue[0], sameSlider[1] - minDistance),
      //     sameSlider[1],
      //   ]);
    } else {
      setWorkWeek((prevState) => ({
        ...prevState,
        [name]: {
          ...prevState[name],
          startSlider: workWeek[name].startSlider,
          endSlider: Math.max(
            newValue[1],
            workWeek[name].startSlider + minDistance
          ),
        },
      }));
      //   setSameSlider([
      //     sameSlider[0],
      //     Math.max(newValue[1], sameSlider[0] + minDistance),
      //   ]);
    }
  };
  return (
    <FieldGroup>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            width: "450px",
            // marginTop: "10px",
          }}
        >
          <h5 style={{ marginBottom: "5px" }}>근무일</h5>
          <div
            className="workWeek"
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(4,1fr)",
              rowGap: "8px",
              marginBottom: "10px",
            }}
          >
            <CustomChecked
              style={{ fontSize: "14px" }}
              onChange={(checked) => {
                handleCheck(checked, "월");
              }}
              data={{
                text: "월요일",
                isCheck: workWeek["월"]?.checked,
              }}
            />
            <CustomChecked
              style={{ fontSize: "14px" }}
              onChange={(checked) => {
                handleCheck(checked, "화");
              }}
              data={{
                text: "화요일",
                isCheck: workWeek["화"]?.checked,
              }}
            />
            <CustomChecked
              style={{ fontSize: "14px" }}
              onChange={(checked) => {
                handleCheck(checked, "수");
              }}
              data={{
                text: "수요일",
                isCheck: workWeek["수"]?.checked,
              }}
            />
            <CustomChecked
              style={{ fontSize: "14px" }}
              onChange={(checked) => {
                handleCheck(checked, "목");
              }}
              data={{
                text: "목요일",
                isCheck: workWeek["목"]?.checked,
              }}
            />
            <CustomChecked
              style={{ fontSize: "14px" }}
              onChange={(checked) => {
                handleCheck(checked, "금");
              }}
              data={{
                text: "금요일",
                isCheck: workWeek["금"]?.checked,
              }}
            />
            <CustomChecked
              style={{ fontSize: "14px" }}
              onChange={(checked) => {
                handleCheck(checked, "토");
              }}
              data={{
                text: "토요일",
                isCheck: workWeek["토"]?.checked,
              }}
            />
            <CustomChecked
              style={{ fontSize: "14px" }}
              onChange={(checked) => {
                handleCheck(checked, "일");
              }}
              data={{
                text: "일요일",
                isCheck: workWeek["일"]?.checked,
              }}
            />
          </div>
        </div>
      </div>
      <TimeGroup>
        <h5 style={{ marginBottom: "5px" }}>근무 시간</h5>
        <span className="info">
          * 보여지는 근무시간은 휴식시간을 포함하지 않은 실제 근무시간입니다.
        </span>
        <RadioGroup
          name="use-radio-group"
          defaultValue={workTimeSelect}
          onChange={(event) => {
            setWorkTimeSelect(event.target.value);
          }}
        >
          <div className="same">
            <FormControlLabel value="S" control={<Radio />} label="매일 같음" />
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "7px",
                  width: "200px",
                }}
              >
                <InputBox>
                  <input
                    name="work_place_nm"
                    type="text"
                    {...register("work_place_nm", {
                      validate: {
                        register: (value) =>
                          value !== "" || "사업장명을 등록해 주세요.",
                        noSpaces: (value) =>
                          value?.trim() !== "" || "사업장명을 등록해 주세요.",
                      },
                    })}
                    maxLength={4}
                    disabled={workTimeSelect === "D"}
                  />
                </InputBox>
                <span>시간</span>
              </div>
              <Box sx={{ width: "calc(100% - 200px)" }}>
                <Slider
                  value={sameSlider}
                  onChange={handleSameChange}
                  valueLabelDisplay="on"
                  getAriaValueText={valuetext}
                  disableSwap
                  step={0.5}
                  min={MIN}
                  max={MAX}
                  disabled={workTimeSelect === "D"}
                />
              </Box>
            </div>
          </div>
          <div className="different">
            <FormControlLabel value="D" control={<Radio />} label="매일 다름" />
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "7px",
                  width: "200px",
                }}
              >
                <span>월요일</span>
                <InputBox>
                  <input
                    name="work_place_nm"
                    type="text"
                    {...register("work_place_nm", {
                      validate: {
                        register: (value) =>
                          value !== "" || "사업장명을 등록해 주세요.",
                        noSpaces: (value) =>
                          value?.trim() !== "" || "사업장명을 등록해 주세요.",
                      },
                    })}
                    maxLength={4}
                    disabled={
                      workTimeSelect === "S" || !workWeek["월"]?.checked
                    }
                  />
                </InputBox>
                <span>시간</span>
              </div>
              <Box sx={{ width: "calc(100% - 200px)" }}>
                <Slider
                  value={[
                    workWeek["월"]?.startSlider,
                    workWeek["월"]?.endSlider,
                  ]}
                  onChange={(event, newValue, activeThumb) => {
                    handledDiffChange(event, newValue, activeThumb, "월");
                  }}
                  valueLabelDisplay="on"
                  getAriaValueText={valuetext}
                  disableSwap
                  step={0.5}
                  min={MIN}
                  max={MAX}
                  disabled={workTimeSelect === "S" || !workWeek["월"]?.checked}
                />
              </Box>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "7px",
                  width: "200px",
                }}
              >
                <span>화요일</span>
                <InputBox>
                  <input
                    name="work_place_nm"
                    type="text"
                    {...register("work_place_nm", {
                      validate: {
                        register: (value) =>
                          value !== "" || "사업장명을 등록해 주세요.",
                        noSpaces: (value) =>
                          value?.trim() !== "" || "사업장명을 등록해 주세요.",
                      },
                    })}
                    maxLength={4}
                    disabled={
                      workTimeSelect === "S" || !workWeek["화"]?.checked
                    }
                  />
                </InputBox>
                <span>시간</span>
              </div>
              <Box sx={{ width: "calc(100% - 200px)" }}>
                <Slider
                  value={[
                    workWeek["화"]?.startSlider,
                    workWeek["화"]?.endSlider,
                  ]}
                  onChange={(event, newValue, activeThumb) => {
                    handledDiffChange(event, newValue, activeThumb, "화");
                  }}
                  valueLabelDisplay="on"
                  getAriaValueText={valuetext}
                  disableSwap
                  step={0.5}
                  min={MIN}
                  max={MAX}
                  disabled={workTimeSelect === "S" || !workWeek["화"]?.checked}
                />
              </Box>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "7px",
                  width: "200px",
                }}
              >
                <span>수요일</span>
                <InputBox>
                  <input
                    name="work_place_nm"
                    type="text"
                    {...register("work_place_nm", {
                      validate: {
                        register: (value) =>
                          value !== "" || "사업장명을 등록해 주세요.",
                        noSpaces: (value) =>
                          value?.trim() !== "" || "사업장명을 등록해 주세요.",
                      },
                    })}
                    maxLength={4}
                    disabled={
                      workTimeSelect === "S" || !workWeek["수"]?.checked
                    }
                  />
                </InputBox>
                <span>시간</span>
              </div>
              <Box sx={{ width: "calc(100% - 200px)" }}>
                <Slider
                  value={[
                    workWeek["수"]?.startSlider,
                    workWeek["수"]?.endSlider,
                  ]}
                  onChange={(event, newValue, activeThumb) => {
                    handledDiffChange(event, newValue, activeThumb, "수");
                  }}
                  valueLabelDisplay="on"
                  getAriaValueText={valuetext}
                  disableSwap
                  step={0.5}
                  min={MIN}
                  max={MAX}
                  disabled={workTimeSelect === "S" || !workWeek["수"]?.checked}
                />
              </Box>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "7px",
                  width: "200px",
                }}
              >
                <span>목요일</span>
                <InputBox>
                  <input
                    name="work_place_nm"
                    type="text"
                    {...register("work_place_nm", {
                      validate: {
                        register: (value) =>
                          value !== "" || "사업장명을 등록해 주세요.",
                        noSpaces: (value) =>
                          value?.trim() !== "" || "사업장명을 등록해 주세요.",
                      },
                    })}
                    maxLength={4}
                    disabled={
                      workTimeSelect === "S" || !workWeek["목"]?.checked
                    }
                  />
                </InputBox>
                <span>시간</span>
              </div>
              <Box sx={{ width: "calc(100% - 200px)" }}>
                <Slider
                  value={[
                    workWeek["목"]?.startSlider,
                    workWeek["목"]?.endSlider,
                  ]}
                  onChange={(event, newValue, activeThumb) => {
                    handledDiffChange(event, newValue, activeThumb, "목");
                  }}
                  valueLabelDisplay="on"
                  getAriaValueText={valuetext}
                  disableSwap
                  step={0.5}
                  min={MIN}
                  max={MAX}
                  disabled={workTimeSelect === "S" || !workWeek["목"]?.checked}
                />
              </Box>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "7px",
                  width: "200px",
                }}
              >
                <span>금요일</span>
                <InputBox>
                  <input
                    name="work_place_nm"
                    type="text"
                    {...register("work_place_nm", {
                      validate: {
                        register: (value) =>
                          value !== "" || "사업장명을 등록해 주세요.",
                        noSpaces: (value) =>
                          value?.trim() !== "" || "사업장명을 등록해 주세요.",
                      },
                    })}
                    maxLength={4}
                    disabled={
                      workTimeSelect === "S" || !workWeek["금"]?.checked
                    }
                  />
                </InputBox>
                <span>시간</span>
              </div>
              <Box sx={{ width: "calc(100% - 200px)" }}>
                <Slider
                  value={[
                    workWeek["금"]?.startSlider,
                    workWeek["금"]?.endSlider,
                  ]}
                  onChange={(event, newValue, activeThumb) => {
                    handledDiffChange(event, newValue, activeThumb, "금");
                  }}
                  valueLabelDisplay="on"
                  getAriaValueText={valuetext}
                  disableSwap
                  step={0.5}
                  min={MIN}
                  max={MAX}
                  disabled={workTimeSelect === "S" || !workWeek["금"]?.checked}
                />
              </Box>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "7px",
                  width: "200px",
                }}
              >
                <span>토요일</span>
                <InputBox>
                  <input
                    name="work_place_nm"
                    type="number"
                    {...register("work_place_nm", {
                      validate: {
                        register: (value) =>
                          value !== "" || "사업장명을 등록해 주세요.",
                        noSpaces: (value) =>
                          value?.trim() !== "" || "사업장명을 등록해 주세요.",
                      },
                    })}
                    maxLength={4}
                    onChange={(e) => {
                      if (e.target.value > 100) return;
                      numberOnly(e);
                    }}
                    max={"99"}
                    step={"1"}
                    disabled={
                      workTimeSelect === "S" || !workWeek["토"]?.checked
                    }
                  />
                </InputBox>
                <span>시간</span>
              </div>
              <Box sx={{ width: "calc(100% - 200px)" }}>
                <Slider
                  value={[
                    workWeek["토"]?.startSlider,
                    workWeek["토"]?.endSlider,
                  ]}
                  onChange={(event, newValue, activeThumb) => {
                    handledDiffChange(event, newValue, activeThumb, "토");
                  }}
                  valueLabelDisplay="on"
                  getAriaValueText={valuetext}
                  disableSwap
                  step={0.5}
                  min={MIN}
                  max={MAX}
                  disabled={workTimeSelect === "S" || !workWeek["토"]?.checked}
                />
              </Box>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "7px",
                  width: "200px",
                }}
              >
                <span>일요일</span>
                <InputBox>
                  <input
                    name="work_place_nm"
                    type="text"
                    {...register("work_place_nm", {
                      validate: {
                        register: (value) =>
                          value !== "" || "사업장명을 등록해 주세요.",
                        noSpaces: (value) =>
                          value?.trim() !== "" || "사업장명을 등록해 주세요.",
                      },
                    })}
                    maxLength={4}
                    disabled={
                      workTimeSelect === "S" || !workWeek["일"]?.checked
                    }
                  />
                </InputBox>
                <span>시간</span>
              </div>
              <Box sx={{ width: "calc(100% - 200px)" }}>
                <Slider
                  value={[
                    workWeek["일"]?.startSlider,
                    workWeek["일"]?.endSlider,
                  ]}
                  onChange={(event, newValue, activeThumb) => {
                    handledDiffChange(event, newValue, activeThumb, "일");
                  }}
                  valueLabelDisplay="on"
                  getAriaValueText={valuetext}
                  disableSwap
                  step={0.5}
                  min={MIN}
                  max={MAX}
                  disabled={workTimeSelect === "S" || !workWeek["일"]?.checked}
                />
              </Box>
            </div>
          </div>
        </RadioGroup>
      </TimeGroup>
    </FieldGroup>
  );
};

export default WorkingForm;

const TimeGroup = styled.div`
  .MuiFormControlLabel-label {
    font-size: 14px;
  }

  .MuiSlider-valueLabelOpen {
    color: #000;
    background-color: transparent;
    top: 0px;
  }
  .different > div,
  .same > div {
    display: flex;
    align-items: center;
    padding-left: 30px;
    /* justify-content: space-between; */
  }
`;
const FieldGroup = styled.form``;
