import React from "react";
import { InputBox, Select } from "../../../library/styled-components";
import { addCommas, numberOnly, stringNumberToInt } from "../../../bin/common";
import styled from "@emotion/styled";
import { Controller, useForm } from "react-hook-form";
import axiosInstance from "../../../library/axios-index";
import DayCalendarPicker from "../../../components/DayCalendarPicker.js";

const constManagerYnOptions = [
  { value: "Y", label: "예" },
  { value: "N", label: "아니오" },
];
const hireTypeOptions = [
  { value: "F", label: "정규직" },
  { value: "T", label: "계약직" },
  { value: "D", label: "일용직" },
];

const salaryTypeOptions = [
  { value: "Y", label: "연봉" },
  { value: "M", label: "월급" },
  { value: "D", label: "일급" },
];

const fieldsOrder = [
  "user_nm",
  "id_num",
  "phone_num",
  "personnel_num",
  "const_manager_yn",
  "team_seq",
  "rank_seq",
  "salary_amt",
  "bank_nm",
  "bank_acct",
  "join_dt",
];

const ModifyEmployForm = ({
  modifyEmployComplete,
  employ,
  formRef,
  ...props
}) => {
  const { ranks } = props;
  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    setFocus,
    formState: { errors },
  } = useForm({
    defaultValues: {
      personnel_num: employ?.personnel_num,
      addr1: employ?.addr1,
      addr2: employ?.addr2,
      const_manager_yn: constManagerYnOptions.find(
        (e) => e.value === employ?.const_manager_yn
      ),
      team_seq: {
        value: employ.team_seq,
        label: props?.teams.find((e) => e.team_seq === employ.team_seq)
          ?.team_nm,
      },
      rank_seq: {
        value: employ.rank_seq,
        label: props?.ranks.find((e) => e.rank_seq === employ.rank_seq)
          ?.position_nm,
      },
      hire_type: hireTypeOptions.find((e) => e.value === employ?.hire_type),
      salary_type: salaryTypeOptions.find(
        (e) => e.value === employ.salary_type
      ),
      salary_amt: addCommas(employ?.salary_amt),
      bank_nm: employ?.bank_nm && {
        value: employ?.bank_nm,
        label: employ?.bank_nm,
      },
      bank_acct: employ?.bank_acct,
      join_dt: employ?.join_dt,
      leave_dt: null,
    },
  });

  const onSubmit = async (e) => {
    delete e.addr1;
    delete e.addr2;
    e.const_manager_yn = e.const_manager_yn["value"];
    e.team_seq = e.team_seq["value"];
    e.rank_seq = e.rank_seq["value"];
    //   e.bank_nm = e.bank_nm['value'];
    e.bank_nm = !getValues("bank_nm") ? null : getValues("bank_nm")?.value;
    e.salary_amt = stringNumberToInt(e.salary_amt);
    e.hire_type = e.hire_type["value"];
    e.salary_type = e.salary_type["value"];
    e.leave_dt = e.leave_dt === "" ? null : e.leave_dt;

    const token = localStorage.getItem("admin_token");
    const {
      data: { result: done },
    } = await axiosInstance.post(`/company/modifyEmploy`, {
      lang: "ko",
      uuid: "string",
      token,
      cu_seq: employ.cu_seq,
      ...e,
    });

    modifyEmployComplete();
  };

  const onError = (errors) => {
    const errorKeys = Object.keys(errors);
    if (errorKeys.length > 0) {
      const firstError = fieldsOrder.find((key) => errorKeys.includes(key));
      setFocus(firstError);

      if (errors[firstError].type === "noSpaces") {
        setValue(firstError, "");
      }
      alert(errors[firstError]?.message || "Form validation error");
    }
  };

  return (
    <FieldGroup
      ref={formRef}
      className="modify-employ-form"
      onSubmit={handleSubmit(onSubmit, onError)}
    >
      <InputBox>
        <h6>이름</h6>
        <h5>{employ?.user_nm}</h5>
      </InputBox>

      <InputBox>
        <h6>주민등록번호</h6>
        <h5>{employ?.id_num}</h5>
        {/*<input name="id_num" readOnly={true} style={{backgroundColor: '#7180960f'}} {...register('id_num')} />*/}
      </InputBox>

      <InputBox>
        <h6>연락처</h6>
        <h5>{employ?.phone_num}</h5>
      </InputBox>

      <InputBox>
        <h6>
          사원번호 <span>*</span>
        </h6>
        <input
          name="personnel_num"
          {...register("personnel_num", {
            validate: {
              register: (value) => value !== "" || "사원번호를 입력해 주세요.",
              noSpaces: (value) =>
                value?.trim() !== "" || "사원번호를 입력해 주세요.",
            },
          })}
          maxLength={25}
        />
      </InputBox>

      <InputBox fulled>
        <h6>주소</h6>
        <h5>
          {employ.addr1} {employ.addr2}
        </h5>
      </InputBox>

      <InputBox style={{ marginTop: "3px" }}>
        <h6>
          현장 관리자 여부 <span>*</span>
        </h6>
        <Controller
          name="const_manager_yn"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Select {...field} options={constManagerYnOptions} />
          )}
        />
      </InputBox>
      {/*<InputBox></InputBox>*/}

      <InputBox>
        <h6>
          부서 <span>*</span>
        </h6>

        <Controller
          name="team_seq"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Select
              {...field}
              placeholder={"부서를 선택해주세요"}
              options={props?.teams.map((e) => {
                return { value: e.team_seq, label: e.team_nm };
              })}
            />
          )}
        />
      </InputBox>

      <InputBox>
        <h6>
          직급 <span>*</span>
        </h6>

        <Controller
          name="rank_seq"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Select
              {...field}
              placeholder={"직급을 선택해주세요"}
              options={ranks?.map((e) => {
                return { value: e.rank_seq, label: e.position_nm };
              })}
            />
          )}
        />
      </InputBox>

      <InputBox>
        <h6>
          고용형태 <span>*</span>
        </h6>
        <Controller
          name="hire_type"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Select
              {...field}
              placeholder={"고용형태를 선택해주세요"}
              options={hireTypeOptions}
            />
          )}
        />
      </InputBox>
      <InputBox>
        <h6>
          급여 <span>*</span>
        </h6>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 2fr",
            columnGap: "3px",
          }}
        >
          <Controller
            name="salary_type"
            control={control}
            rule={{ required: true }}
            render={({ field }) => (
              <Select {...field} options={salaryTypeOptions} />
            )}
          />

          <input
            name="salary_amt"
            {...register("salary_amt", {
              required: "급여를 입력해 주세요",
            })}
            onChange={(e) => {
              numberOnly(e);
              e.target.value = stringNumberToInt(e.target.value);
              e.target.value = addCommas(e.target.value);
            }}
          />
        </div>
      </InputBox>
      <InputBox>
        <h6>은행 </h6>
        <h5>
          {getValues("bank_nm")?.label} {getValues("bank_acct")}
        </h5>
      </InputBox>

      <InputBox>
        <h6 style={{ marginBottom: "8px" }}>
          입사일 <span>*</span>
        </h6>

        <DayCalendarPicker
          {...register("join_dt")}
          style={{ width: "100%", height: "39px", fontSize: "16px" }}
          change={(newValue) => {
            setValue("join_dt", newValue);
          }}
          isValue={getValues("join_dt") || ""}
        />
      </InputBox>
      <InputBox>
        <h6 style={{ marginBottom: "8px" }}>퇴사일</h6>

        <DayCalendarPicker
          {...register("leave_dt")}
          style={{ width: "100%", height: "39px", fontSize: "16px" }}
          change={(newValue) => {
            setValue("leave_dt", newValue);
          }}
          isValue={getValues("leave_dt") || ""}
        />
      </InputBox>

      <InputBox>
        <br />
      </InputBox>
    </FieldGroup>
  );
};
const FieldGroup = styled.form`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 0 14px;
`;

export default ModifyEmployForm;
