import React, { forwardRef, useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { ModalButton } from "../../../components/ModalButton.js";
import SearchBox from "../../../components/Search";
import { useForm, Controller } from "react-hook-form";
import { numberOnly } from "../../../bin/common";
import SelectedFilterBox from "../../../components/SelectedFilterBox.js";
const PaymentStatementForm = forwardRef((props, ref) => {
  const { belongFilterOption } = props;
  const { setOpendSearch, setSearchTxt, setBelongFilter, setAttendAndManDay } =
    props.state;
  const { searchTxt, belongFilter, attendAndManDay } = props.date;
  // const componentRef = ref;
  const componentRef = useRef(null);
  const {
    register,
    control,
    clearErrors,
    watch,
    handleSubmit,
    reset,
    setValue,
    getValues,
    setFocus,
  } = useForm({
    defaultValues: {
      name: searchTxt,
      belong: belongFilter,
      attendCnt: attendAndManDay.attendCnt, // 근무일 수
      manDay: attendAndManDay.manDay, // 공수
      moreThenAttendCnt: attendAndManDay.moreThenAttendCnt, // 근무일 수 이상/이하
      moreThenManDay: attendAndManDay.moreThenManDay, // 공수 이상/이하,
    },
  });

  useEffect(() => {
    setValue(
      "belong",
      Object.keys(belongFilter).length === 0
        ? { label: "전체 소속", value: "" }
        : belongFilter
    );
    setValue("name", searchTxt);
  }, []);

  const onSubmit = (e) => {
    setSearchTxt(e.name.trim());
    setBelongFilter(e.belong);
    setAttendAndManDay({
      attendCnt: !e.attendCnt ? null : Number(e.attendCnt),
      manDay: !e.manDay ? null : Number(e.manDay),
      moreThenAttendCnt: e.moreThenAttendCnt,
      moreThenManDay: e.moreThenManDay,
    });

    setOpendSearch(false);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        // 여기서 원하는 작업을 수행
        setOpendSearch(false);
      }
    }

    // 마운트 시 document에 이벤트 리스너 추가
    document.addEventListener("mousedown", handleClickOutside);
    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [componentRef]);
  return (
    <DialogGroup onSubmit={handleSubmit(onSubmit)} ref={componentRef}>
      <div className="header">
        <h4
          style={{
            color: "#171923",
            fontSize: "18px",
            margin: 0,
          }}
        >
          검색
        </h4>

        <ModalButton
          onClick={() => {
            setOpendSearch(false);
          }}
        >
          <img alt={"XButton"} src="/assets/icons/left-icon.svg" />
        </ModalButton>
      </div>
      <div className="contain">
        <div>
          <span>직원 이름</span>
          <Controller
            name="name"
            {...register("name")}
            control={control}
            render={({ field }) => (
              <SearchBox
                {...field}
                placeholder="직원 검색"
                onKeyDown={(e) => {
                  if (
                    e.key === "Enter" &&
                    e.nativeEvent.isComposing === false
                  ) {
                    e.preventDefault(); // 엔터 키로 인한 폼 제출 방지
                    setOpendSearch(true);
                  }
                }}
                onChange={(e) => {
                  setValue("name", e.target.value);
                }}
                style={{
                  background: "#fff",
                  height: "37px",
                  width: "100%",
                }}
                value={getValues("name") || ""}
              ></SearchBox>
            )}
          />
        </div>
        <div>
          <span>소속</span>

          <Controller
            name="belong"
            {...register("belong")}
            control={control}
            render={({ field }) => (
              <SelectedFilterBox
                {...field}
                style={{
                  height: "38px",
                  width: "100%",
                  border: "1px solid #cbd5e0",
                }}
                placeholder={"전체 소속"}
                value={getValues("belong") || { label: "전체 소속", value: "" }}
                options={belongFilterOption}
                onChange={(e) => {
                  setValue("belong", e);
                }}
              ></SelectedFilterBox>
            )}
          />
        </div>
        <div>
          <span>근무일수</span>
          <NumberBox>
            <input
              name="attendCnt"
              type="text"
              {...register("attendCnt", {
                validate: {
                  register: (value) => {
                    if (value !== "" && Number(value) > 31) {
                      alert("31일 이하만 가능합니다");
                      return;
                    }
                  },
                },
              })}
              onChange={(e) => {
                e.preventDefault();
                numberOnly(e);
              }}
              maxLength={2}
            />
            <Controller
              name="moreThenAttendCnt"
              control={control}
              render={({ field }) => (
                <div style={{ display: "flex", columnGap: "14px" }}>
                  <label>
                    <input
                      value="Y"
                      type="radio"
                      {...register("moreThenAttendCnt")}
                      style={{ marginRight: "5px" }}
                    />
                    이상
                  </label>

                  <label>
                    <input
                      value="N"
                      type="radio"
                      {...register("moreThenAttendCnt")}
                      style={{ marginRight: "5px" }}
                    />
                    이하
                  </label>
                </div>
              )}
            ></Controller>
          </NumberBox>
        </div>
        <div>
          <span>공수</span>
          <NumberBox>
            <input
              name="manDay"
              type="text"
              {...register("manDay", {
                validate: {
                  register: (value) => {
                    if (value !== "" && Number(value) > 31) {
                      alert("31일 이하만 가능합니다");
                      return;
                    }
                  },
                },
              })}
              onChange={(e) => {
                e.preventDefault();
                numberOnly(e);
              }}
              maxLength={2}
            />
            <Controller
              name="moreThenManDay"
              control={control}
              render={({ field }) => (
                <div style={{ display: "flex", columnGap: "14px" }}>
                  <label>
                    <input
                      value="Y"
                      type="radio"
                      {...register("moreThenManDay")}
                      style={{ marginRight: "5px" }}
                    />
                    이상
                  </label>

                  <label>
                    <input
                      value="N"
                      type="radio"
                      {...register("moreThenManDay")}
                      style={{ marginRight: "5px" }}
                    />
                    이하
                  </label>
                </div>
              )}
            ></Controller>
          </NumberBox>
        </div>
      </div>

      <div className="footer">
        <ModalButton
          type="button"
          onClick={(event) => {
            event.preventDefault();
            setValue("name", "");
            setValue("belong", { label: "전체 소속", value: "" });
            setValue("moreThenAttendCnt", "Y");
            setValue("moreThenManDay", "Y");
            setValue("attendCnt", null);
            setValue("manDay", null);
          }}
        >
          초기화
        </ModalButton>

        <ModalButton
          type="submit"
          style={{ color: "#fff", background: "#3279F5" }}
        >
          검색하기
        </ModalButton>
      </div>
    </DialogGroup>
  );
});

const NumberBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 20px;
`;

const DialogGroup = styled.form`
  position: absolute;
  width: 400px;
  right: 0;
  top: 49px;
  border-radius: 10px;
  box-sizing: border-box;
  border: 1px solid #e2e8f0;
  background: #fff;
  z-index: 999;
  padding: 10px;

  > div {
    display: flex;
    width: 100%;

    &.header {
      margin-bottom: 10px;
      justify-content: space-between;
      align-items: center;
    }

    &.contain {
      margin-bottom: 10px;
      flex-direction: column;
      row-gap: 10px;
      > div {
        display: flex;
        align-items: center;
        column-gap: 20px;
        width: 100%;
        > span {
          white-space: nowrap;
          color: #4a5568;
          font-size: 14px;
          display: block;
          width: 70px;
        }
      }
      label {
        color: hsl(0, 0%, 50%);
        font-size: 14px;
        white-space: nowrap;
      }

      input[type="text"] {
        display: inline-block;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        box-sizing: border-box;
        width: 100%;
        border: 0px;
        outline: none;

        padding: 8px 12px;

        color: #171923;
        leading-trim: both;
        text-edge: cap;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        background: #fff;
        border: 1px solid #cbd5e0;
        border-radius: 4px;
      }
    }

    &.footer {
      justify-content: flex-end;
    }
  }
`;
export default PaymentStatementForm;
