import React, { forwardRef, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import styled from "@emotion/styled";
import { InputBox } from "../../../library/styled-components";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import SelectedFilterBox from "../../../components/SelectedFilterBox";
import { addCommas, numberOnly, stringNumberToInt } from "../../../bin/common";
import { useMutation } from "@tanstack/react-query";
import {
  createDiscountRule,
  updateDiscountRule,
} from "../../../library/admin-react-query";

const fieldsOrder = [
  "type",
  "amt",
  "dateType",
  "period",
  "title",
  "desc",
  "use_yn",
];

const discountType = [
  {
    value: "FR",
    label: "정률(%)",
  },
  { value: "FA", label: "정액(원)" },
  { value: "FF", label: "고정액(원)" },
];

const monthType = [
  {
    value: "M",
    label: "월",
  },
];
const DiscountRuleForm = forwardRef((props, ref) => {
  const { data, action } = props;

  const {
    watch,
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    setFocus,
    formState: { errors },
  } = useForm({
    defaultValues:
      data !== null
        ? {
            //   discount_type: null,
            dateType:
              data.date_type === "M"
                ? { value: "M", label: "월" }
                : { value: "Y", label: "년" },
            type: discountType.find((item) => item.value === data?.dscnt_type),
            period: data.period,
            title: data.dscnt_title,
            desc: data.dscnt_desc,
            useYN: data.use_yn,
          }
        : {
            useYN: "Y",
          },
    //{discount_type: {value: '', label: ''}}
  });

  // 할인 정책 생성
  const { mutate: createDiscountRuleMutate } = useMutation({
    mutationKey: ["createDiscountRule"],
    mutationFn: createDiscountRule,
    onSuccess: (data) => {
      if (data.done) {
        action("create");
      }
    },
    onError: (error) => {
      alert(error.message);
    },
  });

  // 할인 정책 생성
  const { mutate: updateDiscountRuleMutate } = useMutation({
    mutationKey: ["updateDiscountRule"],
    mutationFn: updateDiscountRule,
    onSuccess: (data) => {
      if (data.done) {
        action("update");
      }
    },
    onError: (error) => {
      alert(error.message);
    },
  });

  const onSubmit = (e) => {
    e.dateType = "M";
    e.type = e.type.value;
    e.period = Number(e.period);
    e.amt = Number(e.amt.replace(/[^0-9.]/g, ""));

    if (!data) {
      // 생성
      createDiscountRuleMutate(e);
    } else {
      // 수정
      e.dscnt_seq = data.dscnt_seq;
      updateDiscountRuleMutate(e);
    }
  };

  const onError = (errors) => {
    const errorKeys = Object.keys(errors);
    if (errorKeys.length > 0) {
      const firstError = fieldsOrder.find((key) => errorKeys.includes(key));
      setFocus(firstError);

      if (errors[firstError].type === "noSpaces") {
        setValue(firstError, "");
      }
      alert(errors[firstError]?.message || "Form validation error");
    }
  };
  useEffect(() => {
    if (data === null) return;
    setValue("amt", data?.dscnt_amt?.toLocaleString());
  }, []);
  return (
    <FieldGroup
      className="discount-policy-form"
      ref={ref}
      onSubmit={handleSubmit(onSubmit, onError)}
    >
      <InputBox fulled>
        <h6>할인 구분 및 할인율/금액</h6>
        <span style={{ color: "#ff3838", fontSize: "13px", marginTop: "-2px" }}>
          * 수수료 단가 기준 적용 ex) 할인금액 1,000 등록시 3,900원 -&gt;
          2,900원 적용
        </span>
        <div style={{ display: "flex", columnGap: "15px" }}>
          <Controller
            name="type"
            control={control}
            rules={{ required: "할인 구분을 선택해주세요" }}
            render={({ field }) => (
              <SelectedFilterBox
                {...field}
                placeholder={"할인 구분"}
                style={{ width: "100%", height: "39px", font: "16px" }}
                value={
                  getValues("type") === ""
                    ? {
                        value: "",
                        label: "할인 구분",
                      }
                    : getValues("type")
                }
                onChange={(e) => {
                  setValue("type", {
                    value: e.value,
                    label: e.label,
                  });

                  setValue("amt", 0);
                }}
                options={discountType}
              ></SelectedFilterBox>
            )}
          />

          <input
            name="amt"
            type="text"
            {...register("amt", {
              validate: {
                register: (value) => {
                  if (Number(value) === 0) {
                    return "할인율/금액을 입력해주세요";
                  }

                  if (
                    Number(value) > 100 &&
                    getValues("type")?.value === "FR"
                  ) {
                    return "할인율 입력해주세요";
                  }
                },
              },
            })}
            onChange={(e) => {
              numberOnly(e);
              e.target.value = stringNumberToInt(e.target.value);
              e.target.value = addCommas(e.target.value);
              if (getValues("type")?.value === "FA") {
              }
            }}
            maxLength={watch("type")?.value === "FR" ? 3 : undefined}
            defaultValue={"0"}
          />
        </div>
      </InputBox>

      <InputBox fulled>
        <h6>기간</h6>
        <div style={{ display: "flex", columnGap: "15px" }}>
          <Controller
            name="dateType"
            control={control}
            render={({ field }) => (
              <SelectedFilterBox
                {...field}
                placeholder={"기간"}
                style={{ width: "100%", height: "39px", font: "16px" }}
                value={monthType[0]}
                onChange={(e) => {
                  setValue("dateType", {
                    value: e.value,
                    label: e.label,
                  });
                }}
                options={monthType}
              ></SelectedFilterBox>
            )}
          />
          <input
            name="period"
            type="text"
            {...register("period", {
              validate: {
                register: (value) => value !== "" || "기간을 입력해주세요",
                //  noSpaces: (value) => value?.trim() !== '' || '기간을 입력해주세요',
              },
            })}
            onChange={(e) => numberOnly(e)}
          />
        </div>
      </InputBox>
      <InputBox fulled>
        <h6>제목</h6>
        <input
          name="title"
          type="text"
          {...register("title", {
            validate: {
              register: (value) => value !== "" || "제목을 입력해주세요",
              noSpaces: (value) =>
                value?.trim() !== "" || "제목을 입력해주세요",
            },
          })}
        />
      </InputBox>
      <InputBox fulled>
        <h6>설명</h6>
        <textarea
          name="desc"
          type="text"
          {...register("desc", {
            validate: {
              register: (value) => value !== "" || "설명을 입력해주세요",
              noSpaces: (value) =>
                value?.trim() !== "" || "살먕을 입력해주세요",
            },
          })}
          style={{ minHeight: "505px" }}
        />
      </InputBox>
      <InputBox
        style={{ display: "flex", columnGap: "15px", marginTop: "-3px" }}
      >
        <Controller
          name="useYN"
          control={control}
          rules={{ required: "사용 여부를 선택해주새요" }}
          render={({ field }) => (
            <>
              <FormControl
                style={{
                  columnGap: "10px",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <h6>사용 여부</h6>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  {...field}
                  value={field.value ?? ""}
                >
                  <FormControlLabel
                    value="Y"
                    control={<Radio size="small" />}
                    label="사용"
                    sx={{
                      fontSize: "16px",
                    }}
                  />
                  <FormControlLabel
                    value="N"
                    control={<Radio size="small" />}
                    label="중지"
                    sx={{
                      fontSize: "16px",
                    }}
                  />
                </RadioGroup>
              </FormControl>
            </>
          )}
        />
      </InputBox>
    </FieldGroup>
  );
});

const FieldGroup = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 10px;

  > div {
    row-gap: 8px;
    display: flex;
    flex-direction: column;
  }

  textarea {
    width: 100%;
    border-radius: 6px;
    border: 1px solid #cbd5e0;
    box-sizing: border-box;
    // margin-top: 6px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    resize: none;
    padding: 8px 12px;
    color: #171923;
    leading-trim: both;
    text-edge: cap;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  input {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }

  label {
    margin-left: 0;

    > span,
    input {
      padding: 0;
    }

    .MuiFormControlLabel-label {
      font-size: 16px;
      color: #171923;
      font-weight: 500;
      line-height: 20px;
    }
  }

  .editor-wrap {
    height: 500px;

    .jodit-react-container {
      height: 500px;
    }

    p {
      margin: 0 !important;
    }

    .jodit-container:not(.jodit_inline) .jodit-workplace {
      min-height: unset !important;
      height: calc(500px - 39px) !important;
    }

    .jodit-add-new-line,
    .jodit-status-bar {
      display: none;
    }
  }
`;
export default DiscountRuleForm;
