import styled from "@emotion/styled";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Pagination from "react-js-pagination";
import SideModal from "../../../components/SideModal";
import Modal from "../../../components/Modal";
import { ModalButton } from "../../../components/ModalButton";
import { PagingFooter } from "../../../components/PagingFooter";
import axiosInstance from "../../../library/axios-index";
import dayjs from "dayjs";
import { addCommas, BlockScrolling, checkRole } from "../../../bin/common";
import AxiosNavigate from "../../../library/axios-navigate";
import { useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { RoleType } from "../../../bin/enum";
import Loading from "../../../components/Loading";
import { RightContent } from "../../../library/styled-components";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { planData } from "../../../library/axios-query";
import NewTaxAccountantForm from "./new-tax-accountant-form";
import ModifyTaxAccountantForm from "./modify-tax-accountant-form";
import ButtonComponent from "../../../components/Button";
import PagesList from "../../../components/PagesList";
import moment from "moment";
import EmptyContainer from "../../../components/EmptyContainer";

const avatar = `/assets/images/profile.png`;
const fieldsOrder = [
  "user_nm",
  "id_num",
  "phone_num",
  "personnel_num",
  "const_manager_yn",
  "team_seq",
  "rank_seq",
  "salary_amt",
  "bank_nm",
  "bank_acct",
  "join_dt",
];

function SettingTaxAccountant() {
  const selectStyles = {
    control: (base) => ({
      ...base,
      boxShadow: "none",
      "&:hover": {
        border: "1px solid #E2E8F0",
      },
    }),
  };

  const pageSizeOptions = [
    { value: 20, label: "20" },
    { value: 30, label: "30" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
  ];

  const hireTypeOptions = [
    { value: "F", label: "정규직" },
    { value: "T", label: "계약직" },
    { value: "D", label: "일용직" },
  ];

  const salaryTypeOptions = [
    { value: "Y", label: "연봉" },
    { value: "M", label: "월급" },
    { value: "D", label: "일급" },
  ];

  const constManagerYnOptions = [
    { value: "Y", label: "예" },
    { value: "N", label: "아니오" },
  ];

  const location = useLocation();
  const [cookies] = useCookies(["role"]);
  const formRef = useRef();
  const inputRef = useRef([]);

  const [employees, setEmployees] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [fullTimeCount, setFullTimeCount] = useState(0);
  const [ranks, setRanks] = useState([]);
  const [teams, setTeams] = useState([]);
  const [page, setPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(20);
  const [searchText, setSearchText] = useState("");
  const modalClose = useRef(false);
  const [loading, setLoading] = useState(true); // 로딩바
  const [newLoading, setNewLoading] = useState(false); // 로딩바

  const [laborEmployees, setLaborEmployees] = useState([]);
  const [selectedLaborEmploy, setSelectedLaborEmploy] = useState({});
  const [bankNames, setBankNames] = useState([]);
  const [employ, setEmploy] = useState({});

  const [opendDetailEmploy, setOpendDetailEmploy] = useState(false);
  const [opendNewEmploy, setOpendNewEmploy] = useState(false);
  const [opendModifyEmploy, setOpendModifyEmploy] = useState(false);

  BlockScrolling([opendDetailEmploy, opendNewEmploy, opendModifyEmploy]);
  // BlockScrolling(opendModifyEmploy);
  // BlockScrolling(opendNewEmploy);
  const methods = useForm();

  // 플랜 정보
  const { mutate: planDataItem, data: planDataList } = useMutation({
    mutationKey: ["planData"],
    mutationFn: planData,
    onSuccess: (data) => {
      if (!bankNames) return;
      const bankName = data.map((e) => {
        return { value: e.dtl_cd_nm, label: e.dtl_cd_nm };
      });
      setBankNames(bankName);
    },
    onSettled: () => {},
    onError: (error) => {
      throw error;
    },
  });

  const handleSearchName = useCallback(
    async (value) => {
      const token = localStorage.getItem("admin_token");
      const {
        data: { result },
      } = await axiosInstance.post(`/company/listEmploy`, {
        lang: "ko",
        uuid: "string",
        token,
        page,
        limit: pageLimit,
        search_txt: value,
      });

      setSearchText(value);
      setEmployees(result.employees);
      setTotalCount(result.totalCount);
    },
    [searchText, page, pageLimit]
  );

  const handleEmployInfo = useCallback(
    async (cu_seq) => {
      const employData = await loadEmploy(cu_seq);
      setEmploy(employData);
      setOpendDetailEmploy(true);
    },
    [employ]
  );

  const handlePage = useCallback(
    async (page) => {
      const token = localStorage.getItem("admin_token");

      const {
        data: {
          result: { employees, totalCount },
        },
      } = await axiosInstance.post(`/company/listEmploy`, {
        lang: "ko",
        uuid: "string",
        token,
        page,
        limit: pageLimit,
        search_txt: searchText,
      });

      setEmployees(employees);
      setPage(page);
      setTotalCount(totalCount);
    },
    [page, pageLimit]
  );

  const loadSearchLaborEmploy = useCallback(
    async (value) => {
      const token = localStorage.getItem("admin_token");

      const {
        data: {
          result: { laborEmployees, countOfLaborEmploy },
        },
      } = await axiosInstance.post(`/company/searchLaborEmploy`, {
        lang: "ko",
        uuid: "string",
        token,
        search_txt: value,
      });

      setLaborEmployees(laborEmployees);
      setTimeout(() => {
        setNewLoading(false);
      }, 400);
    },
    [laborEmployees]
  );

  const handleSearchLaborEmploy = useCallback(
    async (value) => {
      await loadSearchLaborEmploy(value);
    },
    [laborEmployees]
  );

  const handleSelectedLaborEmploy = useCallback(
    async (values) => {
      setSelectedLaborEmploy(values);
    },
    [selectedLaborEmploy]
  );

  const loadEmploy = useCallback(async (cu_seq) => {
    const token = localStorage.getItem("admin_token");

    const {
      data: { result },
    } = await axiosInstance.post(`/company/employ`, {
      lang: "ko",
      uuid: "string",
      token,
      cu_seq,
    });

    return result;
  }, []);

  const loadListEmploy = useCallback(async () => {
    const token = localStorage.getItem("admin_token");

    const {
      data: { result },
    } = await axiosInstance.post(`/company/listEmploy`, {
      lang: "ko",
      uuid: "string",
      token,
      page,
      limit: pageLimit,
      search_txt: searchText,
    });

    return result;
  }, [pageLimit, page, searchText]);

  useEffect(() => {
    if (opendNewEmploy) {
      (async () => {
        await loadSearchLaborEmploy("");
      })();
    }
  }, [opendNewEmploy]);

  useEffect(() => {
    (async () => {
      const token = localStorage.getItem("admin_token");

      const result = await loadListEmploy();

      const ranksResp = await axiosInstance.post(`/company/listRank`, {
        lang: "ko",
        uuid: "string",
        token,
      });

      const teamsResp = await axiosInstance.post(`/company/listTeam`, {
        lang: "ko",
        uuid: "string",
        token,
      });

      setEmployees(result.employees);
      setTotalCount(result.totalCount);
      setFullTimeCount(result.fullTimeCount);
      setRanks(ranksResp.data.result);
      setTeams(teamsResp.data.result);

      setTimeout(() => {
        setLoading(false);
      }, 300);
    })();
  }, [pageLimit]);

  useEffect(() => {
    if (!bankNames) return;
    planDataItem("M0010");
  }, []);

  // 직원 정보 생성 완료시
  const newEmployComplete = useCallback(async () => {
    const result = await loadListEmploy();

    setEmploy(await loadEmploy(employ.cu_seq));
    setEmployees(result.employees);
    setTotalCount(result.totalCount);
    setFullTimeCount(result.fullTimeCount);
    setOpendNewEmploy(false);
  });

  // 직원 정보 수정 완료시
  const modifyEmployComplete = useCallback(async () => {
    const result = await loadListEmploy();

    setEmploy(await loadEmploy(employ.cu_seq));
    setEmployees(result.employees);
    setTotalCount(result.totalCount);
    setFullTimeCount(result.fullTimeCount);
    setOpendModifyEmploy(false);
  });
  return (
    <AxiosNavigate>
      <div>
        <FormProvider {...methods}>
          <Panel>
            <div>
              <BoardHeader>
                <h2>세무사 정보</h2>
                <h5>
                  총 소속인원 {totalCount}명{" "}
                  <span>
                    정규직 {fullTimeCount}명 / 계약직{" "}
                    {totalCount - fullTimeCount}명
                  </span>
                </h5>
              </BoardHeader>
            </div>
            <div>
              <ButtonGroup>
                <Search>
                  <SearchIcon src="/assets/icons/search-sm.svg" />
                  <SearchInput
                    placeholder="직원 검색"
                    id={"searchName"}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSearchName(e.target.value);
                        setPage(1);
                      }
                    }}
                    onBlur={(e) => {
                      if (e.target.id === "searchName") {
                        handleSearchName(e.target.value);
                        setPage(1);
                      }
                    }}
                  />
                </Search>
                <Button
                  style={{ backgroundColor: "#3279F5", color: "#fff" }}
                  onClick={() => {
                    if (
                      !checkRole(
                        location.pathname,
                        cookies.role,
                        RoleType.WRITE
                      )
                    ) {
                      return;
                    }
                    setNewLoading(true);
                    setOpendNewEmploy(true);
                    setSelectedLaborEmploy({});
                    setLaborEmployees([]);
                  }}
                >
                  <Icon src="/assets/icons/plus.svg" /> 신규등록
                </Button>
              </ButtonGroup>
            </div>
          </Panel>

          {loading ? (
            <Loading></Loading>
          ) : (
            <>
              <TableContainer>
                <TableWrapper>
                  <Table>
                    <thead>
                      <tr>
                        <th>이름</th>
                        <th>직급</th>
                        <th>주민등록번호</th>
                        <th>근로조건</th>
                        <th>입사일</th>
                        <th>근무여부</th>
                        <th>현장관리자 여부</th>
                        <th>권한</th>
                      </tr>
                    </thead>
                    <tbody>
                      {employees.length === 0 ? (
                        <tr style={{ height: "500px" }}>
                          <td colSpan={8}>
                            <EmptyContainer
                              falseText={"등록된 직원이"}
                              style={{ background: "#fff" }}
                            ></EmptyContainer>
                          </td>
                        </tr>
                      ) : (
                        employees.map((e, i) => (
                          <tr key={i}>
                            <td style={{ width: "400px" }}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  justifyContent: "flex-start",
                                }}
                              >
                                <div
                                  style={{
                                    marginRight: "20px",
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "stretch",
                                  }}
                                >
                                  <Avatar
                                    style={{
                                      marginRight: "10px",
                                      width: "20px",
                                      height: "20px",
                                      backgroundImage: `url(${
                                        e.photo || "/avatar.png"
                                      })`,
                                    }}
                                  />
                                  {e.user_nm}
                                  <Badge>
                                    {
                                      teams.find(
                                        (x) => x.team_seq === e.team_seq
                                      ).team_nm
                                    }
                                  </Badge>
                                </div>
                                <ButtonComponent
                                  children={"상세보기"}
                                  style={{
                                    padding: "0 9px",
                                    height: "32px",
                                    background: "#fff",
                                    borderColor: "#E2E8F0",
                                    color: "#4A5568",
                                  }}
                                  onClick={() => handleEmployInfo(e.cu_seq)}
                                ></ButtonComponent>
                              </div>
                            </td>
                            <td>
                              {
                                ranks.find((x) => x.rank_seq === e.rank_seq)
                                  .position_nm
                              }
                            </td>
                            <td>{e.id_num}</td>
                            <td>
                              {
                                {
                                  F: "정규직",
                                  T: "계약직",
                                  D: "일용직",
                                }[e.hire_type]
                              }
                            </td>
                            <td>{dayjs(e.join_dt).format("YYYY년 M월 D일")}</td>
                            <td>
                              <div style={{ width: "100%", display: "flex" }}>
                                <Badge
                                  style={{
                                    backgroundColor: !e.leave_dt
                                      ? "#D7FBE2"
                                      : "#FFE3E3",
                                  }}
                                >
                                  {!e.leave_dt ? "근무중" : "퇴사"}
                                </Badge>
                              </div>
                            </td>
                            <td>
                              {e.const_manager_yn === "Y" ? "예" : "아니오"}
                            </td>
                            <td style={{ width: "300px" }}>
                              <div
                                style={{
                                  whiteSpace: "pre",
                                  display: "grid",
                                  gridTemplateColumns: "1fr 1fr 1fr",
                                  rowGap: "6px",
                                }}
                              >
                                {e.auth.map((e, i) => {
                                  return (
                                    <Badge
                                      key={i}
                                      style={{ justifyContent: "center" }}
                                    >
                                      {e.auth_nm}
                                    </Badge>
                                  );
                                })}
                              </div>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                </TableWrapper>
              </TableContainer>
              <PagingFooter>
                <Pagination
                  prevPageText={<Icon src="/assets/icons/chevron-left.svg" />}
                  nextPageText={<Icon src="/assets/icons/chevron-right.svg" />}
                  activePage={page}
                  itemsCountPerPage={pageLimit}
                  totalItemsCount={totalCount === 0 ? 1 : totalCount}
                  pageRangeDisplayed={5}
                  hideFirstLastPages={true}
                  onChange={handlePage}
                />
                <PagesList
                  options={pageSizeOptions}
                  onChange={(e) => {
                    setPageLimit(e.value);
                    setPage(1);
                  }}
                ></PagesList>
              </PagingFooter>
            </>
          )}

          <SideModal
            opend={opendDetailEmploy}
            closeModal={() => setOpendDetailEmploy(false)}
            header={
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    width: "180px",
                  }}
                >
                  <Avatar
                    style={{
                      width: "48px",
                      height: "48px",
                      backgroundImage: `url(${employ.photo || "/avatar.png"})`,
                    }}
                  />
                  <AvatarContent>
                    <h5>{employ.user_nm}</h5>
                    <h6>
                      {employ.birth_day
                        ? dayjs(employ.birth_day).format("YYYY년 M월 D일")
                        : "생년월일 미등록"}
                    </h6>
                  </AvatarContent>
                </div>
              </div>
            }
            buttons={
              <>
                <ModalButton
                  onClick={() => {
                    if (
                      !checkRole(
                        location.pathname,
                        cookies.role,
                        RoleType.WRITE
                      )
                    ) {
                      return;
                    }
                    if (employ.leave_dt !== null) {
                      alert("퇴사자는 수정할 수 없습니다.");
                      return;
                    }
                    setOpendModifyEmploy(true);
                  }}
                >
                  수정하기
                </ModalButton>
              </>
            }
          >
            <RightContent>
              <figure>
                <h6>사원번호</h6>
                <h5>{employ.personnel_num}</h5>
              </figure>
              <figure>
                <h6>주민등록번호</h6>
                <h5>{employ.id_num}</h5>
              </figure>
              <figure style={{ height: "100%" }}>
                <h6>주소</h6>
                <div
                  style={{
                    width: "60%",
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "5px",
                    marginBottom: "5px",
                  }}
                >
                  <h5>{employ.addr1}</h5>
                  <h5>{employ.addr2}</h5>
                </div>
              </figure>
              <figure>
                <h6>전화번호</h6>
                <h5>{employ.phone_num}</h5>
              </figure>
              <figure>
                <h6>생년월일</h6>
                <h5>
                  {!!employ.birth_day &&
                    dayjs(employ.birth_day).format("YYYY년 M월 D일")}
                </h5>
              </figure>
              <figure>
                <h6>입사일</h6>
                <h5>
                  {employ.join_dt &&
                    moment.utc(employ.join_dt).format("YYYY년 M월 D일")}
                </h5>
              </figure>
              <figure>
                <h6>퇴사일</h6>
                <h5>
                  {employ.leave_dt &&
                    moment.utc(employ.leave_dt).format("YYYY년 M월 D일")}
                </h5>
              </figure>

              <figure>
                <h6>현장 관리자 여부</h6>
                <h5>{employ.const_manager_yn === "Y" ? "예" : "아니오"}</h5>
              </figure>
              <p />
              <br />
              <figure>
                <h6>부서</h6>
                <h5>
                  {teams.find((e) => e.team_seq === employ.team_seq)?.team_nm}
                </h5>
              </figure>
              <figure>
                <h6>직급</h6>
                <h5>
                  {
                    ranks.find((e) => e.rank_seq === employ.rank_seq)
                      ?.position_nm
                  }
                </h5>
              </figure>
              <figure>
                <h6>고용형태</h6>
                <h5>
                  {
                    {
                      F: "정규직",
                      T: "계약직",
                      D: "일용직",
                    }[employ.hire_type]
                  }
                </h5>
              </figure>
              <figure>
                <h6>급여조건</h6>
                <h5>
                  {
                    {
                      Y: "연봉",
                      M: "월급",
                      D: "일급",
                    }[employ.salary_type]
                  }
                  &nbsp;&nbsp;{addCommas(employ.salary_amt)}원
                </h5>
              </figure>
              <p />
              <br />
              <figure
                style={{
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "flex-start",
                }}
              >
                <h6>권한</h6>
                <h5
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr",
                    rowGap: "5px",
                    columnGap: "6px",
                    width: "calc(100% - 120px)",
                  }}
                >
                  {employees
                    ?.find((e) => e.cu_seq === employ.cu_seq)
                    ?.auth?.map((e, i) => {
                      return (
                        <Badge
                          key={i}
                          style={{
                            justifyContent: "center",
                            marginLeft: "0",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {e.auth_nm}
                        </Badge>
                      );
                    })}
                </h5>
              </figure>
              <br />
              <br />
            </RightContent>
          </SideModal>
          {newLoading ? (
            <Loading></Loading>
          ) : (
            <Modal
              opend={opendNewEmploy}
              closeModal={() => {
                setOpendNewEmploy(modalClose.current);
                modalClose.current = false;
              }}
              header={"직원 신규등록"}
              okText={"확인"}
              formRef={formRef}
              // handleOk={handleNewEmploy}
              widthCheck={"50%"}
            >
              {!Object.keys(selectedLaborEmploy).length ? (
                <div>
                  <Search>
                    <SearchIcon src="/assets/icons/search-sm.svg" />
                    <SearchInput
                      placeholder="근로자 이름을 검색해주세요"
                      onKeyDown={(e) =>
                        e.key === "Enter" &&
                        handleSearchLaborEmploy(e.target.value)
                      }
                    />
                  </Search>
                  {!laborEmployees ? (
                    <EmptyReport>
                      <div>
                        <p>
                          <EmptyIcon src="/assets/icons/check-circle-broken.svg" />
                        </p>
                        <p>근로자가 존재하지 않습니다</p>
                      </div>
                    </EmptyReport>
                  ) : (
                    laborEmployees.map((e, i) => (
                      <LaborCard
                        key={i}
                        onClick={() => handleSelectedLaborEmploy(e)}
                      >
                        <div>
                          <Avatar
                            style={{
                              backgroundImage: `url(${
                                e.photo || "/avatar.png"
                              })`,
                            }}
                          />
                        </div>
                        <LaborCardContent>
                          <h5>
                            {e.user_nm} / {e.phone_num}
                          </h5>
                        </LaborCardContent>
                      </LaborCard>
                    ))
                  )}
                </div>
              ) : (
                <Card>
                  <NewTaxAccountantForm
                    formRef={formRef}
                    newEmployComplete={newEmployComplete}
                    teams={teams}
                    ranks={ranks}
                    constManagerYnOptions={constManagerYnOptions}
                    bankNames={bankNames}
                    selectedLaborEmploy={selectedLaborEmploy}
                  ></NewTaxAccountantForm>
                </Card>
              )}
            </Modal>
          )}

          <Modal
            opend={opendModifyEmploy}
            closeModal={() => setOpendModifyEmploy(false)}
            header={"직원정보 수정하기"}
            okText={"확인"}
            formRef={formRef}
            //   handleOk={handleModifyEmploy}
            widthCheck={"50%"}
          >
            <ModifyTaxAccountantForm
              formRef={formRef}
              employ={employ}
              teams={teams}
              ranks={ranks}
              modifyEmployComplete={modifyEmployComplete}
              bankNames={bankNames}
            ></ModifyTaxAccountantForm>
          </Modal>
        </FormProvider>
      </div>
    </AxiosNavigate>
  );
}

const Panel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 25px;
`;

const Search = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #cbd5e0;
  overflow: hidden;
`;

const SearchIcon = styled.img`
  padding-left: 12px;
`;

const SearchInput = styled.input`
  type: search;
  box-sizing: border-box;
  width: 100%;
  border: 0px;
  outline: none;

  padding: 6px;

  color: #171923;
  leading-trim: both;
  text-edge: cap;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  :focus {
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
`;

const Button = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
  min-height: 32px;
  padding: 0 9px;
  justify-content: center;
  align-items: center;
  gap: 9px;
  border-radius: 6px;
  border: 1.125px solid #e2e8f0;
  background: #fff;

  color: #4a5568;
  leading-trim: both;
  text-edge: cap;
  font-size: 14px;
  cursor: pointer;
`;

// const Select = styled(ReactSelect)`
//     color: #4A5568;
//     leading-trim: both;
//     text-edge: cap;
//     font-size: 16px;
//     font-style: normal;
//     line-height: normal;
//     font-weight: 600;
//     border-radius: 6px;
//     background: #FFF;
//     margin-top: 7px;
//     margin-bottom: 7px;
//
//     div {
//         cursor: pointer;
//         color: #4A5568;
//         border-color: #E2E8F0;
//     }
// `;

const Icon = styled.img`
  width: 16px;
`;

const TableContainer = styled.div`
  padding: 0 25px;
  margin-top: 28px;
`;

const TableWrapper = styled.div`
  border: 1px solid #cbd5e0;
  border-radius: 8px;
  overflow: hidden;
  overflow-x: auto;
`;

const Table = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;

  th,
  td {
    text-align: left;
    //padding: 8px;
    font-size: 13px;
    font-weight: 300;
    border-right: 1px solid #cbd5e0;
    border-bottom: 1px solid #cbd5e0;
    white-space: nowrap;
  }

  th {
    //background-color: #F7FAFC;
  }

  tr:last-child td {
    border-bottom: none;
  }

  td:first-of-type {
    text-align: center;
  }

  thead {
    height: 31px;
  }
`;

const SelectWrapper = styled.div`
  display: flex;
  align-items: center;

  span {
    color: #718096;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-right: 6px;
  }
`;

const BoardHeader = styled.div`
  margin-bottom: 14px;
  margin-top: 28px;

  h2,
  h5 {
    margin: 0;
  }

  h5 {
    color: #4a5568;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-top: 4px;

    span {
      color: #718096;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
  }
`;

const Content = styled.div`
  figure {
    margin: 4px;
    display: flex;
    align-items: center;
    height: 34px;
  }

  h6,
  h5 {
    margin: 0;
  }

  h6 {
    color: #718096;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    width: 110px;
  }

  h5 {
    color: rgba(0, 0, 0, 0.92);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  hr {
    background: #edf2f7;
    color: #edf2f7;
    border: 1px solid #edf2f7;
    margin: 12px 0;
  }
`;

const FieldGroup = styled.form`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 0 14px;
`;

const Field = styled.div`
  h6 {
    color: #718096;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin: 0;
  }

  h5 {
    color: #171923;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    //margin: 8px;
    margin-left: 8px;
    margin-bottom: 12px;
    margin-top: 15px;
  }

  input {
    display: inline-block;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-sizing: border-box;
    width: 100%;
    border: 0px;
    outline: none;

    padding: 8px 12px;

    color: #171923;
    leading-trim: both;
    text-edge: cap;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    background: #fff;
    border: 1px solid #cbd5e0;
    border-radius: 6px;
    margin-bottom: 8px;
    margin-top: 8px;

    :focus {
    }
  }

  width: ${({ fulled }) => (fulled ? `100%` : "48%")};
  @media only screen and (max-width: 992px) {
    width: 100%;
  }
`;

const Card = styled.div`
  border-radius: 12px;
  background: #f7fafc;
  padding: 20px;
  margin-top: 20px;

  h5 {
    color: #171923;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    margin: 0;
    margin-bottom: 16px;
  }
`;

const LaborCard = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 14px 0;
  cursor: pointer;

  &:hover {
    background-color: #ebf2fe !important;
    border-radius: 4px;
  }
`;

const Avatar = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  margin-left: 8px;
`;

const LaborCardContent = styled.div`
  h5,
  h6 {
    margin: 0;
  }

  h5 {
    color: #4a5568;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }

  h6 {
    color: #718096;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
`;

const EmptyReport = styled.div`
  //border-radius: 16px;
  min-height: 300px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    text-align: center;
    margin: 0;
    padding: 0;
    color: #a0aec0;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.2px;
    text-transform: uppercase;
  }

  @media only screen and (max-width: 992px) {
    min-height: 333px;
    margin-top: 24px;
  }
`;

const EmptyIcon = styled.img``;

const AvatarContent = styled.div`
  h5,
  h6 {
    margin: 0;
  }

  h5 {
    color: #171923;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  }

  h6 {
    color: #718096;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
`;

const Badge = styled.div`
  color: #1a202c;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  display: flex;
  padding: 2px 6px;
  align-items: center;
  gap: 6px;
  border-radius: 6px;
  background: #edf2f7;
  margin-left: 6px;
`;

export default SettingTaxAccountant;
