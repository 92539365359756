import React, { forwardRef, useCallback, useState } from "react";
import styled from "@emotion/styled";
import { Controller, useForm } from "react-hook-form";
import { addCommas, numberOnly } from "../../bin/common.js";
import ReactSelect from "react-select";
import ButtonComponent from "../../components/Button.js";
import { InputBox } from "../../library/styled-components.js";
import SelectedFilterBox from "../../components/SelectedFilterBox.js";
import { useEffect } from "react";
import { Tooltip } from "react-tooltip";
import { FormButton } from "./work-place.js";
const fieldsOrder = [
  "const_nm",
  "site_nm",
  "begin_day",
  "end_day",
  "site_code",
  "work_field",
  "contract_amt",
  "orderer_nm",
  "contractor_nm",
  "manager_nm",
  "distance",
];

const ModifyWorkPlaceForm = forwardRef((props, ref) => {
  const {
    endNtabColor,
    endYtabColor,
    workFieldOptions,
    teamsOptions,
    getSiteAddressList,
    detailSite,
    managerData,
    formFields,
  } = props.data;
  const {
    setOpendMap,
    setOpenMapMode,
    setCancelSelectSiteManager,
    setOpendSelectSiteManager,
  } = props.state;

  const {
    handleEndYnTab = () => {},
    addressDelete = () => {},
    handleModifySite = () => {},
  } = props.handle;

  const [fieldData, setFieldData] = useState(formFields);
  const [addFormField, setAddFormField] = useState(true);

  const {
    watch,
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    setFocus,
    formState: { errors },
  } = useForm({
    defaultValues: {
      const_nm: detailSite?.const_nm,
      site_nm: detailSite?.site_nm,
      begin_day: detailSite?.begin_day,
      end_day: detailSite?.end_day,
      site_code: detailSite?.site_code,
      work_field: workFieldOptions.find(
        (e) => e.value == detailSite?.work_field
      ),
      contract_amt: addCommas(detailSite?.contract_amt),
      orderer_nm: detailSite?.orderer_nm,
      contractor_nm: detailSite?.contractor_nm,
      retirement_num: detailSite?.retirement_num,
      employment_num: detailSite?.employment_num,
      health_num: detailSite?.health_num,
      tax_email_addr: detailSite?.tax_email_addr,
      tax_user_nm: detailSite?.tax_user_nm,
      tax_phone_num: detailSite?.tax_phone_num,
      department: teamsOptions.find((e) => e.value == detailSite?.team_seq),
      manager_nm: detailSite?.manager_nm
        ? detailSite?.manager_nm || ""
        : detailSite?.manager_nm_temp || "",
      distance: addCommas(detailSite?.distance),
    },
  });

  useEffect(() => {
    if (!managerData?.userData) return;
    setValue("manager_nm", managerData?.userData?.user_nm);
  }, [managerData]);

  useEffect(() => {}, []);
  const onSubmit = (e) => {
    e.contract_amt = parseInt(e.contract_amt.replaceAll(",", ""));
    e.distance = parseInt(e.distance.replaceAll(",", ""));
    e.department = !e.department ? 0 : Number(e.department.value);
    e.work_field = !e.work_field ? "M0003D0001" : e.work_field?.value;
    if (!addFormField) {
      e.change_contract_amt = parseInt(
        fieldData[fieldData.length - 1].contractAmt.replaceAll(",", "")
      );
      e.change_begin_day = fieldData[fieldData.length - 1].beginDay;
      e.change_end_day = fieldData[fieldData.length - 1].endDay;
    }

    handleModifySite(e);
  };

  const onError = (errors) => {
    const errorKeys = Object.keys(errors);
    if (errorKeys.length > 0) {
      const firstError = fieldsOrder.find((key) => errorKeys.includes(key));

      if (errors[firstError]?.type === "noSpaces") {
        setValue(firstError, "");
      }
      setFocus(firstError);
      alert(errors[firstError]?.message || "Form validation error");
    }
  };

  const handleAddFields = useCallback(() => {
    setFieldData((prevState) => [
      ...prevState,
      {
        contractAmt:
          fieldData?.length === 0
            ? getValues("contract_amt")
            : fieldData[fieldData.length - 1].contractAmt,
        endDay:
          fieldData?.length === 0
            ? getValues("end_day")
            : fieldData[fieldData.length - 1].endDay,
        beginDay:
          fieldData?.length === 0
            ? getValues("begin_day")
            : fieldData[fieldData.length - 1].beginDay,
        readOnly: true,
      },
    ]);
    setAddFormField(false);
  }, [fieldData]);
  const handleInputChange = (index, value, name) => {
    setFieldData((prevData) =>
      prevData.map((field, i) =>
        i === index ? { ...field, [name]: value } : field
      )
    );
  };
  return (
    <FieldGroup
      className="modify-site-form"
      ref={ref}
      onSubmit={handleSubmit(onSubmit, onError)}
    >
      <InputBox fulled>
        <h6>
          사업장명 <span>*</span>
        </h6>
        <input
          name="work_place_nm"
          type="text"
          {...register("work_place_nm", {
            validate: {
              register: (value) => value !== "" || "사업장명을 등록해 주세요.",
              noSpaces: (value) =>
                value?.trim() !== "" || "사업장명을 등록해 주세요.",
            },
          })}
          maxLength={50}
        />
      </InputBox>

      <InputBox fulled>
        <h6>
          업종 <span>*</span>
        </h6>
        <Controller
          name="work_field"
          control={control}
          render={({ field }) => (
            <SelectedFilterBox
              {...field}
              placeholder={""}
              style={{ width: "100%", height: "37px", font: "16px" }}
              value={getValues("work_field") || workFieldOptions[0]}
              onChange={(e) => {
                setValue("work_field", {
                  value: e.value,
                  label: e.label,
                });
              }}
              options={workFieldOptions}
            ></SelectedFilterBox>
          )}
        />
      </InputBox>

      <InputBox fulled style={{ gridColumn: "1/3" }}>
        <h6>
          사업장 관리자 <span>*</span>
        </h6>

        <ButtonComponent
          type="button"
          onClick={() => {
            // initPopupData();
            setCancelSelectSiteManager(false);
            setOpendSelectSiteManager(true);
          }}
          style={{
            backgroundColor: "#708090",
            color: "aliceblue",
            width: "fit-content",
            lineHeight: "32px",
            boxSize: "border-box",
            padding: "0 9px",
            marginTop: "7px",
          }}
        >
          검색
        </ButtonComponent>

        <input
          name="manager_nm"
          type="text"
          placeholder="검색을 이용해 주세요"
          readOnly={true}
          value={getValues("manager_nm") || ""}
          {...register("manager_nm", {
            validate: {
              register: (value) =>
                value !== "" || "사업장 관리자를 등록해 주세요.",
              noSpaces: (value) =>
                value?.trim() !== "" || "사업장 관리자를 등록해 주세요.",
            },
          })}
        />
      </InputBox>

      <InputBox fulled style={{ gridColumn: "1/3" }}>
        <h6>
          사업장 주소 <span>*</span> (2개 이상의 현장주소 등록은 현장정보
          수정에서 가능합니다.)
        </h6>
        <ButtonComponent
          type="button"
          onClick={() => {
            if (getSiteAddressList?.addressList.length >= 10) {
              alert("최대 10개까지 등록 가능합니다");
              return;
            }
            setOpendMap(true);
            setOpenMapMode({ modeName: "add" });
          }}
          style={{
            backgroundColor: "#708090",
            color: "aliceblue",
            marginTop: "7px",
            width: "fit-content",
            lineHeight: "32px",
            boxSize: "border-box",
            padding: "0 9px",
          }}
        >
          주소등록
        </ButtonComponent>
        <ul
          style={{
            margin: "5px 0 8px",
            minHeight: "56px",
            boxSizing: "border-box",
            background: "#edf2f7",
            padding: "8px 5px",
            borderRadius: "3px",
          }}
        >
          {getSiteAddressList?.addressList &&
            getSiteAddressList?.addressList?.map((e, i) => (
              <li
                style={{
                  padding: "3px 0",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                key={i}
              >
                {`${e.addr1 === null ? "" : e.addr1} ${
                  e.addr2 === null ? "" : e.addr2
                }`}
                {e.addr1 !== null && (
                  <div style={{ display: "flex", columnGap: "15px" }}>
                    {e.addr_seq !== -1 && (
                      <ButtonComponent
                        type="button"
                        style={{
                          backgroundColor: "#fff",
                          color: "#4a5568",
                          border: "1px solid #e2e8f0",
                          lineHeight: "32px",
                        }}
                        onClick={() => {
                          addressDelete(e);
                        }}
                      >
                        삭제
                      </ButtonComponent>
                    )}
                    <ButtonComponent
                      type="button"
                      style={{
                        backgroundColor: "#fff",
                        color: "#4a5568",
                        border: "1px solid #e2e8f0",
                        lineHeight: "32px",
                      }}
                      onClick={() => {
                        setOpendMap(true);
                        setOpenMapMode({
                          modeName: "modify",
                          items: e,
                        });
                      }}
                    >
                      수정
                    </ButtonComponent>
                  </div>
                )}
              </li>
            ))}
        </ul>
      </InputBox>
      <InputBox fulled style={{ gridColumn: "1/3" }}>
        <h6>
          사업장 반경<span style={{ marginLeft: "3px" }}>*</span> (
          사업장에서부터 거리)
        </h6>

        <input
          name="distance"
          type="text"
          {...register("distance", {
            validate: {
              register: (value) => {
                if (!value) {
                  return "사업장 반경을 입력해주세요";
                }

                if (
                  Number(value.replaceAll(",", "")) < 50 ||
                  Number(value.replaceAll(",", "")) > 1000
                ) {
                  return "사업장 반경은 50 ~ 1,000 미터 이내로 등록해 주세요.";
                }
              },
              noSpaces: (value) =>
                value?.trim() !== "" || "사업장 반경을 입력해주세요",
            },
          })}
          onChange={(e) => {
            numberOnly(e);
            e.target.value = addCommas(parseInt(e.target.value));
          }}
        />
        <h6>* 사업장 반경은 50 ~ 1,000 미터 이내로 등록해 주세요.</h6>
      </InputBox>
    </FieldGroup>
  );
});
export default ModifyWorkPlaceForm;
const Icon = styled.img`
  width: 16px;
`;

const Select = styled(ReactSelect)`
  color: #4a5568;
  leading-trim: both;
  text-edge: cap;
  border-radius: 6px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  background: #fff;
  margin-top: 5px;
  margin-bottom: 12px;
  div {
    cursor: pointer;
    color: #4a5568;
    border-color: #e2e8f0;
  }
`;
const Field = styled.div`
  h6 {
    color: #718096;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin: 0;
  }

  span {
    color: #ff3838;
  }

  input {
    display: inline-block;
    -webkit-appearance: none;
    -moz-appearance: none;

    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    appearance: none;
    box-sizing: border-box;
    width: 100%;
    border: 0px;
    outline: none;

    padding: 8px 12px;

    color: #171923;
    leading-trim: both;
    text-edge: cap;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    height: 37px;
    background: #fff;
    border: 1px solid #cbd5e0;
    border-radius: 6px;
    margin-bottom: 12px;
    margin-top: 6px;

    :focus {
    }
  }

  width: ${({ fulled }) => (fulled ? `100%` : "48%")};

  @media only screen and (max-width: 992px) {
    width: 100%;
  }
`;

const FieldGroup = styled.form`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px 64px;

  > div {
    row-gap: 6px;
    display: flex;
    flex-direction: column;
  }

  textarea {
    width: 100%;
    border-radius: 6px;
    border: 1px solid #cbd5e0;
    box-sizing: border-box;
    // margin-top: 6px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    resize: none;
    padding: 8px 12px;
    color: #171923;
    leading-trim: both;
    text-edge: cap;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  input {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    font-size: 16px !important;
    height: 37px;
  }

  label {
    margin-left: 0;
    align-items: flex-start;

    > span,
    input {
      padding: 0;
    }

    .MuiFormControlLabel-label {
      font-size: 14px;
      color: #171923;
      font-weight: 500;
    }
  }

  h5 {
    //margin-bottom: 0 !important;
    //margin-top: 0 !important;
    margin: 0 !important;
    font-size: 14px !important;
  }
`;
const Tabs = styled.div`
  display: flex;
  align-items: center;
  border-radius: 4px;
  background: #edf2f7;
  padding: 4px;
  margin-bottom: 28px;
`;
const Tab = styled.div`
  width: 50%;
  text-align: center;
  padding: 6px 10px;
  border-radius: 4px;
  cursor: pointer;

  h6 {
    margin-bottom: 6px !important;
  }
`;
