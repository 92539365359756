import React, { useEffect } from "react";
import { TableStyle, WidthStyle } from "../../../library/styled-components.js";
import styled from "@emotion/styled";
import { forwardRef } from "react";

import moment from "moment";
const InitFeeModal = forwardRef((props, ref) => {
  const { data } = props;

  return (
    <TableStyle style={{ marginTop: "0px", padding: "0px" }}>
      <TableWrapper>
        <table>
          <thead>
            <tr>
              <th>구분</th>
              <th>대상 기간</th>
              <th>이름</th>
              <th>현장</th>
              <th>입사일</th>
              <th>퇴사일</th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data?.list?.map((payment, index) => (
                <tr key={index}>
                  <WidthStyle width="40px">{index + 1}</WidthStyle>
                  <WidthStyle width="130px">{data?.payDt}</WidthStyle>
                  <td>{payment.userNm}</td>
                  <WidthStyle
                    width={"500px"}
                    space={true}
                    style={{ textAlign: "left" }}
                  >
                    <ListBox>
                      {payment?.siteInfo?.map((item, index) => (
                        <span key={index} style={{ textAlign: "left" }}>
                          {item.siteNm}
                        </span>
                      ))}
                    </ListBox>
                  </WidthStyle>
                  <WidthStyle width="130px">
                    <ListBox>
                      {payment?.siteInfo?.map((item, index) => (
                        <span key={index} style={{ textAlign: "center" }}>
                          {moment(item.joinDt).format("YYYY년 M월 D일")}
                        </span>
                      ))}
                    </ListBox>
                  </WidthStyle>
                  <WidthStyle width="130px">
                    <ListBox>
                      {payment?.siteInfo?.map((item, index) => (
                        <span
                          key={index}
                          style={{
                            color: item.leaveDt ? "black" : "transparent",
                          }}
                        >
                          {moment(item.leaveDt).format("YYYY년 M월 D일")}
                        </span>
                      ))}
                    </ListBox>
                  </WidthStyle>
                </tr>
              ))}
          </tbody>
        </table>
      </TableWrapper>
    </TableStyle>
  );
});

const ListBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* row-gap: 7px; */
  span {
    display: inline-block;
    width: 100%;
    padding: 2px 0;
    box-sizing: border-box;
    border-bottom: dotted 1px #cbd5e0;
    &:last-child {
      border-bottom: none;
    }
  }
`;
const TableWrapper = styled.div`
  th,
  td {
    text-align: center;
  }

  th {
    background-color: #e2e8f0;
  }
`;
export default InitFeeModal;
