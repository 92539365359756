import styled from "@emotion/styled";
import { Header } from "../../../components/Header";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactSelect from "react-select";
import Pagination from "react-js-pagination";
import Modal from "../../../components/Modal";
import ExpenseRequestPagePrint from "../../construction/print/ExpenseRequestPrint";
import { PagingFooter } from "../../../components/PagingFooter";
import { useReactToPrint } from "react-to-print";
import axiosInstance from "../../../library/axios-index";
import AxiosNavigate from "../../../library/axios-navigate";
import { BlockScrolling } from "../../../bin/common";
import PagesList from "../../../components/PagesList";
import moment from "moment";
import SearchBox from "../../../components/Search";
import EmptyContainer from "../../../components/EmptyContainer";
import Loading from "../../../components/Loading";

const options = [
  { value: 20, label: "20" },
  { value: 30, label: "30" },
  { value: 50, label: "50" },
  { value: 100, label: "100" },
];

function ExpenseRequest() {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [loading, setLoading] = useState(true);
  const [opendExport, setOpendExport] = useState(false);
  const [searchTxt, setSearchTxt] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [sites, setSites] = useState([]);
  const [totalCount, setTotalCount] = useState(1);
  const [detailData, setDetailData] = useState({});
  const [selectedSite, setSelectedSite] = useState(0);

  const handleSearchName = useCallback((value) => {
    setSearchTxt(value);
  }, []);

  const handleEmployInfo = useCallback(async (value) => {
    try {
      const token = localStorage.getItem("admin_token");

      const {
        data: { result },
      } = await axiosInstance.post("/admin/expenseRequestInfoList", {
        lang: "ko",
        uuid: "string",
        token,
        site_seq: value.site_seq,
        imp_month: value.imp_month,
      });
      setDetailData(result);
      setOpendExport(true);
      setSelectedSite(value.site_seq);
      return result;
    } catch (error) {
      // 오류 처리 로직
      console.error("Error loading expense request:", error);
      alert("데이터 불러오기 실패");
    }
  }, []);

  BlockScrolling([opendExport]);
  const handlePage = useCallback(
    async (value) => {
      setPage(value);
    },
    [page]
  );

  const loadExpenseRequest = async () => {
    try {
      const token = localStorage.getItem("admin_token");

      const {
        data: { result },
      } = await axiosInstance.post("/admin/expenseRequestList", {
        lang: "ko",
        uuid: "string",
        token,
        search_txt: searchTxt ? searchTxt : "",
        page,
        limit,
      });

      setSites(result.imprests);
      setTotalCount(result.totalCount);

      setTimeout(() => {
        setLoading(false);
      }, 300);
    } catch (error) {
      // 오류 처리 로직
      console.error("Error loading expense request:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await loadExpenseRequest();
    };

    fetchData();
  }, [searchTxt, page, limit]);

  return (
    <AxiosNavigate>
      <div>
        <Header>
          <div>전도금 신청내역</div>
          <div>
            <SearchBox
              placeholder={"현장명"}
              onKeyDown={(e) => {
                if (e.key === "Enter" && e.nativeEvent.isComposing === false) {
                  handleSearchName(e.target.value);
                  setPage(1);
                }
              }}
            ></SearchBox>
          </div>
        </Header>
        {loading ? (
          <Loading></Loading>
        ) : (
          <>
            <TableContainer>
              <TableWrapper>
                <Table>
                  <thead>
                    <tr>
                      <th width={"100px"}>신청일</th>
                      <th>현장명</th>
                      <th>전월 신청액</th>
                      <th>금월 신청액</th>
                      <th>비고</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sites?.length === 0 ? (
                      <tr style={{ height: "500px" }}>
                        <td colSpan={5}>
                          <EmptyContainer
                            falseText={"전도금 신청내역이"}
                            style={{ background: "#fff" }}
                          ></EmptyContainer>
                        </td>
                      </tr>
                    ) : (
                      sites?.map((e, i) => (
                        <tr key={i}>
                          <td>
                            {moment.utc(e.imp_month).format("YYYY년 M월 D일")}
                          </td>
                          <td>
                            <div
                              style={{
                                minWidth: "162px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              {e.site_nm}
                              <Button onClick={() => handleEmployInfo(e)}>
                                상세보기
                              </Button>
                            </div>
                          </td>
                          <td className={"table-right"}>
                            {e.bm_imp_amt.toLocaleString()}
                          </td>
                          <td className={"table-right"}>
                            {e.imp_amt.toLocaleString()}
                          </td>
                          <td>{""}</td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
              </TableWrapper>
            </TableContainer>
            <PagingFooter>
              <Pagination
                prevPageText={<Icon src="/assets/icons/chevron-left.svg" />}
                nextPageText={<Icon src="/assets/icons/chevron-right.svg" />}
                activePage={page}
                itemsCountPerPage={limit}
                totalItemsCount={totalCount === 0 ? 1 : totalCount}
                pageRangeDisplayed={5}
                hideFirstLastPages={true}
                onChange={(e) => {
                  setPage(e);
                }}
              />
              <PagesList
                options={options}
                onChange={(e) => {
                  setLimit(e.value);
                  setPage(1);
                }}
              ></PagesList>
            </PagingFooter>
          </>
        )}

        <Modal
          opend={opendExport}
          closeModal={() => setOpendExport(false)}
          header={"출력 미리보기"}
          okText={"출력"}
          handleOk={() => {
            handlePrint();
          }}
          widthCheck={"55%"}
        >
          <ExpenseRequestPagePrint
            ref={componentRef}
            data={detailData}
            siteSeq={selectedSite}
          />
        </Modal>
      </div>
    </AxiosNavigate>
  );
}

const Panel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 25px;
`;

const Search = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #cbd5e0;
  overflow: hidden;
`;

const SearchIcon = styled.img`
  padding-left: 12px;
`;

const SearchInput = styled.input`
  box-sizing: border-box;
  width: 100%;
  border: 0px;
  outline: none;

  padding: 12px;

  color: #171923;
  leading-trim: both;
  text-edge: cap;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  :focus {
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const Button = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
  min-height: 32px;
  padding: 0 9px;
  justify-content: center;
  align-items: center;
  gap: 9px;
  border-radius: 6px;
  border: 1.125px solid #e2e8f0;
  background: #fff;
  box-sizing: border-box;
  color: #4a5568;
  leading-trim: both;
  text-edge: cap;
  font-size: 14px;
  cursor: pointer;

  //margin-top: 5px;
`;

const Select = styled(ReactSelect)`
  color: #4a5568;
  leading-trim: both;
  text-edge: cap;
  font-size: 14px;
  border-radius: 6px;
  background: #fff;
  margin-top: 5px;

  div {
    cursor: pointer;
    color: #4a5568;
    border-color: #e2e8f0;
  }
`;

const Icon = styled.img`
  width: 16px;
`;

const TableContainer = styled.div`
  padding: 0 25px;
  margin-top: 28px;
`;

const TableWrapper = styled.div`
  border: 1px solid #cbd5e0;
  border-radius: 8px;
  overflow: hidden;
  overflow-x: auto;
`;

const Table = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;

  th,
  td {
    text-align: left;
    //padding: 8px;
    font-size: 13px;
    font-weight: 300;
    border-right: 1px solid #cbd5e0;
    border-bottom: 1px solid #cbd5e0;
    white-space: nowrap;
  }

  th {
    //background-color: #F7FAFC;
    padding: 8px;
  }

  td {
    padding: 2px;
  }

  tr:last-child td {
    border-bottom: none;
  }

  td:first-of-type {
    text-align: center;
  }
`;

const SelectWrapper = styled.div`
  display: flex;
  align-items: center;

  span {
    color: #718096;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-right: 6px;
  }
`;

export default ExpenseRequest;
