import React from "react";
import styled from "@emotion/styled";
import ButtonComponent from "../components/Button.js";
import { useNavigate } from "react-router-dom";
const TypeSelect = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <Wrapper>
        <h2>업종을 선택해주세요.</h2>
        <div>
          <ButtonComponent
            onClick={(event) => {
              event.preventDefault();
              navigate("/const/signup-new");
            }}
          >
            건설업 <br />
            (건설 근로자)
          </ButtonComponent>
          <ButtonComponent
            onClick={(event) => {
              event.preventDefault();
              navigate("/service/signup-new");
            }}
          >
            서비스업 <br />
            (자영업, 음식점 등)
          </ButtonComponent>
        </div>
        <span>관심 업종은 가입 후 “마이페이지”에서 변경 가능합니다.</span>
      </Wrapper>
    </Container>
  );
};

export default TypeSelect;
const Container = styled.div`
  background: #f7fafc;
  min-height: 100vh;
  height: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  width: 100%;
  margin: auto;
  padding-top: 60px;
  padding-bottom: 60px;
  > span,
  h2 {
    text-align: center;
  }

  > div {
    button {
      margin: 20px auto;
      text-align: center;
      padding: 10px 20px;
    }
  }
`;
