import React, { forwardRef } from "react";
import ReactSelect, { components } from "react-select";
import styled from "@emotion/styled";
import { boxSizing, lineHeight, margin, minHeight } from "@mui/system";
import { color } from "chart.js/helpers";

const selectStyles = {
  control: (base, state) => ({
    ...base,
    boxShadow: "none",
    marginTop: "0px",
    minHeight: "34px", // 원하는 높이로 변경,
    color: state.selectProps.color || "#4a5568",
    border: state.selectProps.border || "1px solid #CBD5E0",
    height: state.selectProps.height || "34px",

    "&:hover": {
      border: "1px solid #E2E8F0",
    },
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: "100%", // 원하는 높이로 변경
    padding: "0 6px",
  }),
  input: (provided, state) => ({
    ...provided,
    margin: "0px",
    padding: "0px",
    height: state.selectProps.height || "34px !important",
    backgroundColor: "transparent",
    fontSize: state.selectProps.font || "14px",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "100%", // 원하는 높이로 변경
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: state.selectProps.font || "14px",

    " > div": {
      display: "flex",
      alignItems: "center",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    minHeight: "34px",
    fontSize: state.selectProps.font || "14px",
    lineHeight: "1.4",
    padding: "7px 6px",
    color: state.selectProps.color || "#4a5568",
    boxSizing: "border-box",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontSize: state.selectProps.font || "14px",
    color: state.selectProps.color || "#4a5568",
  }),
};

const Select = styled(ReactSelect)`
  leading-trim: both;
  text-edge: cap;
  border-radius: 6px;
  font-style: normal;
  box-sizing: border-box;
  font-weight: 500;
  background: #fff;
  width: ${(props) => props.width || "150px"};
  border-color: #cbd5e0;

  input {
    height: 100%;
  }
`;
const SingleValueContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SingleValue = ({ children, ...props }) => {
  return (
    <components.SingleValue {...props}>
      <SingleValueContainer>
        <Icon src={`/assets/icons/${props.selectProps.iconName}`} />
        {children}
      </SingleValueContainer>
    </components.SingleValue>
  );
};

const customComponents = {
  Input: (props) => <components.Input {...props} readOnly />, // readOnly 속성 추가,
  SingleValue,
};

const SelectedFilter = forwardRef((props, ref) => {
  const {
    placeholder,
    iconName = "filter-funnel-01.svg",
    options,
    style,
    onChange,
    value = null,
  } = props;

  return (
    <>
      <Select
        ref={ref}
        styles={selectStyles}
        components={customComponents}
        placeholder={
          <div>
            <Icon src={`/assets/icons/${iconName}`} />
            {placeholder}
          </div>
        }
        iconName={iconName}
        options={options}
        width={style?.width || "150px"}
        height={style?.height || "34px"}
        color={style?.color}
        font={style?.font}
        border={style?.border}
        onChange={onChange}
        // value={value}
      ></Select>
    </>
  );
});

const Icon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 6px;
`;

export default SelectedFilter;
