import React, { useEffect, useState } from "react";
import { forwardRef } from "react";
import { FieldGroup } from "../../../../library/styled-components";
import { useForm } from "react-hook-form";
import { InputBox } from "../../../../library/styled-components";
import SearchBox from "../../../../components/Search";
import styled from "@emotion/styled";
import { getPartnersUserList } from "../../../../library/admin-react-query";
import { numberOnly } from "../../../../bin/common";
import { useMutation } from "@tanstack/react-query";
import { modifyPartnersCompany } from "../../../../library/admin-react-query";
const CustomerForm = forwardRef((props, ref) => {
  const { data, data_seq, handleFinish } = props;

  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    setFocus,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });

  const [searchValue, setSearchValue] = useState("");
  const [searchTxt, setSearchTxt] = useState("");
  const [selectedParters, setSelectedParters] = useState([]);
  const [partnersData, setPartnersData] = useState([]);

  const onError = (errors) => {
    const errorKeys = Object.keys(errors);
    if (errorKeys.length > 0) {
      const firstError = errorKeys[0];

      // setFocus(firstError);
      if (errors[firstError].type === "noSpaces") {
        setValue(firstError, "");
      }
      alert(errors[firstError]?.message || "Form validation error");
    }
  };

  // 파트너 리스트 가져오기
  const { mutate: getPartnersUserListMutate, data: getPartnersUserListData } =
    useMutation({
      mutationKey: ["getPartnersUserList"],
      mutationFn: getPartnersUserList,
      onSuccess: async (isData) => {},
      onError: (error) => {
        console.log(error);
      },
    });

  //고객사 파트너 정보 수정
  const { mutate: modifyPartnersCompanyMutate } = useMutation({
    mutationKey: ["modifyPartnersCompany"],
    mutationFn: modifyPartnersCompany,
    onSuccess: async (data) => {
      if (data.done) {
        alert("수정이 완료되었습니다");
        handleFinish();
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  useEffect(() => {
    const items = {
      searchTxt: searchTxt,
      withdrawYn: "N",
      page: 1,
      limit: 1000,
    };
    getPartnersUserListMutate(items);
  }, [searchTxt]);

  useEffect(() => {
    setSelectedParters(data);
    data.map((i) => {
      setValue(`rebate_${i?.partner_seq}`, i?.rebate_amt);
    });
  }, []);

  useEffect(() => {
    selectedParters?.map((e) => {
      if (!getValues(`rebate_${e?.partner_seq}`)) {
        setValue(`rebate_${e?.partner_seq}`, 0);
        setFocus(`rebate_${e?.partner_seq}`);
      }
    });
  }, [selectedParters]);

  const onSubmit = (e) => {
    let sum = 0;
    selectedParters.map((item) => {
      sum = sum + Number(e[`rebate_${item.partner_seq}`]);
    });

    if (sum >= 100) {
      alert("리베이트 비율 총합계가 100이하만 가능합니다");
      return;
    }

    const items = {
      corpSeq: data_seq,
      partnerInfos: selectedParters.map((item) => {
        return {
          partnerSeq: item.partner_seq || item.partners_seq,
          corpSeq: data_seq,
          rebateType: "FR",
          rebateAmt: Number(e[`rebate_${item.partner_seq}`]),
          note: "",
        };
      }),
    };
    modifyPartnersCompanyMutate(items);
  };

  return (
    <FieldGroup
      className="customer-form"
      ref={ref}
      onSubmit={handleSubmit(onSubmit, onError)}
    >
      <InputBox fulled>
        <h6>파트너 검색</h6>
        <SearchWrap>
          <SearchBox
            style={{ height: "39px" }}
            onKeyDown={(e) => {
              if (e.key === "Enter" && e.nativeEvent.isComposing === false) {
                if (
                  e.target.value.length > 0 &&
                  e.target.value.trim().length === 0
                ) {
                  alert("법인을 입력해 주세요");
                  setSearchValue(e.target.value.trim());
                  return;
                }
                setSearchTxt(e.target.value);
                setSearchValue(e.target.value.trim());
              }
            }}
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
          ></SearchBox>
          <CompanyList>
            {getPartnersUserListData?.list?.map((e, i) => (
              <CompanyItem
                key={e.partners_seq}
                id={"siteItem" + e.partners_seq}
                className={
                  selectedParters.some(
                    (item) => item.partners_id === e.partners_id
                  )
                    ? "action"
                    : ""
                }
                onClick={(event) => {
                  if (selectedParters.length === 3) {
                    alert("파트너 등록은 최대 3개만 가능합니다");
                    return;
                  }
                  const test = selectedParters.some((item) => {
                    return e.partners_id === item.partners_id;
                  });
                  if (test) {
                    return;
                  }

                  setSelectedParters((prevState) => [
                    ...prevState,
                    {
                      ...e,
                      partner_seq: e.partners_seq,
                    },
                  ]);
                }}
              >
                {e.partners_nm} / {e.partners_id} / {e.phone_num}
              </CompanyItem>
            ))}
          </CompanyList>
        </SearchWrap>
      </InputBox>

      <InputBox fulled>
        <h6>등록 파트너</h6>
        <SelectedList>
          {selectedParters?.map((e, i) => (
            <Label
              key={e.partner_seq || e.partners_seq}
              style={{ cursor: "pointer" }}
            >
              <div>
                <div>{i + 1}차</div>
                {e.partners_nm}
                <div>
                  리베이트 비율
                  <input
                    name={`rebate_${e.partner_seq || e.partners_seq}`}
                    type="text"
                    {...register(`rebate_${e.partner_seq || e.partners_seq}`, {
                      validate: {
                        register: (value) => {
                          return value !== ""
                            ? (value > 0 && value <= 100) ||
                                "리베이트 비율을 확인해주세요"
                            : "리베이트 비율을 입력해주세요";
                        },
                      },
                    })}
                    onChange={(event) => {
                      numberOnly(event);
                      setValue(
                        `rebate_${e?.partner_seq || e.e.partners_seq}`,
                        Number(event.target.value)
                      );
                    }}
                    style={{
                      width: "55px",
                      padding: "4px 8px",
                      margin: "0px 10px",
                    }}
                    maxLength={3}
                  />
                  %
                </div>
              </div>
              <Icon
                style={{ marginLeft: "2px" }}
                onClick={(event) => {
                  event.preventDefault();
                  setValue(`rebate_${e?.partner_seq || e.partners_seq}`, 0);
                  setSelectedParters((prevState) =>
                    prevState.filter(
                      (item) => item.partners_id !== e.partners_id
                    )
                  );
                }}
                src="/assets/icons/left-icon.svg"
              />
            </Label>
          ))}
        </SelectedList>
      </InputBox>
    </FieldGroup>
  );
});

const Icon = styled.img`
  width: 16px;
`;
const Label = styled.span`
  padding: 2px 4px;
  align-items: center;

  border-radius: 6px;
  //border: 1px solid #E2E8F0;
  /* background-color: #d7fbe2; */
  color: #718096;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  width: 100%;
  display: flex;
  height: 30px;
  justify-content: space-between;
  box-sizing: border-box;

  > div {
    display: flex;
    column-gap: 20px;
    align-items: center;
  }

  input {
    font-size: 14px;
    text-align: right;
  }
`;
const SelectedList = styled.div`
  overflow: auto;
  min-height: 150px;
  height: 150px;
  padding: 7px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #cbd5e0;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  row-gap: 6px;
`;
const CompanyList = styled.div`
  overflow: auto;
  height: 150px;
  margin: 5px 0;
  box-sizing: border-box;
  background-color: #f7fafc;
  //border-radius: 4px;
  //border: 1px solid #CBD5E0;
`;

const CompanyItem = styled.div`
  color: #4a5568;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding: 8px;
  cursor: pointer;

  &&.action {
    background-color: #d7fbe2;
  }

  :hover {
    background: rgba(0, 0, 0, 0.05) !important;
  }
`;

const SearchWrap = styled.div`
  input {
    border: none;
  }
`;
export default CustomerForm;
