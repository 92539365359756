import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import Modal from "../../../components/Modal";
import axiosInstance from "../../../library/axios-index";
import AxiosNavigate from "../../../library/axios-navigate";

function SettingAdmin() {
  const [adminList, setAdminList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [openAdminModal, setOpenAdminModal] = useState(false);
  const [openCompanyModal, setOpenCompanyModal] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState({});
  const [selectedCompany, setSelectedCompany] = useState({});
  const [searchAdmin, setSearchAdmin] = useState("");
  const [searchCompany, setSearchCompany] = useState("");

  const handleSelectAdmin = (value) => {
    setSelectedAdmin(value);
    setOpenAdminModal(false);
  };

  const handleSelectCompany = (value) => {
    setSelectedCompany(value);
    setOpenCompanyModal(false);
  };

  const loadAdminList = async () => {
    const token = localStorage.getItem("admin_token");

    const request = {
      lang: "ko",
      uuid: "string",
      token,
      search_txt: searchAdmin,
    };

    const {
      data: { result },
    } = await axiosInstance.post("/company/searchManagerList", request);

    setAdminList(result.laborEmployees);
  };

  const loadCompanyList = async () => {
    const token = localStorage.getItem("admin_token");

    const request = {
      lang: "ko",
      uuid: "string",
      token,
      search_txt: searchCompany,
    };

    const {
      data: { result },
    } = await axiosInstance.post("/company/searchConstSiteList", request);
    setCompanyList(result.laborEmployees);
  };

  const saveAdmin = async () => {
    if (!selectedAdmin.user_seq) {
      alert("관리자를 선택해주세요");
      return;
    }

    if (!selectedCompany.site_seq) {
      alert("현장을 선택해주세요");
      return;
    }

    const token = localStorage.getItem("admin_token");

    const request = {
      lang: "ko",
      uuid: "string",
      token,
      user_seq: selectedAdmin.user_seq,
      site_seq: selectedCompany.site_seq,
    };

    try {
      const response = await axiosInstance.post(
        "/company/settingManager",
        request
      );
      if (response.data.code === 200) {
        alert("저장되었습니다");
        setSelectedAdmin({});
        setSelectedCompany({});
        return;
      } else {
        alert("저장에 실패하였습니다");
        return;
      }
    } catch (error) {
      console.error(error);
      alert(error.response.data.message);
      return;
    }
  };

  useEffect(() => {
    loadAdminList();
  }, [searchAdmin]);

  useEffect(() => {
    loadCompanyList();
  }, [searchCompany]);

  return (
    <AxiosNavigate>
      <div>
        <Panel>
          <div>
            <BoardHeader>
              <h2>관리자 설정</h2>
            </BoardHeader>
          </div>
        </Panel>

        <div style={{ display: "flex" }}>
          <Button
            onClick={() => {
              setOpenAdminModal(true);
              setSearchAdmin("");
            }}
          >
            관리자 선택
          </Button>
          <h5>
            {selectedAdmin.user_nm} / {selectedAdmin.phone_num}
          </h5>
        </div>
        <div style={{ display: "flex" }}>
          <Button
            onClick={() => {
              setOpenCompanyModal(true);
              setSearchCompany("");
            }}
          >
            회사,현장 선택
          </Button>
          <h5>{selectedCompany.site_nm}</h5>
        </div>

        <Button
          onClick={() => {
            saveAdmin();
          }}
        >
          저장
        </Button>

        <Modal
          opend={openAdminModal}
          closeModal={() => {
            setOpenAdminModal(false);
          }}
          header={"관리자 선택"}
          okText={"확인"}
          handleOk={() => {}}
          widthCheck={"50%"}
        >
          {
            <div>
              <Search>
                <SearchIcon src="/assets/icons/search-sm.svg" />
                <SearchInput
                  placeholder="관리자 이름을 검색해주세요"
                  onKeyDown={(e) =>
                    e.key === "Enter" && setSearchAdmin(e.target.value)
                  }
                />
              </Search>

              {!adminList.length ? (
                <EmptyLaborCard>결과가 없습니다</EmptyLaborCard>
              ) : (
                adminList.map((e, i) => (
                  <LaborCard key={i} onClick={() => handleSelectAdmin(e)}>
                    <div>
                      <Avatar
                        style={{
                          backgroundImage: `url(${e.photo || "/avatar.png"})`,
                        }}
                      />
                    </div>
                    <LaborCardContent>
                      <h5>
                        {e.user_nm} / {e.phone_num}
                      </h5>
                    </LaborCardContent>
                  </LaborCard>
                ))
              )}
            </div>
          }
        </Modal>

        <Modal
          opend={openCompanyModal}
          closeModal={() => {
            setOpenCompanyModal(false);
          }}
          header={"회사 선택"}
          okText={"확인"}
          handleOk={() => {}}
          widthCheck={"50%"}
        >
          {
            <div>
              <Search>
                <SearchIcon src="/assets/icons/search-sm.svg" />
                <SearchInput
                  placeholder="회사 이름을 검색해주세요"
                  onKeyDown={(e) =>
                    e.key === "Enter" && setSearchCompany(e.target.value)
                  }
                />
              </Search>

              {!companyList.length ? (
                <EmptyLaborCard>결과가 없습니다</EmptyLaborCard>
              ) : (
                companyList.map((e, i) => (
                  <LaborCard key={i} onClick={() => handleSelectCompany(e)}>
                    <LaborCardContent>
                      <h5>{e.site_nm}</h5>
                    </LaborCardContent>
                  </LaborCard>
                ))
              )}
            </div>
          }
        </Modal>
      </div>
    </AxiosNavigate>
  );
}

const Panel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 32px;
`;

const Search = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #cbd5e0;
  overflow: hidden;
`;

const SearchIcon = styled.img`
  padding-left: 12px;
`;

const SearchInput = styled.input`
  box-sizing: border-box;
  width: 100%;
  border: 0px;
  outline: none;

  padding: 6px;

  color: #171923;
  leading-trim: both;
  text-edge: cap;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  :focus {
  }
`;

const Button = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
  min-height: 32px;
  padding: 0 9px;
  margin: 9px 20px;
  justify-content: center;
  align-items: center;
  gap: 9px;
  border-radius: 6px;
  border: 1.125px solid #e2e8f0;
  background: #fff;

  color: #4a5568;
  leading-trim: both;
  text-edge: cap;
  font-size: 14px;
  cursor: pointer;
`;

const BoardHeader = styled.div`
  margin-bottom: 14px;
  margin-top: 28px;

  h2,
  h5 {
    margin: 0;
  }

  h5 {
    color: #4a5568;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-top: 4px;

    span {
      color: #718096;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
  }
`;

const Content = styled.div`
  figure {
    margin: 4px;
    display: flex;
    align-items: center;
    height: 34px;
  }

  h6,
  h5 {
    margin: 0;
  }

  h6 {
    color: #718096;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    width: 140px;
  }

  h5 {
    color: rgba(0, 0, 0, 0.92);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  hr {
    background: #edf2f7;
    color: #edf2f7;
    border: 1px solid #edf2f7;
    margin: 12px 0;
  }
`;

const LaborCard = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 14px 0;
  cursor: pointer;

  &:hover {
    background-color: #ebf2fe !important;
    border-radius: 4px;
  }
`;

const LaborCardContent = styled.div`
  h5,
  h6 {
    margin: 0;
  }

  h5 {
    color: #4a5568;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }

  h6 {
    color: #718096;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
`;

const Avatar = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  margin-left: 8px;
`;

const EmptyLaborCard = styled.div`
    color: #4A5568;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px
    border: 1px solid #ff3838;
    text-align: center;
    padding-top: 26px;
`;

export default SettingAdmin;
