import AppLayout from "./app-layout";
import Login from "./pages/login.js";
import Dashboard from "./pages/construction/dashboard.js";
import Report from "./pages/construction/report.js";
import Site from "./pages/construction/site.js";
import LaborAttendance from "./pages/construction/labor/attendance";
import LaborAttendanceStatus from "./pages/construction/labor/attendance-status";
import LaborEmploy from "./pages/construction/labor/employ";
import LaborPaymentStatement from "./pages/construction/labor/payment-statement";
import LaborStatementSubmission from "./pages/construction/labor/statement-submission";
import LaborTransferRequest from "./pages/construction/labor/transfer-request";
import ExpenseStatus from "./pages/construction/expense/status";
import ExpenseRequest from "./pages/construction/expense/request";
import SettingCompany from "./pages/construction/setting/company";
import SettingEmploy from "./pages/construction/setting/employ";
import SettingRole from "./pages/construction/setting/role";
import SettingNotice from "./pages/construction/setting/notice";
import SettingAdmin from "./pages/construction/setting/admin";
import Company from "./pages/construction/admin/company";
import User from "./pages/construction/admin/user";
import LaborMonthlyPaymentStatement from "./pages/construction/labor/monthly-payment-statement";
import SignUpNew from "./pages/construction/signup_new.js";
import Welcome from "./pages/construction/welcome.js";
import UpgradePlan from "./pages/construction/upgrade-plan.js";
import UpdateCompanyInfo from "./pages/construction/update-company-info.js";
import PaymentInformation from "./pages/construction/setting/payment-information";
import FindIdPassword from "./pages/construction/find-id-password.js";
import SiteAdmin from "./pages/construction/admin/site";
import LaborInsuranceDetail from "./pages/construction/labor/insurance-detail";
import PaymentInformationAdmin from "./pages/construction/admin/payment-information";
import LaborDownloadReport from "./pages/construction/labor/download-report-data";
import NoticeAdmin from "./pages/construction/admin/notice";
import OutsourcingManagement from "./pages/construction/outsourcing/outsourcing-management";
import DashBoardAdmin from "./pages/construction/admin/dashboard";
import DiscountApplyAdmin from "./pages/construction/admin/discount-apply";
import DiscountRuleAdmin from "./pages/construction/admin/discount-rule";
import DashBoardPartnersAdmin from "./pages/construction/admin/partners/dashboard";
import PartnersAdmin from "./pages/construction/admin/partners/partners";
import PaymentPartnersAdmin from "./pages/construction/admin/partners/payment-information";
import CustomerPartnersAdmin from "./pages/construction/admin/partners/customer";
import ConstAppLayout from "./const-app-layout.js";
import TypeSelect from "./pages/type-select.js";

// 서비스
import ServiceSignUp from "./pages/service/service-signup.js";
import ServiceAppLayout from "./pages/service/service-app-layout.js";
import ServiceDashboard from "./pages/service/dashboard.js";
import WorkPlace from "./pages/service/work-place.js";
import Employ from "./pages/service/employ/employ.js";
import EmployPaymentStatement from "./pages/service/employ/payment-statement.js";
import EmployTransferRequest from "./pages/service/employ/transfer-request.js";
import ServiceExpenseStatus from "./pages/service/expense/status.js";
import ServiceSettingCompany from "./pages/service/setting/company.js";
import SettingTaxAccountant from "./pages/service/setting/tax-accountant.js";
import ServicePaymentInformation from "./pages/service/expense/payment-information.js";

const routes = [
  // 건설
  {
    path: "/const/",
    element: <ConstAppLayout />,
    children: [
      {
        path: "admin/company",
        element: <Company />,
      },
      {
        path: "admin",
        element: <SettingAdmin />,
      },
      {
        path: "admin/site",
        element: <SiteAdmin />,
      },
      {
        path: "admin/user",
        element: <User />,
      },
      {
        path: "admin/discount-rule",
        element: <DiscountRuleAdmin />,
      },
      {
        path: "admin/discount-apply",
        element: <DiscountApplyAdmin />,
      },
      {
        path: "admin/payment-information",
        element: <PaymentInformationAdmin />,
      },
      {
        path: "admin/notice",
        element: <NoticeAdmin />,
      },
      {
        path: "admin/board",
        element: <DashBoardAdmin />,
      },
      {
        path: "partners/admin/customer",
        element: <CustomerPartnersAdmin />,
      },
      {
        path: "partners/admin",
        element: <PartnersAdmin />,
      },
      {
        path: "partners/admin/payment-information",
        element: <PaymentPartnersAdmin />,
      },
      {
        path: "partners/admin/board",
        element: <DashBoardPartnersAdmin />,
      },
      {
        path: "labor/attendance",
        element: <LaborAttendance />,
      },
      {
        path: "labor/attendance-status",
        element: <LaborAttendanceStatus />,
      },
      {
        path: "labor/employ",
        element: <LaborEmploy />,
      },
      {
        path: "labor/payment-statement",
        element: <LaborPaymentStatement />,
      },
      {
        path: "labor/monthly-payment-statement",
        element: <LaborMonthlyPaymentStatement />,
      },
      {
        path: "labor/statement-submission",
        element: <LaborStatementSubmission />,
      },
      {
        path: "labor/transfer-request",
        element: <LaborTransferRequest />,
      },
      {
        path: "labor/insurance-detail",
        element: <LaborInsuranceDetail />,
      },
      {
        path: "labor/download-report",
        element: <LaborDownloadReport />,
      },
      {
        path: "expense/status",
        element: <ExpenseStatus />,
      },
      {
        path: "expense/request",
        element: <ExpenseRequest />,
      },
      {
        path: "outsourcing/management",
        element: <OutsourcingManagement />,
      },
      {
        path: "setting/company",
        element: <SettingCompany />,
      },
      {
        path: "setting/employ",
        element: <SettingEmploy />,
      },
      {
        path: "setting/role",
        element: <SettingRole />,
      },
      {
        path: "setting/notice",
        element: <SettingNotice />,
      },
      {
        path: "setting/payment-information",
        element: <PaymentInformation />,
      },
      {
        path: "",
        element: <Dashboard />,
      },
      {
        path: "report",
        element: <Report />,
      },
      {
        path: "site",
        element: <Site />,
      },
    ],
  },

  // 서비스
  {
    path: "/service/",
    element: <ServiceAppLayout />,
    children: [
      {
        path: "",
        element: <ServiceDashboard />,
      },
      {
        path: "work-place",
        element: <WorkPlace />,
      },
      {
        path: "employ",
        element: <Employ />,
      },
      {
        path: "employ/payment-statement",
        element: <EmployPaymentStatement />,
      },
      {
        path: "employ/transfer-request",
        element: <EmployTransferRequest />,
      },
      {
        path: "expense/status",
        element: <ServiceExpenseStatus />,
      },
      {
        path: "setting/company",
        element: <ServiceSettingCompany />,
      },
      {
        path: "setting/payment-information",
        element: <ServicePaymentInformation />,
      },
      {
        path: "setting/tax-accountant",
        element: <SettingTaxAccountant />,
      },
    ],
  },

  // 기본
  {
    path: "/",
    element: <AppLayout />,
    children: [
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "find-account/:text",
        element: <FindIdPassword />,
      },
      {
        path: "const/signup-new",
        element: <SignUpNew />,
      },
      {
        path: "service/signup-new",
        element: <ServiceSignUp />,
      },
      {
        path: "company-info",
        element: <UpdateCompanyInfo />,
      },
      {
        path: "welcome",
        element: <Welcome />,
      },
      {
        path: "upgrade-plan",
        element: <UpgradePlan />,
      },
      {
        path: "type-select",
        element: <TypeSelect />,
      },
    ],
  },
];

export { routes };
