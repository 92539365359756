import React, { useCallback, useEffect, useRef, useState } from "react";
import AxiosNavigate from "../../../library/axios-navigate";
import { Header } from "../../../components/Header";
import styled from "@emotion/styled";
import SiteContainers from "../../../components/SiteContainer";
import axiosInstance from "../../../library/axios-index";
import ButtonComponent from "../../../components/Button";
import OutsourcingHistory from "./outsourcing-history";
import { outsourcingCompanyState } from "../../../library/recoil/atoms/outsourcingCompanyState";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import SideModal from "../../../components/SideModal";
import { ModalButton } from "../../../components/ModalButton";
import { RightContent } from "../../../library/styled-components";
import Modal from "../../../components/Modal";
import { FormProvider, useForm } from "react-hook-form";
import OutsourcingForm from "./outsourcing-form";
import EmptyContainer from "../../../components/EmptyContainer";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  createSubPartner,
  getAdminSubPartner,
  modifySubPartner,
} from "../../../library/axios-query";
import CardInformation from "../../../components/CardInformation";

const OutsourcingManagement = () => {
  const queryClient = useQueryClient();

  const setOutsourcingState = useSetRecoilState(outsourcingCompanyState);
  const resetOutsourcingState = useResetRecoilState(outsourcingCompanyState);
  const outsourcingState = useRecoilValue(outsourcingCompanyState);
  const methods = useForm();
  const formRef = useRef();

  const [sites, setSites] = useState([]);
  const [siteName, setSiteName] = useState("현장이름 테스트");
  const [siteList, setSiteList] = useState([]);
  const [selectedSite, setSelectedSite] = useState(null);
  const [selectedPartner, setSelectedPartner] = useState(null);

  const [openedHistory, setOpenedHistory] = useState(false);
  const [opendDetailSite, setOpendDetailSite] = useState(false);
  const [openedManageForm, setOpenedManageForm] = useState(false);
  const [openedFormCreate, setOpenedFormCreate] = useState(false); // true => 추가 false => 수정

  // 현장 협력사 목록
  const { mutate: getSubPartner, data: getSubPartnerData } = useMutation({
    mutationKey: "getAdminSubPartner",
    mutationFn: getAdminSubPartner,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["getAdminSubPartner"] });

      if (opendDetailSite || openedHistory) {
        const listData = data.list.filter((item) => {
          return item.partner_seq === outsourcingState?.partner_seq;
        });

        if (listData.length === 0) return;
        setOutsourcingState(listData[0]);
      }
    },
    onError: (error) => {
      throw error;
    },
  });

  // 현장 협력사 수정
  const { mutate: modifySubPartnerItem, data: modifySubPartnerData } =
    useMutation({
      mutationKey: "modifySubPartner",
      mutationFn: modifySubPartner,
      onSuccess: (data) => {
        if (data.done === true) {
          getSubPartner({ site_seq: selectedSite });
          setOpenedManageForm(false);
        }
      },
      onError: (error) => {
        throw error;
      },
    });

  // 현장 협력사 등록
  const { mutate: createSubPartnerItem, data: createSubPartnerData } =
    useMutation({
      mutationKey: "createSubPartner",
      mutationFn: createSubPartner,
      onSuccess: (data) => {
        if (data.done === true) {
          getSubPartner({ site_seq: selectedSite });
          //   getSubPartner(selectedSite);
          setOpenedManageForm(false);
        }
      },
      onError: (error) => {
        throw error;
      },
    });

  const handleAllSites = useCallback(async () => {
    const token = localStorage.getItem("admin_token");

    const {
      data: { result },
    } = await axiosInstance.post(`/admin/searchSite`, {
      lang: "ko",
      uuid: "string",
      //token,
      search_txt: "",
    });
    setSites(result);
    setSiteList(result);
  }, []);

  const handleSearchSite = (value) => {
    const list = siteList.filter((e) =>
      e.site_nm
        .toLowerCase()
        .replaceAll(" ", "")
        .includes(value.toLowerCase().replaceAll(" ", ""))
    );
    setSites(list);
  };

  const handleSelectedSite = (e) => {
    getSubPartner({ site_seq: e });
    setSelectedSite(e);
  };

  const handleOpenPrintModal = useCallback(() => {
    alert("준비중 입니다");
  }, []);

  const handleExcel = () => {
    alert("준비중 입니다");
  };
  const loadReport = async () => {};

  useEffect(() => {
    handleAllSites();
  }, []);

  useEffect(() => {
    loadReport();
  }, [selectedSite]);

  const handleEdit = (event, value) => {
    event.stopPropagation();
    setOutsourcingState(value);
    // alert('정보 수정 오픈');
    setOpendDetailSite(true);
  };

  const handleHistoryChange = (value) => {
    if (!openedHistory) {
      setOutsourcingState(value);
    } else {
      getSubPartner({ site_seq: selectedSite });
    }
    setOpenedHistory(!openedHistory);
  };

  const handleForm = (value) => {
    if (openedFormCreate) {
      // 추가
      value.site_seq = selectedSite;
      delete value.complete_yn;
      delete value.partner_seq;
      delete value.stop_yn;
      createSubPartnerItem(value);
    } else {
      // 수정
      modifySubPartnerItem(value);
    }
  };
  const handleHistoryModify = (value) => {
    getSubPartner({ site_seq: selectedSite, partner_seq: value });
  };

  return (
    <AxiosNavigate>
      <div>
        <Header>
          <div>외주 관리</div>
        </Header>

        <Content>
          {openedHistory ? (
            <>
              <OutsourcingHistory
                handleHistoryChange={handleHistoryChange}
                handleHistoryModify={handleHistoryModify}
              ></OutsourcingHistory>
            </>
          ) : (
            <>
              {" "}
              <Info>
                <SiteContainers
                  data={{
                    sites,
                    handleAllSites,
                    handleSearchSite,
                    handleSelectedSite,
                  }}
                />
              </Info>
              <Board>
                {getSubPartnerData === undefined ? (
                  <EmptyContainer
                    style={{ height: "100%" }}
                    selected={true}
                  ></EmptyContainer>
                ) : (
                  <>
                    <Panel>
                      <div>
                        <BoardHeader>
                          <h5>현장명</h5>
                          <h2>{getSubPartnerData?.siteNm}</h2>
                        </BoardHeader>
                      </div>
                      <div>
                        <ButtonGroup>
                          <ButtonComponent
                            style={{ height: "39px" }}
                            onClick={() => {
                              resetOutsourcingState();
                              setOpenedFormCreate(true);
                              setOpenedManageForm(true);
                            }}
                          >
                            <Icon src="/assets/icons/plus.svg" />
                            협력사 등록
                          </ButtonComponent>
                        </ButtonGroup>
                      </div>
                    </Panel>
                    {getSubPartnerData?.list?.length === 0 ? (
                      <EmptyContainer
                        style={{ height: "100%" }}
                        selected={false}
                        falseText={"외주 관리 목록이"}
                      ></EmptyContainer>
                    ) : (
                      <CardGroups>
                        {getSubPartnerData?.list &&
                          getSubPartnerData?.list.map((e, i) => (
                            <CardInformation
                              key={i}
                              title={e.cmpny_nm}
                              contract={e.contract_amt}
                              total={e.pay_amt}
                              contract_type={e.contract_type}
                              handleEdit={(event) => handleEdit(event, e)}
                              handleHistoryChange={() => handleHistoryChange(e)}
                            ></CardInformation>
                          ))}
                      </CardGroups>
                    )}
                  </>
                )}
              </Board>
            </>
          )}
        </Content>

        <SideModal
          opend={opendDetailSite}
          closeModal={() => {
            setOpendDetailSite(false);
          }}
          header={"외주 정보"}
          buttons={
            <>
              <ModalButton
                onClick={() => {
                  setOpenedFormCreate(false);
                  setOpenedManageForm(true);
                }}
              >
                수정하기
              </ModalButton>
            </>
          }
        >
          <RightContent>
            <figure>
              <h6>현장명</h6>
              <h5>{getSubPartnerData?.siteNm}</h5>
            </figure>
            <figure>
              <h6>업체명/개인명</h6>
              <h5>{outsourcingState?.cmpny_nm}</h5>
            </figure>
            <figure>
              <h6>계약방식</h6>
              <h5>
                {outsourcingState?.contract_type === "G" ? "총액" : "단가"}계약
              </h5>
            </figure>
            {outsourcingState?.contract_type === "G" && (
              <figure>
                <h6>총계약 금액</h6>
                <h5>
                  {Number(outsourcingState?.contract_amt).toLocaleString()}원
                </h5>
              </figure>
            )}
            <figure>
              <h6>담당자명</h6>
              <h5>{outsourcingState?.manager}</h5>
            </figure>
            <figure>
              <h6>연락처</h6>
              <h5>{outsourcingState?.phone}</h5>
            </figure>

            <figure>
              <h6>사업자등록번호</h6>
              {outsourcingState?.reg_num !== "" && (
                <h5>
                  {outsourcingState?.reg_num?.slice(0, 3)}-
                  {outsourcingState?.reg_num?.slice(3, 5)}-
                  {outsourcingState?.reg_num?.slice(5, 10)}
                </h5>
              )}
            </figure>
            <hr />
            {outsourcingState?.reg_photo !== "" && (
              <figure
                style={{
                  flexDirection: "column",
                  alignItems: "flex-start",
                  rowGap: "7px",
                  marginBottom: "20px",
                }}
              >
                <h6>사업자등록증</h6>
                <img
                  src={outsourcingState?.reg_photo}
                  style={{ width: "150px", height: "150px" }}
                  alt={"사업자등록증"}
                />
              </figure>
            )}
            {outsourcingState?.acct_photo !== "" && (
              <figure
                style={{
                  flexDirection: "column",
                  alignItems: "flex-start",
                  rowGap: "7px",
                }}
              >
                <h6>통장사본</h6>
                <img
                  src={outsourcingState?.acct_photo}
                  style={{ width: "150px", height: "150px" }}
                  alt={"통장사본"}
                />
              </figure>
            )}
          </RightContent>
        </SideModal>

        {/*  협력사 등록 / 수정  */}
        <Modal
          opend={openedManageForm}
          closeModal={() => setOpenedManageForm(false)}
          header={`외주 정보 ${openedFormCreate === true ? "등록" : "수정"}`}
          okText={`${openedFormCreate === true ? "등록" : "수정"}하기`}
          widthCheck={"45%"}
          formRef={formRef}
        >
          <FormProvider {...methods}>
            <OutsourcingForm
              ref={formRef}
              fromState={openedFormCreate}
              getSubPartnerData={getSubPartnerData?.list}
              handleForm={handleForm}
            ></OutsourcingForm>
          </FormProvider>
        </Modal>
      </div>
    </AxiosNavigate>
  );
};

const CardGroups = styled.div`
  padding: 0 25px;
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 25px;

  @media only screen and (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media only screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
const BoardHeader = styled.div`
  display: flex;
  align-items: center;
  column-gap: 15px;

  h2,
  h5 {
    margin: 0;
  }

  h5 {
    font-size: 14px;
  }
`;
const Panel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 25px;
`;
const Icon = styled.img`
  width: 16px;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 992px) {
    flex-direction: row;
  }
  @media only screen and (max-width: 992px) {
    .loading-wrap {
      width: 100vw;
      left: 0;
      top: 450px;
      height: calc(100vh - 450px);
    }
  }
`;
const Button = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 9px;
  justify-content: center;
  align-items: center;
  width: fit-content;
  min-height: 37px;
  height: 37px;
  gap: 9px;
  border: 1.125px solid #e2e8f0;
  border-radius: 6px;
  background: #fff;
  color: #4a5568;
  font-size: 14px;
  font-style: normal;
  cursor: pointer;

  leading-trim: both;
  text-edge: cap;
  // margin-top: 5px;
`;

const Board = styled.div`
  border-radius: 16px;
  background: #f7fafc;
  width: 100%;
  margin-top: 24px;
  padding: 28px 0;
  box-sizing: border-box;
  min-height: 729px;
  @media only screen and (min-width: 992px) {
    margin-top: 0;
  }
`;
const Info = styled.div`
  padding: 0 25px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  > div {
    margin-top: 0;
  }

  @media only screen and (max-width: 992px) {
    > div {
      margin-left: 0;
      width: 100%;
    }
  }
`;

const CalendarContainer = styled.div`
  width: 100%;
  padding: 20px 24px 32px 24px;
  border-radius: 16px;
  border: 1px solid #e2e8f0;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);

  @media only screen and (min-width: 768px) {
    width: 30%;
  }

  @media only screen and (min-width: 992px) {
    width: 280px;
  }
`;
const ButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;
export default OutsourcingManagement;
