import React, { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import styled from "@emotion/styled";
import { useForm } from "react-hook-form";
import "react-quill/dist/quill.snow.css";

import JoditEditor from "jodit-react";
import FileUpload from "../../../../components/FileUpload";
import { htmlToText } from "../../../../bin/common";
import { InputBox } from "../../../../library/styled-components";
import CustomChecked from "../../../../components/CustomChecked";
import { useMutation } from "@tanstack/react-query";
import {
  createPartnersBoard,
  updatePartnersBoard,
} from "../../../../library/admin-react-query";
import { handleFilesName } from "../dashboard";

const DashBoardPartnersForm = forwardRef((props, ref) => {
  const [editor, setEditor] = useState("");
  const [files, setFiles] = useState([]);

  const editorRef = useRef(null);
  const editorContainerRef = useRef(null);
  const [isLayoutReady, setIsLayoutReady] = useState(false);
  const [dataFix, setDataFix] = useState(false);
  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    setFocus,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });

  const dashBordData = props?.data;
  const modify = props.modify;
  const finish = props?.finish;

  // 툴바에서 버튼 아이콘 설정
  const config = useMemo(
    () => ({
      readonly: false, // all options from https://xdsoft.net/jodit/docs/,
      placeholder: "",
      buttons: [
        "fontsize",
        "bold",
        "italic",
        "underline",
        "strikethrough",
        "eraser",
        "superscript",
        "subscript",
        "ul",
        "ol",
        "outdent",
        "indent",
        "brush",
        "paragraph",
        "image",
        //'table',
        "align",
        "undo",
        "redo",
        // 동영상 관련 버튼 "video"는 제외
      ],
      uploader: {
        insertImageAsBase64URI: true, // 이미지 업로드 시 Base64로 인코딩하여 에디터에 삽입
      },
      //theme: theme,
      cleanHTML: {
        fillEmptyParagraph: false,
      },
    }),
    []
  );

  // 퍄트너스 게시글/공지사항 생성
  const { mutate: createPartnersBoardMutate } = useMutation({
    mutationFn: createPartnersBoard,
    mutationKey: ["createPartnersBoard"],
    onSuccess: (data) => {
      if (data.done) {
        finish(modify);
      }
    },
    onError: (error) => {
      if (error.response.data.code !== 401) {
        alert(error.response.data.message);
      }
      throw error;
    },
  });
  // 퍄트너스 게시글/공지사항 수정
  const { mutate: updatePartnersBoardMutate } = useMutation({
    mutationFn: updatePartnersBoard,
    mutationKey: ["updatePartnersBoard"],
    onSuccess: (data) => {
      if (data.done) {
        finish(modify);
      }
    },
    onError: (error) => {
      if (error.response.data.code !== 401) {
        alert(error.response.data.message);
      }
      throw error;
    },
  });

  useEffect(() => {
    if (modify === false) return;
    setValue("subject", dashBordData.subject);
    setEditor(dashBordData?.content);
    setDataFix(dashBordData?.board_type === "N" ? true : false);
  }, []);
  // 최종 src url 저장할곳

  const onSubmit = async (e) => {
    if (htmlToText(editor) === "") {
      alert("내용을 입력해주세요");
      return;
    }

    const items = {
      subject: e.subject,
      content: editor,
      attachment1: files[0]?.photo || null,
      attachment2: files[1]?.photo || null,
      attachment3: files[2]?.photo || null,
      attachment4: files[3]?.photo || null,
      attachment5: files[4]?.photo || null,
      boardType: dataFix ? "N" : "F",
    };

    if (modify) {
      // 수정
      items.board_seq = dashBordData.board_seq;
      updatePartnersBoardMutate(items);
    } else {
      // 생성
      createPartnersBoardMutate(items);
    }
  };

  const onError = (errors) => {
    const errorKeys = Object.keys(errors);
    if (errorKeys.length > 0) {
      const firstError = errorKeys[0];
      setFocus(firstError);
      if (errors[firstError].type === "noSpaces") {
        setValue(firstError, "");
      }
      alert(errors[firstError]?.message || "Form validation error");
    }
  };

  useEffect(() => {
    if (!modify) return;

    const files = [
      dashBordData?.attachment_01 &&
        dashBordData.attachment_01 !== "" && {
          name: handleFilesName(dashBordData?.attachment_01),
          size: null,
          photo: dashBordData?.attachment_01,
        },
      dashBordData?.attachment_02 &&
        dashBordData.attachment_02 !== "" && {
          name: handleFilesName(dashBordData?.attachment_02),
          size: null,
          photo: dashBordData?.attachment_02,
        },
      dashBordData?.attachment_03 &&
        dashBordData.attachment_03 !== "" && {
          name: handleFilesName(dashBordData?.attachment_03),
          size: null,
          photo: dashBordData?.attachment_03,
        },
      dashBordData?.attachment_04 &&
        dashBordData.attachment_04 !== "" && {
          name: handleFilesName(dashBordData?.attachment_04),
          size: null,
          photo: dashBordData?.attachment_04,
        },
      dashBordData?.attachment_05 &&
        dashBordData.attachment_05 !== "" && {
          name: handleFilesName(dashBordData?.attachment_05),
          size: null,
          photo: dashBordData?.attachment_05,
        },
    ].filter(Boolean);
    setFiles(files);
  }, []);
  return (
    <>
      <FieldGroup
        className="dashboard-form"
        ref={ref}
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        <InputBox
          fulled
          style={{
            flexDirection: "row",
            alignItems: "center",
            columnGap: "5px",
          }}
        >
          <h6>모든 페이지에 고정</h6>
          <CustomChecked
            onChange={(checked) => {
              setDataFix(checked);
            }}
            data={{
              text: "",
              isCheck: dataFix,
            }}
          />
        </InputBox>

        <InputBox fulled>
          <h6>
            제목 <span>*</span>
          </h6>
          <input
            name="subject"
            type="text"
            {...register("subject", {
              validate: {
                register: (value) => value !== "" || "제목을 입력해주세요",
                noSpaces: (value) =>
                  value?.trim() !== "" || "제목을 입력해주세요",
              },
            })}
          />
        </InputBox>
        <InputBox fulled>
          <h6>
            내용 <span>*</span>
          </h6>
          <div className="editor-wrap">
            <JoditEditor
              ref={editorRef}
              value={editor}
              config={config}
              tabIndex={1} // tabIndex of textarea
              onChange={(newContent) => {
                setEditor(newContent);
              }}
            />
          </div>
        </InputBox>
        <InputBox fulled>
          <h6>첨부파일</h6>
          <FileUpload
            files={files}
            setFiles={setFiles}
            data={modify ? dashBordData : null}
          />
        </InputBox>
      </FieldGroup>
    </>
  );
});

const FieldGroup = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 10px;

  > div {
    row-gap: 5px;
    display: flex;
    flex-direction: column;
  }

  textarea {
    width: 100%;
    min-height: 80px;
    border-radius: 6px;
    border: 1px solid #cbd5e0;
    box-sizing: border-box;
    // margin-top: 6px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    resize: none;
    padding: 8px 12px;
    color: #171923;
    leading-trim: both;
    text-edge: cap;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  input {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }

  label {
    margin-left: 0;

    > span,
    input {
      padding: 0;
    }

    .MuiFormControlLabel-label {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .editor-wrap {
    // min-height: 488px;

    .jodit .jodit-workplace .jodit-wysiwyg {
      min-height: 400px !important;
    }

    .jodit-container.jodit.jodit_theme_default.jodit-wysiwyg_mode {
      background-color: #fff !important;
    }

    p {
      margin: 0 !important;
    }

    .jodit-add-new-line,
    .jodit-status-bar {
      display: none;
    }
  }
`;
export default DashBoardPartnersForm;
