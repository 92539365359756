import React, { useCallback, useEffect, useRef, useState } from "react";
import AxiosNavigate from "../../../library/axios-navigate";
import { FormProvider, useForm } from "react-hook-form";
import { Header } from "../../../components/Header";
import { PagingFooter } from "../../../components/PagingFooter";
import Pagination from "react-js-pagination";
import PagesList from "../../../components/PagesList";
import Modal from "../../../components/Modal";
import styled from "@emotion/styled";
import DashBoardForm from "./dashboard-form";
import ButtonComponent from "../../../components/Button";
import SideModal from "../../../components/SideModal";
import { Content } from "../../../library/styled-components";
import { ModalButton } from "../../../components/ModalButton";
import { useMutation } from "@tanstack/react-query";
import {
  deleteFAQ,
  getFAQDetail,
  getFAQList,
} from "../../../library/admin-react-query";
import moment from "moment";
import SearchBox from "../../../components/Search";
import EmptyContainer from "../../../components/EmptyContainer";
import { TableStyle } from "../../../library/styled-components";
import { BlockScrolling } from "../../../bin/common.js";
export const handleFilesName = (url) => {
  // URL에서 쿼리 문자열 제거

  if (url === "") return "";

  const cleanedUrl = url?.split("?")[0];
  const type = cleanedUrl?.split(".")[cleanedUrl?.split(".").length - 1];

  // 경로에서 파일 이름만 추출
  let fileName = cleanedUrl?.substring(cleanedUrl?.lastIndexOf("/") + 1);
  // 확장자 앞에 있는 _문자열_ 패턴을 제거
  const regex = new RegExp(`_[^_]+_\\.${type}$`);

  fileName = fileName?.replace(regex, `.${type}`);
  return fileName;
};
const DashBoardAdmin = () => {
  const methods = useForm();
  const closeModal = useRef(false);
  const formRef = useRef();

  const [openedNewDashBoard, setOpenedNewDashBoard] = useState(false);
  const [openedDetailDashBoard, setOpenedDetailDashBoard] = useState(false);
  const [selectedDashBoard, setSelectedDashBoard] = useState({});

  const [searchTxt, setSearchTxt] = useState(""); // 엔터
  const [searchValue, setSearchValue] = useState(""); // 키보드
  const [totalCount, setTotalCount] = useState(0);
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);

  BlockScrolling([openedDetailDashBoard, openedNewDashBoard]);
  //FAQ 목록 API
  const { mutate: getFAQListMutate, data: getFAQListData } = useMutation({
    mutationKey: ["getFAQList"],
    mutationFn: getFAQList,
    onSuccess: (data) => {
      setTotalCount(data.totalCount);
      if (openedDetailDashBoard) {
        loadDashBoardDetail(selectedDashBoard?.faq_seq);
      }
    },
    onError: (error) => {
      throw error;
    },
  });

  // FAQ 글 내용
  const { mutate: getFAQDetailMutate, data: getFAQDetailData } = useMutation({
    mutationKey: ["getFAQDetail"],
    mutationFn: getFAQDetail,
    onSuccess: (data) => {
      setSelectedDashBoard(data);
    },
    onError: (error) => {
      throw error;
    },
  });

  // 게시판 삭제 API
  const { mutate: deleteFAQMutate, data: deleteFAQData } = useMutation({
    mutationKey: ["deleteFAQ"],
    mutationFn: deleteFAQ,
    onSuccess: (data) => {
      handleFinishAction();
      //   setTotalCount(data.totalCount);
    },
    onError: (error) => {
      throw error;
    },
  });

  // 게시판 삭제
  const handleSelectedDelete = (faq_seq) => {
    const result = window.confirm("정말로 삭제하겠습니까?");
    if (result) {
      deleteFAQMutate(faq_seq);
    }
  };

  // 게시판 수정
  const handleSelectedModify = () => {
    setOpenedNewDashBoard(true);
  };

  useEffect(() => {
    loadDashBoards({ search_txt: searchTxt, page: page, limit: limit });
  }, [limit, page, searchTxt]);

  const loadDashBoards = useCallback((data) => {
    getFAQListMutate(data);
  }, []);

  const loadDashBoardDetail = useCallback((data) => {
    const payload = { faq_seq: data };
    getFAQDetailMutate(payload);
  }, []);

  // 게시판 수정
  const handleFinishAction = (modify) => {
    if (!modify) {
      // 생성시 끝나고 작업
      setPage(1);
      setLimit(20);
      setSearchTxt("");
      setOpenedDetailDashBoard(false);
      loadDashBoards({ search_txt: "", page: 1, limit: 20 });
    } else {
      // 수정시 끝나고 작업
      //  setSelectedDashBoard(data);
      loadDashBoardDetail(selectedDashBoard?.faq_seq);
      // loadDashBoards({search_txt: searchTxt, page: page, limit: limit});
    }
    setOpenedNewDashBoard(false);
  };

  const handleDownloadFiles = async (fileUrl, fileName) => {
    try {
      // `https://cors-anywhere.herokuapp.com/${fileUrl}`
      const parseUrl = fileUrl.replace(
        "https://bucket-9uc28v.s3.ap-northeast-2.amazonaws.com/",
        ""
      );

      //https://cors-anywhere.herokuapp.com/
      // console.log(`/down-api${parseUrl}`)
      // console.log(`/down-api/${fileUrl}`);
      // const response = await fetch(`/down-api/${fileUrl}`, {
      //     method: 'GET',
      //     headers: {
      //         // 'Access-Control-Allow-Origin': '*',
      //         // 'Access-Control-Allow-Methods': 'GET',
      //         // 'Access-Control-Allow-Headers': 'Content-Type, Authorization',
      //         // 'Content-Type': 'application/octet-stream',  // 또는 해당 MIME 타입 설정
      //         // 'Content-Type': 'application/vnd.ms-excel'
      //     }
      // });
      // console.log(response);
      const proxyUrl = "https://variable-peafowl-ilsaram-0ee58bff.koyeb.app";
      const response = await fetch(`${proxyUrl}/${fileUrl}`, {
        method: "GET",
        headers: {
          // 'Content-Type': 'application/octet-stream',  // 또는 해당 MIME 타입 설정
          // 'Content-Type': 'application/vnd.ms-excel'
        },
      });

      if (!response.ok) {
        throw new Error("파일 다운로드 실패");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url); // 메모리 해제
    } catch (error) {
      console.error("다운로드 중 오류 발생:", error);
    }
    // downloadFile(url, handleFilesName(url)); // 원하는
  };

  const renderTextWithLinks = (text) => {
    if (!text) return;
    return text.replaceAll("<a href", "<a target={'_blank'} href");
  };
  return (
    <AxiosNavigate>
      <div style={{ paddingBottom: "30px" }}>
        <FormProvider {...methods}>
          <Header>
            <div>도움말 관리</div>
            <div style={{ display: "flex", columnGap: "16px" }}>
              <SearchBox
                placeholder={"제목 검색"}
                onKeyDown={(e) => {
                  if (
                    e.key === "Enter" &&
                    e.nativeEvent.isComposing === false
                  ) {
                    if (
                      e.target.value.length > 0 &&
                      e.target.value.trim().length === 0
                    ) {
                      alert("제목을 입력해 주세요");
                      setSearchValue(e.target.value.trim());
                      return;
                    }
                    setSearchTxt(e.target.value);
                    setSearchValue(e.target.value.trim());
                    setPage(1);
                  }
                }}
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
              ></SearchBox>

              <Button
                style={{ backgroundColor: "#3279F5", color: "#fff" }}
                onClick={() => setOpenedNewDashBoard(true)}
              >
                작성하기
              </Button>
            </div>
          </Header>
          <TableStyle>
            <TableWrapper>
              <table>
                <thead>
                  <tr>
                    <th width={"70px"}>구분</th>
                    <th width={"80%"}>제목</th>
                    <th>등록일</th>
                  </tr>
                </thead>
                <tbody>
                  {getFAQListData && getFAQListData.totalCount === 0 ? (
                    <tr style={{ height: "500px" }}>
                      <td colSpan={3}>
                        <EmptyContainer
                          falseText={"등록된 도움말이"}
                          style={{ background: "#fff" }}
                        ></EmptyContainer>
                      </td>
                    </tr>
                  ) : (
                    getFAQListData?.list?.map((e, i) => (
                      <tr key={i}>
                        <td>{i + limit * (page - 1) + 1}</td>
                        <td
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          <span
                            style={{
                              whiteSpace: "break-spaces",
                              textAlign: "left",
                            }}
                          >
                            {e.subject}
                          </span>
                          <ButtonComponent
                            style={{
                              backgroundColor: "#fff",
                              color: "#4A5568",
                              border: "1.125px solid #E2E8F0",
                              height: "32px",
                              width: "68px",
                              marginLeft: "8px",
                            }}
                            onClick={() => {
                              setOpenedDetailDashBoard(true);
                              loadDashBoardDetail(e.faq_seq);
                              // setSelectedDashBoard(e);
                            }}
                          >
                            {" "}
                            {"상세보기"}
                          </ButtonComponent>
                        </td>
                        <td>{moment.utc(e.crt_dt).format("YYYY년 M월 D일")}</td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </TableWrapper>
          </TableStyle>
          <PagingFooter>
            <Pagination
              prevPageText={<Icon src="/assets/icons/chevron-left.svg" />}
              nextPageText={<Icon src="/assets/icons/chevron-right.svg" />}
              activePage={page}
              itemsCountPerPage={limit}
              totalItemsCount={totalCount === 0 ? 1 : totalCount}
              pageRangeDisplayed={5}
              hideFirstLastPages={true}
              onChange={(e) => setPage(e)}
            />
            <PagesList
              onChange={(e) => {
                setLimit(e.value);
                setPage(1);
              }}
            ></PagesList>
          </PagingFooter>

          <Modal
            opend={openedNewDashBoard}
            closeModal={() => {
              setOpenedNewDashBoard(closeModal.current);
              closeModal.current = false;
            }}
            header={"도움말 작성"}
            formRef={formRef}
            cancelText={""}
            okText={"확인"}
            widthCheck={"950px"}
            //heightCheck={'950px'}
            // style={{minHeight: '950px', maxHeight: 'auto'}}
          >
            <DashBoardForm
              ref={formRef}
              data={selectedDashBoard || ""}
              modify={openedDetailDashBoard}
              finish={handleFinishAction}
            ></DashBoardForm>
          </Modal>
          <SideModal
            opend={openedDetailDashBoard}
            closeModal={() => setOpenedDetailDashBoard(false)}
            header={"도움말 상세"}
            style={{ maxWidth: "800px" }}
          >
            <Side>
              <Content>
                <figure>
                  <h6>등록일</h6>
                  <h5>
                    {moment
                      .utc(selectedDashBoard.crt_dt)
                      .format("YYYY년 M월 D일")}
                  </h5>
                </figure>
                <figure>
                  <h6>제목</h6>
                  <h5>{selectedDashBoard?.subject}</h5>
                </figure>
                <figure style={{ alignItems: "stretch" }}>
                  <h6>내용</h6>
                  <h5
                    className="jodit-wysiwyg"
                    dangerouslySetInnerHTML={{
                      __html: renderTextWithLinks(selectedDashBoard?.content),
                    }}
                  ></h5>
                </figure>
                <figure style={{ alignItems: "stretch" }}>
                  <h6>첨부 파일</h6>
                  <div>
                    <h5
                      onClick={async (event) => {
                        event.preventDefault();
                        await handleDownloadFiles(
                          selectedDashBoard?.attachment_01,
                          handleFilesName(selectedDashBoard?.attachment_01)
                        );
                      }}
                    >
                      {handleFilesName(selectedDashBoard?.attachment_01)}
                    </h5>
                    <h5
                      onClick={(event) => {
                        event.preventDefault();
                        handleDownloadFiles(
                          selectedDashBoard?.attachment_02,
                          handleFilesName(selectedDashBoard?.attachment_02)
                        );
                      }}
                    >
                      {handleFilesName(selectedDashBoard?.attachment_02)}
                    </h5>
                    <h5
                      onClick={(event) => {
                        event.preventDefault();
                        handleDownloadFiles(
                          selectedDashBoard?.attachment_03,
                          handleFilesName(selectedDashBoard?.attachment_03)
                        );
                      }}
                    >
                      {handleFilesName(selectedDashBoard?.attachment_03)}
                    </h5>
                    <h5
                      onClick={(event) => {
                        event.preventDefault();
                        handleDownloadFiles(
                          selectedDashBoard?.attachment_04,
                          handleFilesName(selectedDashBoard?.attachment_04)
                        );
                      }}
                    >
                      {handleFilesName(selectedDashBoard?.attachment_04)}
                    </h5>
                    <h5
                      onClick={(event) => {
                        event.preventDefault();
                        handleDownloadFiles(
                          selectedDashBoard?.attachment_05,
                          handleFilesName(selectedDashBoard?.attachment_05)
                        );
                      }}
                    >
                      {handleFilesName(selectedDashBoard?.attachment_05)}
                    </h5>
                  </div>
                </figure>
              </Content>

              <div style={{ display: "grid" }} className={"grid"}>
                <ModalButton
                  onClick={() =>
                    handleSelectedDelete(selectedDashBoard?.faq_seq)
                  }
                >
                  삭제
                </ModalButton>
                <ModalButton onClick={() => handleSelectedModify()}>
                  수정
                </ModalButton>
              </div>
            </Side>
          </SideModal>
        </FormProvider>
      </div>
    </AxiosNavigate>
  );
};

const Side = styled.div`
  //height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* padding-bottom: 20px; */
  box-sizing: border-box;

  .grid {
    grid-template-columns: repeat(2, 100px);
    margin-left: auto;
    padding-bottom: 20px;
  }

  > div > figure {
    h6 {
      min-width: 80px;
    }

    a {
      text-decoration: none;
      color: #3279f5;
    }

    h5 p {
      margin: 0;
    }
  }

  > div > figure:last-child {
    div {
      display: grid;
      gap: 5px;
      grid-template-columns: repeat(1, 1fr);
    }

    h5 {
      cursor: pointer;
    }
  }

  > div > figure:nth-of-type(3) {
    h5 {
      width: calc(100% - 80px);

      img {
        width: auto;
        max-width: 100%;
      }
    }
  }

  figure:nth-of-type(3) {
    * {
      width: auto !important;
      max-width: 100% !important;
    }

    table {
      width: 100% !important;
    }
  }
`;

const TableContainer = styled.div`
  padding: 0px 32px;
  margin-top: 28px;
`;

const TableWrapper = styled.div`
  /* border: 1px solid #CBD5E0;
    border-radius: 8px;
    overflow: hidden;
    overflow-x: auto; */
  th,
  td {
    text-align: center;
  }
`;

const Icon = styled.img`
  width: 16px;
`;
const Table = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;

  th,
  td {
    text-align: center;
    padding: 8px;
    font-size: 13px;
    font-weight: 300;
    border-right: 1px solid #cbd5e0;
    border-bottom: 1px solid #cbd5e0;
    white-space: nowrap;
  }

  th {
    background-color: #f7fafc;
  }

  tr:last-child td {
    border-bottom: none;
  }

  td:first-of-type {
    text-align: center;
  }

  tr {
    height: 49px;
  }
`;

const Button = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
  min-height: 32px;
  padding: 0 9px;
  justify-content: center;
  align-items: center;
  gap: 9px;
  border-radius: 6px;
  border: 1.125px solid #e2e8f0;
  background: #fff;
  box-sizing: border-box;
  color: #4a5568;
  leading-trim: both;
  text-edge: cap;
  font-size: 14px;
  cursor: pointer;

  //    margin-top: 5px;
`;
export default DashBoardAdmin;
