import { useEffect, useState, useCallback } from "react";
import {
  Link as DomLink,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import {
  Menu,
  MenuItem as ReactMenuItem,
  Sidebar as ReactSidebar,
} from "react-pro-sidebar";

import styled from "@emotion/styled";

import AxiosNavigate from "./library/axios-navigate";

function AppLayout() {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <AxiosNavigate container={false}>
      <div>
        <Outlet />
      </div>
    </AxiosNavigate>
  );
}

export default AppLayout;
