import React, { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { dataFilter } from "../../../bin/common";
import { InputBox } from "../../../library/styled-components";
import styled from "@emotion/styled";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import JoditEditor from "jodit-react";
import SelectedFilterBox from "../../../components/SelectedFilterBox";
import DayCalendarPicker from "../../../components/DayCalendarPicker.js";

const fieldsOrder = [
  "start_dt",
  "end_dt",
  "pop_type",
  "subject",
  "content",
  "use_yn",
];

const ModifyNoticeForm = forwardRef((props, ref) => {
  const { typeNames, planDataList, updatePopupAdminItem, selectedNotice } =
    props;
  const editorRef = useRef(null);

  const [editor, setEditor] = useState("");
  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    setFocus,
    formState: { errors },
  } = useForm({
    defaultValues: {
      content: selectedNotice?.content,
      start_dt: selectedNotice?.start_dt,
      end_dt: selectedNotice?.end_dt,
      pop_type: {
        value: dataFilter(selectedNotice?.pop_type, planDataList),
        label: dataFilter(selectedNotice?.pop_type, planDataList),
      },
      subject: selectedNotice?.subject,
      use_yn: selectedNotice?.use_yn,
    },
  });

  const config = useMemo(
    () => ({
      readonly: false, // all options from https://xdsoft.net/jodit/docs/,
      placeholder: "",
      buttons: [
        "fontsize",
        "bold",
        "italic",
        "underline",
        "strikethrough",
        "eraser",
        "superscript",
        "subscript",
        "ul",
        "ol",
        "outdent",
        "indent",
        "brush",
        "paragraph",
        "image",
        //'table',
        "align",
        "undo",
        "redo",
        // 동영상 관련 버튼 "video"는 제외
      ],
      //theme: theme,
      uploader: {
        insertImageAsBase64URI: true, // 이미지 업로드 시 Base64로 인코딩하여 에디터에 삽입
      },
      cleanHTML: {
        fillEmptyParagraph: false,
      },
    }),
    []
  );
  useEffect(() => {
    setEditor(selectedNotice?.content?.replace(/\n/g, "<br />"));
  }, [selectedNotice]);

  const onSubmit = (e) => {
    if (e.start_dt > e.end_dt || e.end_dt === e.start_dt) {
      alert("날짜를 확인해주세요");
      return;
    }

    e.pop_type = dataFilter(
      e.pop_type.value,
      planDataList,
      "dtl_cd_nm",
      "dtl_cd_id"
    );
    e.start_dt = new Date(e.start_dt).toISOString();
    e.end_dt = new Date(e.end_dt).toISOString();
    e.pop_seq = selectedNotice?.pop_seq;
    e.content = editor;
    updatePopupAdminItem(e);
  };

  const onError = (errors) => {
    const errorKeys = Object.keys(errors);
    if (errorKeys.length > 0) {
      const firstError = fieldsOrder.find((key) => errorKeys.includes(key));
      setFocus(firstError);

      if (errors[firstError].type === "noSpaces") {
        setValue(firstError, "");
      }
      alert(errors[firstError]?.message || "Form validation error");
    }
  };
  return (
    <FieldGroup
      className="new-notice-form"
      ref={ref}
      onSubmit={handleSubmit(onSubmit, onError)}
    >
      <InputBox fulled>
        <h6>기간</h6>
        <div
          style={{
            display: "flex",
            justifyItems: "center",
            alignItems: "center",
            columnGap: "15px",
          }}
        >
          <DayCalendarPicker
            style={{ width: "430px", fontSize: "16px" }}
            {...register("start_dt", {
              required: "시작 날짜를 입력해주세요",
            })}
            change={(newValue) => {
              setValue("start_dt", newValue);
            }}
            isValue={getValues("start_dt") || ""}
          />
          <span>~</span>
          <DayCalendarPicker
            style={{ width: "430px", fontSize: "16px" }}
            {...register("end_dt", {
              required: "종료 날짜를 입력해주세요",
            })}
            change={(newValue) => {
              setValue("end_dt", newValue);
            }}
            isValue={getValues("end_dt") || ""}
          />
        </div>
      </InputBox>

      <InputBox fulled>
        <h6>종류</h6>
        <Controller
          name="pop_type"
          control={control}
          rules={{ required: "공지 종류를 선택해주세요" }}
          render={({ field }) => (
            <SelectedFilterBox
              {...field}
              placeholder={"공지 종류"}
              style={{ width: "100%", height: "39px" }}
              value={getValues("pop_type")}
              onChange={(e) =>
                setValue("pop_type", { value: e.value, label: e.value })
              }
              options={typeNames}
            ></SelectedFilterBox>
          )}
        />
      </InputBox>

      <InputBox fulled style={{ marginTop: "8px" }}>
        <h6>제목</h6>
        <input
          name="subject"
          type="text"
          {...register("subject", {
            validate: {
              register: (value) => value !== "" || "제목을 입력해주세요",
              noSpaces: (value) =>
                value?.trim() !== "" || "제목을 입력해주세요",
            },
          })}
        />
      </InputBox>

      <InputBox fulled>
        <h6>내용</h6>
        <div className="editor-wrap">
          <JoditEditor
            ref={editorRef}
            value={editor}
            config={config}
            tabIndex={1} // tabIndex of textarea
            onChange={(newContent) => {
              setEditor(newContent);
            }}
          />
        </div>
      </InputBox>

      <InputBox
        style={{
          display: "flex",
          alignItems: "center",
          columnGap: "15px",
          flexDirection: "row",
        }}
      >
        <Controller
          name="use_yn"
          control={control}
          rules={{ required: "사용 여부를 선택해주새요" }}
          render={({ field }) => (
            <>
              <FormControl
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  columnGap: "10px",
                }}
                {...field}
              >
                <h6>사용 여부</h6>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={getValues("use_yn")}
                >
                  <FormControlLabel
                    value="Y"
                    control={<Radio size="small" />}
                    label="사용"
                    sx={{
                      fontSize: "14px",
                    }}
                  />
                  <FormControlLabel
                    value="N"
                    control={<Radio size="small" />}
                    label="중지"
                    sx={{
                      fontSize: "14px",
                    }}
                  />
                </RadioGroup>
              </FormControl>
            </>
          )}
        />
      </InputBox>
    </FieldGroup>
  );
});

const FieldGroup = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 10px;

  > div {
    row-gap: 8px;
    display: flex;
    flex-direction: column;
  }

  textarea {
    width: 100%;
    min-height: 80px;
    border-radius: 6px;
    border: 1px solid #cbd5e0;
    box-sizing: border-box;
    // margin-top: 6px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    resize: none;
    padding: 8px 12px;
    color: #171923;
    leading-trim: both;
    text-edge: cap;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  input {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }

  label {
    margin-left: 0;

    > span,
    input {
      padding: 0;
    }

    .MuiFormControlLabel-label {
      font-size: 15px;
    }
  }

  .editor-wrap {
    //height: 500px;

    .jodit .jodit-workplace .jodit-wysiwyg {
      min-height: 500px !important;
    }

    .jodit-container.jodit.jodit_theme_default.jodit-wysiwyg_mode {
      background-color: #fff !important;
    }

    .jodit-react-container {
      //height: 500px;
    }

    p {
      margin: 0 !important;
    }

    .jodit-container:not(.jodit_inline) .jodit-workplace {
      min-height: unset !important;
      height: calc(500px - 39px) !important;
    }

    .jodit-add-new-line,
    .jodit-status-bar {
      display: none;
    }
  }
`;
export default ModifyNoticeForm;
