import React, {
  forwardRef,
  useState,
  useCallback,
  useEffect,
  useRef,
} from "react";
import styled from "@emotion/styled";
import { InputBox, TableStyle } from "../../../library/styled-components";
import moment from "moment";
import { useMutation } from "@tanstack/react-query";
import { getLaborPayment } from "../../../library/axios-query";
import EmptyContainer from "../../../components/EmptyContainer";
import Modal from "../../../components/Modal";
import UserPaymentStatementPrint from "../../construction/print/UserPaymentStatementPrint";
import CustomChecked from "../../../components/CustomChecked.js";
import { idNumReveal } from "../../../bin/common.js";
import MonthCalendarPicker from "../../../components/MonthCalendarPicker.js";
const UserPaymentStatement = forwardRef((props, ref) => {
  const { data } = props;
  const componentRef = useRef();
  const nowDate = moment(new Date());
  const [startDay, setStartDay] = useState(nowDate.format("YYYY-01"));
  const [endDay, setEndDay] = useState(nowDate.format("YYYY-MM"));
  const [print, setPrint] = useState(false);
  const [check, setCheck] = useState();

  const { mutate: getLaborPaymentMutate, data: getLaborPaymentData } =
    useMutation({
      mutationFn: getLaborPayment,
      mutationKey: "getLaborPayment",
      onSuccess: (data) => {},
      onError: (error) => {
        console.log(error);
      },
    });
  useEffect(() => {
    const items = { startDt: startDay, endDt: endDay, userSeq: data.user_seq };
    getLaborPaymentMutate(items);
  }, [startDay, endDay]);

  // 프린트
  const handleOpenPrint = useCallback(() => {
    if (getLaborPaymentData?.list?.length === 0) {
      alert("출력 가능한 내역이 없습니다");
      return;
    }
    setPrint(true);
  }, [getLaborPaymentData]);

  return (
    <div ref={ref}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            columnGap: "7px",
          }}
        >
          <MonthCalendarPicker
            change={(newValue) => {
              setStartDay(newValue);
            }}
            isValue={startDay}
          ></MonthCalendarPicker>
          ~
          <MonthCalendarPicker
            change={(newValue) => {
              setEndDay(newValue);
            }}
            isValue={endDay}
          ></MonthCalendarPicker>
        </div>

        <Checked className="checked">
          <CustomChecked
            style={{ paddingLeft: "0px" }}
            onChange={(checked) => {
              setCheck(checked);
            }}
            data={{
              text: "주민등록번호(뒷부분 6자리) 공개",
              isCheck: check,
            }}
          ></CustomChecked>
          <Button onClick={handleOpenPrint}>
            <Icon src="/assets/icons/printer.svg" /> 출력하기
          </Button>
        </Checked>
      </div>

      <Header>
        <Title>노무비 지급명세서</Title>
        <span>
          {`조회기간 : ${moment(startDay).format("YYYY년 M월")} ~ ${moment(
            endDay
          ).format("YYYY년 M월")}`}
        </span>
      </Header>
      <TableStyle style={{ padding: "0px", marginTop: "15px" }}>
        <TableWrapper className="first">
          <table>
            <thead>
              <tr>
                <th className="none-border" width="150px">
                  이름
                </th>
                <td style={{ width: "300px", minWidth: "300px" }}>
                  {data?.user_nm}
                </td>
                <th className="none-border" width="150px">
                  주민등록번호
                </th>
                <td style={{ width: "200px", minWidth: "200px" }}>
                  {idNumReveal(data?.id_num, check)}
                </td>
                <th className="none-border border" width="150px">
                  주소
                </th>
                <td className="table-left">
                  {data.addr1} {data.addr2}
                </td>
              </tr>
            </thead>
          </table>
        </TableWrapper>
      </TableStyle>

      <TableStyle style={{ padding: "0px", marginTop: "10px" }}>
        <TableWrapper>
          <table>
            <thead>
              <tr>
                <th style={{ width: "120px" }} rowSpan={3}>
                  년월
                </th>
                <th rowSpan={3} className="spaces" style={{ width: "556px" }}>
                  현장명
                </th>
                <th style={{ width: "80px" }} rowSpan={3}>
                  직종
                </th>
                <th colSpan={16}>출 역 사 항</th>
                <th
                  colSpan={3}
                  rowSpan={2}
                  style={{ width: "84px", minWidth: "84px" }}
                >
                  주차/년월차
                  <br />
                  /추가
                </th>
                <th className="th_width" style={{ width: "60px" }} rowSpan={2}>
                  단가
                </th>
                <th className="th_width" rowSpan={2}>
                  소득세
                </th>
                <th className="th_width" rowSpan={2}>
                  고용보험
                </th>
                <th className="th_width" rowSpan={2}>
                  국민연금
                </th>
                <th className="th_width" rowSpan={2}>
                  기타/노조
                </th>
                <th className="th_width" rowSpan={2}>
                  식대
                </th>
              </tr>
              <tr>
                <th className="tb_width">1</th>
                <th className="tb_width">2</th>
                <th className="tb_width">3</th>
                <th className="tb_width">4</th>
                <th className="tb_width">5</th>
                <th className="tb_width">6</th>
                <th className="tb_width">7</th>
                <th className="tb_width">8</th>
                <th className="tb_width">9</th>
                <th className="tb_width">10</th>
                <th className="tb_width">11</th>
                <th className="tb_width">12</th>
                <th className="tb_width">13</th>
                <th className="tb_width">14</th>
                <th className="tb_width">15</th>
                <th className="border tb_width">16</th>
              </tr>
              <tr>
                <th className="tb_width">17</th>
                <th className="tb_width">18</th>
                <th className="tb_width">19</th>
                <th className="tb_width">20</th>
                <th className="tb_width">21</th>
                <th className="tb_width">22</th>
                <th className="tb_width">23</th>
                <th className="tb_width">24</th>
                <th className="tb_width">25</th>
                <th className="tb_width">26</th>
                <th className="tb_width">27</th>
                <th className="tb_width">28</th>
                <th className="tb_width">29</th>
                <th className="tb_width">30</th>
                <th className="tb_width">31</th>
                <th className="tb_width"></th>
                <th colSpan={3} style={{ width: "84px", minWidth: "84px" }}>
                  총일수/공수
                </th>
                <th className="th_width">노무비총액</th>
                <th className="th_width">지방소득세</th>
                <th className="th_width">건강보험</th>
                <th className="th_width">요양보험</th>
                <th className="th_width">공제계</th>
                <th className="th_width">실지급액</th>
              </tr>
            </thead>
            {getLaborPaymentData && getLaborPaymentData?.list?.length > 0 ? (
              <tbody>
                {getLaborPaymentData?.list?.map((e, i) => (
                  <React.Fragment key={i}>
                    <tr>
                      <td rowSpan={2} style={{ minWidth: "120px" }}>
                        {e.yyyymm}
                      </td>
                      <td
                        rowSpan={2}
                        style={{
                          cursor: "pointer",
                          textAlign: "left",
                          whiteSpace: "normal",
                        }}
                      >
                        {e.siteNm}
                      </td>
                      <td
                        rowSpan={2}
                        style={{
                          maxWidth: "80px",
                          minWidth: "80px",
                        }}
                      >
                        {e.jobPosition}
                      </td>
                      <td className="tb_width">
                        {e.dayAttendList[0] === 10 ? "" : e.dayAttendList[0]}
                      </td>
                      <td className="tb_width">
                        {e.dayAttendList[1] === 10 ? "" : e.dayAttendList[1]}
                      </td>
                      <td className="tb_width">
                        {e.dayAttendList[2] === 10 ? "" : e.dayAttendList[2]}
                      </td>
                      <td className="tb_width">
                        {e.dayAttendList[3] === 10 ? "" : e.dayAttendList[3]}
                      </td>
                      <td className="tb_width">
                        {e.dayAttendList[4] === 10 ? "" : e.dayAttendList[4]}
                      </td>
                      <td className="tb_width">
                        {e.dayAttendList[5] === 10 ? "" : e.dayAttendList[5]}
                      </td>
                      <td className="tb_width">
                        {e.dayAttendList[6] === 10 ? "" : e.dayAttendList[6]}
                      </td>
                      <td className="tb_width">
                        {e.dayAttendList[7] === 10 ? "" : e.dayAttendList[7]}
                      </td>
                      <td className="tb_width">
                        {e.dayAttendList[8] === 10 ? "" : e.dayAttendList[8]}
                      </td>
                      <td className="tb_width">
                        {e.dayAttendList[9] === 10 ? "" : e.dayAttendList[9]}
                      </td>
                      <td className="tb_width">
                        {e.dayAttendList[10] === 10 ? "" : e.dayAttendList[10]}
                      </td>
                      <td className="tb_width">
                        {e.dayAttendList[11] === 10 ? "" : e.dayAttendList[11]}
                      </td>
                      <td className="tb_width">
                        {e.dayAttendList[12] === 10 ? "" : e.dayAttendList[12]}
                      </td>
                      <td className="tb_width">
                        {e.dayAttendList[13] === 10 ? "" : e.dayAttendList[13]}
                      </td>
                      <td className="tb_width">
                        {e.dayAttendList[14] === 10 ? "" : e.dayAttendList[14]}
                      </td>
                      <td className="tb_width">
                        {e.dayAttendList[15] === 10 ? "" : e.dayAttendList[15]}
                      </td>
                      {/* 주차/년월차/추가 */}
                      <td></td>
                      <td className="tb_width"></td>
                      <td className="tb_width"></td>

                      <td className={"table-right"}>
                        {e.dayAmt.toLocaleString()}
                      </td>
                      <td className={"table-right"}>
                        {e.taxIncome.toLocaleString()}
                      </td>
                      <td className={"table-right"}>
                        {e.taxEmployInsu.toLocaleString()}
                      </td>
                      <td className={"table-right"}>
                        {e.taxNationPension.toLocaleString()}
                      </td>
                      <td className={"table-right"}>
                        {e.taxEtcAndUnion.toLocaleString()}
                      </td>
                      <td className={"table-right"}>
                        {e.taxMeal.toLocaleString()}
                      </td>
                    </tr>
                    <tr style={{ position: "relative" }}>
                      <td className="tb_width">
                        {e.dayAttendList[16] === 10 ? "" : e.dayAttendList[16]}
                      </td>
                      <td className="tb_width">
                        {e.dayAttendList[17] === 10 ? "" : e.dayAttendList[17]}
                      </td>
                      <td className="tb_width">
                        {e.dayAttendList[18] === 10 ? "" : e.dayAttendList[18]}
                      </td>
                      <td className="tb_width">
                        {e.dayAttendList[19] === 10 ? "" : e.dayAttendList[19]}
                      </td>
                      <td className="tb_width">
                        {e.dayAttendList[20] === 10 ? "" : e.dayAttendList[20]}
                      </td>
                      <td className="tb_width">
                        {e.dayAttendList[21] === 10 ? "" : e.dayAttendList[21]}
                      </td>
                      <td className="tb_width">
                        {e.dayAttendList[22] === 10 ? "" : e.dayAttendList[22]}
                      </td>
                      <td className="tb_width">
                        {e.dayAttendList[23] === 10 ? "" : e.dayAttendList[23]}
                      </td>
                      <td className="tb_width">
                        {e.dayAttendList[24] === 10 ? "" : e.dayAttendList[24]}
                      </td>
                      <td className="tb_width">
                        {e.dayAttendList[25] === 10 ? "" : e.dayAttendList[25]}
                      </td>
                      <td className="tb_width">
                        {e.dayAttendList[26] === 10 ? "" : e.dayAttendList[26]}
                      </td>
                      <td className="tb_width">
                        {e.dayAttendList[27] === 10 ? "" : e.dayAttendList[27]}
                      </td>
                      <td className="tb_width">
                        {e.dayAttendList[28] === 10 ? "" : e.dayAttendList[28]}
                      </td>
                      <td className="tb_width">
                        {e.dayAttendList[29] === 10 ? "" : e.dayAttendList[29]}
                      </td>
                      <td className="tb_width">
                        {e.dayAttendList[30] === 10 ? "" : e.dayAttendList[30]}
                      </td>
                      <td className="tb_width">
                        {e.dayAttendList[31] === 10 ? "" : e.dayAttendList[31]}
                      </td>
                      <td className="tb_width">{e.attendCnt}</td>
                      <td className="tb_width" colSpan={2}>
                        {e.manDay}
                      </td>
                      <td className={"table-right"}>
                        {e.salaryAmt.toLocaleString()}
                      </td>
                      <td className={"table-right"}>
                        {e.taxLocaIncome.toLocaleString()}
                      </td>
                      <td className={"table-right"}>
                        {e.taxHealthInsu.toLocaleString()}
                      </td>
                      <td className={"table-right"}>
                        {e.taxCareInsu.toLocaleString()}
                      </td>
                      <td className={"table-right"}>
                        {e.taxSum.toLocaleString()}
                      </td>
                      <td className={"table-right"}>
                        {e.paymentAmt.toLocaleString()}
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
                <tr>
                  <th colSpan={19} rowSpan={2} className="none-border">
                    총계
                  </th>
                  <th style={{ width: "28px", minWidth: "28px" }}></th>
                  <th style={{ width: "28px", minWidth: "28px" }}></th>
                  <th style={{ width: "28px", minWidth: "28px" }}></th>
                  <th className={"table-right"}>
                    {getLaborPaymentData?.total?.dayAmt?.toLocaleString()}
                  </th>
                  <th className={"table-right"}>
                    {getLaborPaymentData?.total?.taxIncome?.toLocaleString()}
                  </th>
                  <th className={"table-right"}>
                    {getLaborPaymentData?.total?.taxEmployInsu?.toLocaleString()}
                  </th>
                  <th className={"table-right"}>
                    {getLaborPaymentData?.total?.taxNationPension?.toLocaleString()}
                  </th>
                  <th className={"table-right"}>
                    {getLaborPaymentData?.total?.taxEtcAndUnion?.toLocaleString()}
                  </th>
                  <th className={"table-right"}>
                    {getLaborPaymentData?.total?.taxMeal?.toLocaleString()}
                  </th>
                </tr>
                <tr>
                  <th
                    className="none-border"
                    style={{ width: "28px", minWidth: "28px" }}
                  >
                    {getLaborPaymentData?.total?.attendCnt}
                  </th>
                  <th
                    colSpan={2}
                    className="none-border"
                    style={{ width: "56px", minWidth: "56px" }}
                  >
                    {getLaborPaymentData?.total?.manDay}
                  </th>
                  <th className={"table-right none-border"}>
                    {getLaborPaymentData?.total?.salaryAmt?.toLocaleString()}
                  </th>
                  <th className={"table-right none-border"}>
                    {getLaborPaymentData?.total?.taxLocaIncome?.toLocaleString()}
                  </th>
                  <th className={"table-right none-border"}>
                    {getLaborPaymentData?.total?.taxHealthInsu?.toLocaleString()}
                  </th>
                  <th className={"table-right none-border"}>
                    {getLaborPaymentData?.total?.taxCareInsu?.toLocaleString()}
                  </th>
                  <th className={"table-right none-border"}>
                    {getLaborPaymentData?.total?.taxSum?.toLocaleString()}
                  </th>
                  <th className={"table-right none-border"}>
                    {getLaborPaymentData?.total?.paymentAmt?.toLocaleString()}
                  </th>
                </tr>
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan={50}>
                    <EmptyContainer
                      selected={false}
                      falseText={`노무비 지급명세서 내역이`}
                      style={{ height: "400px", background: "#fff" }}
                    ></EmptyContainer>
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </TableWrapper>
      </TableStyle>

      <Modal
        opend={print}
        closeModal={() => setPrint(false)}
        header={"출력 미리보기"}
        okText={"출력"}
        handleOk={() => {
          //   handlePrint();
        }}
        overlayStyle={{ display: "none" }}
        widthCheck={"70%"}
      >
        <UserPaymentStatementPrint
          ref={componentRef}
          day={`조회기간 : ${moment(startDay).format("YYYY년 M월")} ~ ${moment(
            endDay
          ).format("YYYY년 M월")}`}
          data={data}
          setPrint={setPrint}
          check={check}
          getLaborPaymentData={getLaborPaymentData}
        ></UserPaymentStatementPrint>
      </Modal>
    </div>
  );
});

const Print = styled.div`
  @page {
    box-sizing: border-box;
    padding: 20px 24px 25px;
    size: A4 landscape !important;
    /* margin-top: 20px; */
    /* margin-bottom: 25px; */
  }
  @media print {
    padding: 20px 24px 25px;
    table th {
      background-color: #edf2f7 !important;
      -webkit-print-color-adjust: exact;
    }

    .checked {
      display: none;
    }
  }
`;

const Checked = styled.div`
  display: flex;
  column-gap: 15px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
`;
const Icon = styled.img`
  width: 16px;
`;
const Button = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
  min-height: 32px;
  padding: 0 9px;
  justify-content: center;
  align-items: center;
  gap: 9px;
  border-radius: 6px;
  border: 1.125px solid #e2e8f0;
  background: #fff;

  color: #4a5568;
  leading-trim: both;
  text-edge: cap;
  font-size: 14px;
  cursor: pointer;
`;

const TableWrapper = styled.div`
  .tb_width {
    width: 28px;
    min-width: 28px;
  }

  .th_width {
    width: 65px;
  }

  &&.first {
    th,
    td {
      /* text-align: left; */
      font-size: 14px;
      /* background-color: #fff; */
    }
  }

  th,
  td {
    font-size: 11px;
    text-align: center;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: center;
  position: relative;
  margin-top: 15px;
  span {
    font-size: 14px;
    position: absolute;
    right: 0;
    bottom: 0;
  }
`;
const Title = styled.div`
  color: #171923;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  /* line-height: 30px; */
  text-align: center;
  /* margin-bottom: 6px; */
`;
export default UserPaymentStatement;
