import React, { forwardRef, useEffect } from "react";
import styled from "@emotion/styled";
import { useRecoilValue } from "recoil";
import { companyState } from "../../../library/recoil/atoms/companyState";
import { createMarkup } from "../../../bin/common";
import moment from "moment/moment";
const ContractBizConstructPrint = forwardRef((props, ref) => {
  const { data, content } = props;
  const companyInfo = useRecoilValue(companyState);
  const handleImageError = (event) => {
    event.currentTarget.style.display = "none";
  };

  return (
    <Container ref={ref} className="print-portrait">
      <span className="title">
        {!data ? "근 로 계 약 서(건설일용근로자)" : data?.contractTitle}
      </span>
      <div>
        <table>
          <tbody>
            <tr></tr>
            <tr className="table-center">
              <th rowSpan="2">사업주</th>
              <th>상호</th>
              <td colSpan="3" className="border-right">
                {!data ? "일광종합개발" : data?.corpName}
              </td>
              <th>대표자</th>
              <td colSpan="3">{!data ? "넷대표1" : data?.ceoName}</td>
            </tr>
            <tr className="table-center">
              <th>주소</th>
              <td colSpan="7">
                {!data
                  ? "서울 종로구 경희궁길 28 (신문로2가, 서광빌딩) 서광빌딩"
                  : data?.corpAddr}
              </td>
            </tr>
            <tr className="table-center">
              <th rowSpan="2">근로자</th>
              <th>성명</th>
              <td colSpan="3" className="border-right">
                {!data ? "홍길동" : data?.userName}
              </td>
              <th>생년월일</th>
              <td colSpan="3">
                {!data ? "1982년 12월 25일" : data?.userBirthday}
              </td>
            </tr>
            <tr className="table-center">
              <th>주소</th>
              <td colSpan="3" className="border-right">
                {!data ? "서울시 중구 청계천로 10" : data?.userAddr}
              </td>
              <th>휴대전화번호</th>
              <td colSpan="3">
                {!data ? "010-9999-9999 " : data?.userPhoneNum}
              </td>
            </tr>
            <tr className="table-center">
              <td colSpan="9" style={{ fontWeight: 600 }}>
                “사업주”와 “근로자”는 아래의 근로조건을 성실히 이행할 것을
                약정하고 근로계약을 체결한다.
              </td>
            </tr>
            <tr className="table-center">
              <th>현장명</th>
              <td colSpan="4" className="border-right">
                {!data
                  ? "서울 숭례문 리모델링 전기공사 (예시)"
                  : data?.siteList}
              </td>
              <th>업무의내용</th>
              <td colSpan="3">{!data ? "전기 (예시)" : data?.workType}</td>
            </tr>
            <tr>
              <th>근무장소</th>
              <td colSpan="8">
                {!data
                  ? " 당일 근로 현장은 작업 지시에 따라 정해짐 (예시)"
                  : data?.siteAddr}
              </td>
            </tr>
            <tr>
              <th>계약기간</th>
              <td colSpan="4" className="border-right">
                {!data ? "2024년 1월 1일 부터 (예시)" : data?.contractPeriod}
              </td>
              <th>소정근로시간</th>
              <td colSpan="3">
                {!data
                  ? "08:00:00 ~ 17:00:00 (휴게시간: 09:00:00 ~ 13:00:00) (예시)"
                  : data?.workTime}
              </td>
            </tr>
            <tr>
              <th>근무일/휴일</th>
              <td colSpan="8" className="border-right">
                {!data
                  ? "매주 5일 (또는 매일 단위)근무, 주휴일 매주 토,일요일 (예시)"
                  : data?.workDate}
                <span style={{ float: "right" }}>
                  ※ 주휴일은 1주간 소정근로일을 모두 근로한 경우에 주당 1일을
                  유급으로 부여
                </span>
              </td>
            </tr>
            <tr style={{ borderBottom: "none" }}>
              <th rowSpan="2">임금</th>
              <td colSpan="8">
                "근로자"의 임금은 {!data ? "일급 200,000" : data?.salaryAmt}
                <br />
                임금은 {!data ? "근로월 기준 익월 10일" : data?.salaryDate}
                (휴일의 경우는 전일 지급)에 지급하며, 지급시 근로소득세 및
                고용보험료, 건강보험료, 국민연금 등 제세공과금을 원천징수 후
                지급한다.
              </td>
            </tr>
            <tr className="table-center">
              <td colSpan="2"></td>
              <th style={{ borderRight: "none" }}>은행</th>
              <td>{!data ? "신한" : data?.bankName}</td>
              <th style={{ borderRight: "none" }}>계좌</th>
              <td>{!data ? "1234567890" : data?.bankAccount}</td>
              <th style={{ borderRight: "none" }}>예금주</th>
              <td>{!data ? "홍길동" : data?.bankUserName}</td>
            </tr>

            <tr>
              <th rowSpan="6">급여산정 내역</th>
            </tr>
            <tr>
              <th rowSpan="2">임금구성 내역</th>
              <th rowSpan="2">임금합계</th>
              <th rowSpan="2">기본급여</th>
              <th colSpan="5">법정수당(일당)</th>
            </tr>
            <tr>
              <th>유급 휴일수당</th>
              <th>연차 유급수당</th>
              <th>휴일 근로수당</th>
              <th>연장근로수당</th>
              <th>야간 근로수당</th>
            </tr>
            <tr className="table-center">
              <th>적용기준</th>
              <td className="border-right">
                {!data ? "일당합계" : data?.salaryTotalTxt}
              </td>
              <td className="border-right">
                {!data ? "8시간/일" : data?.workTimeTxt}
              </td>
              <td className="border-right">
                1주 동안 소정
                <br />
                근로일 개근시
              </td>
              <td className="border-right">1개월 개근시</td>
              <td className="border-right">
                휴일작업
                <br />
                7시간(1주)
              </td>
              <td className="border-right">
                40시간 이상/주
                <br />
                8시간 이상/일
                <br />
                1시간(일)
              </td>
              <td>
                22:00~06:00 <br />
                근무시
              </td>
            </tr>
            <tr className="table-center">
              <th>금액산정</th>
              <td className="border-right"></td>
              <td className="border-right"></td>
              <td className="border-right" colSpan="2">
                기본급여 100%
              </td>
              <td colSpan="3">시간급여 50% 할증</td>
            </tr>
            <tr className="table-center">
              <th>금 액</th>
              <td className="border-right">
                {!data ? "200,000" : data?.totalAmt}원
              </td>
              <td className="border-right">
                {!data ? "117,612" : data?.defaultAmt}원
              </td>
              <td className="border-right">
                {!data ? "23,522" : data?.paidHolidayAmt}원
              </td>
              <td className="border-right">
                {!data ? "5,940" : data?.annualLeaveAmt}원
              </td>
              <td className="border-right">
                {!data ? "30,873" : data?.holidayWorkAmt}원
              </td>
              <td className="border-right">
                {!data ? "22,053" : data?.overTimeWorkAmt}원
              </td>
              <td className="border-right"></td>
            </tr>
            <tr>
              <th colSpan="9">상세근로조건</th>
            </tr>
            <tr>
              <td className="detail" colSpan="9">
                <span
                  dangerouslySetInnerHTML={createMarkup(
                    !data ? content : data?.workConditions
                  )}
                ></span>
              </td>
            </tr>
            <tr style={{ borderBottom: "none" }}>
              <th rowSpan="2">
                개인정보 <br />
                제공
              </th>
              <td colSpan="8">
                개인정보(성명, 주민등록번호, 주소, 급여, 전화번호)를 발주기관의
                안전, 노무, 현장 출입관련업무 담당에게 제공/활용에 이의 없이
                동의합니다.
              </td>
            </tr>
            <tr>
              <td colSpan="8" style={{ textAlign: "right" }}>
                <div className="value">
                  개인정보 제공 동의자 성명{" "}
                  <em style={{ margin: "0 10px" }}>
                    {!data ? "홍길동" : data?.userName}
                  </em>
                  <em>동의함</em>
                </div>
              </td>
            </tr>
            <tr>
              <th rowSpan="2">
                안전장구
                <br />및 안전교육
              </th>
              <th>안전조끼</th>
              <th>안전모</th>
              <th>안전화</th>
              <th>안전벨트</th>
              <th>보호안경</th>
              <th>안전교육</th>
              <th>혈액형</th>
              <th>기타()</th>
            </tr>
            <tr className="table-center">
              <td className="border-right">수령함</td>
              <td className="border-right">수령함</td>
              <td className="border-right">수령함</td>
              <td className="border-right">수령함</td>
              <td className="border-right">수령함</td>
              <td className="border-right">받았음</td>
              <td className="border-right">{!data ? "AB" : data?.bloodType}</td>
              <td></td>
            </tr>
            <tr>
              <td className="text-center" colSpan="9">
                근로계약서를 교부받았음을 확인합니다
              </td>
            </tr>
            <tr>
              <td colSpan="9" className="text-center">
                {!data
                  ? moment(new Date()).format("YYYY년 M월 D일")
                  : data?.contractDate}
              </td>
            </tr>
          </tbody>
        </table>
        <table className="signature">
          <tbody>
            <tr>
              <th colSpan="3">사업주</th>
              <th colSpan="3">근로자</th>
            </tr>

            <tr>
              <th>대표자</th>
              <td colSpan="2" className="border-right">
                <div className="value">
                  {!data ? "넷대표1" : data?.managerName}
                  <em>
                    (인)
                    {data?.corpStamp && (
                      <img
                        src={data?.corpStamp}
                        onError={handleImageError}
                        alt="img"
                      />
                    )}
                  </em>
                </div>
              </td>
              <th>성명</th>
              <td colSpan="2">
                <div className="value">
                  {!data ? "홍길동" : data?.userName}
                  <em>
                    (인)
                    {data?.userStamp && (
                      <img
                        src={data?.userStamp}
                        onError={handleImageError}
                        alt="img"
                      />
                    )}
                  </em>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Container>
  );
});

const Container = styled.div`
  // border: 1px solid #E2E8F0;
  background: #fff;
  padding: 20px 24px;
  display: none;
  @page {
    /* size: A4 portrait !important; */
    margin-top: 20px;
    margin-bottom: 25px;
  }

  @media print {
    table th {
      background-color: #edf2f7 !important;
      -webkit-print-color-adjust: exact;
    }

    .table-wrapper {
      //break-inside: auto;
      break-after: page;
    }

    display: flex;
    row-gap: 10px;
    flex-direction: column;
    align-items: center;
  }

  .detail > span {
    display: block !important;
    font-size: 8px;
    min-height: 562px !important;
    //min-height: calc(100vh - 520px) !important;
  }

  .border-right {
    border-right: 1px solid #000;
  }

  .signature .value {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  span {
    color: #363636;
    font-size: 9px;
  }

  .title {
    font-size: 20px;
    font-weight: 600;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
  }

  table tbody {
    width: 100%;
    border: 1px solid #000;
  }

  table tbody tr {
    border-bottom: 1px solid #000;
  }

  table th {
    border-right: 1px solid #000;
    font-size: 9px;
    font-weight: 600;
    background-color: #f1f1f1;
    text-align: center;
    width: 40%;
    padding: 2px 0;
    color: #363636;
  }

  table td {
    color: #363636;
    font-size: 9px;
    background-color: #ffffff;
    text-align: left;
    padding: 2px 2px;
  }

  .table-center td,
  .text-center {
    text-align: center;
  }

  table .detail {
    padding: 8px;
  }

  .signature tbody {
    border-top: none;
  }

  .signature tbody td {
    padding: 2px 8px;
  }

  .signature img {
    height: 40px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  .value em {
    font-weight: 600;
    font-style: normal;
    position: relative;
  }

  .table-center td,
  .text-center {
    text-align: center;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const UserWrap = styled.div``;
export default ContractBizConstructPrint;
