import styled from "@emotion/styled";
import ReactModal from "react-modal";
import { ModalButton } from "./ModalButton.js";
import { useWindowSize } from "react-use";
import { useEffect, useState } from "react";

const customStyles = {
  overlay: {
    position: "fixed",
    width: "100vw",
    height: "100vh",
    background: "rgba(0, 0, 0, 0.4)",
    zIndex: 9998,
    display: "flex",
    justifyContent: "center",
    // alignItems: 'center',
  },
  content: {
    position: "relative",
    maxWidth: "100%",
    width: "100%",
    background: "rgba(0,0,0,0.4)",
    height: "100%",
    maxHeight: "100%",
    top: "auto",
    left: "auto",
    right: "auto",
    bottom: "auto",
    boxSizing: "border-box",
    // borderRadius: '8px',
    border: "none",
    overflow: "auto",
    outline: "none",
  },
};

function ImageEnlargedModal({
  children,
  opend,
  closeModal,
  handleImageIndex,
  imageIndex,
  imageLength,
}) {
  const [viewButton, setViewButton] = useState({
    left: true,
    right: true,
  });

  useEffect(() => {
    if (imageLength === 1) {
      setViewButton({ left: false, right: false });
    } else if (imageIndex === imageLength) {
      setViewButton({ left: true, right: false });
    } else if (imageIndex === 1) {
      setViewButton({ left: false, right: true });
    } else {
      setViewButton({ left: true, right: true });
    }
  }, [imageIndex, imageLength]);
  const { width } = useWindowSize();

  return (
    <ReactModal
      isOpen={opend}
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick={false}
      style={{
        overlay: customStyles.overlay,
        content: {
          ...customStyles.content,
          ...(width < 992
            ? {
                marginTop: 0,
                width: "100%",
                maxWidth: "100%",
                height: "100%",
                maxHeight: "100%",
              }
            : {}),
        },
      }}
      ariaHideApp={false}
    >
      <Padding>
        <ButtonGroup>
          <ModalButton
            style={{ backgroundColor: "transparent" }}
            onClick={closeModal}
          >
            <img
              style={{ filter: "invert(100%)", width: "24px", height: "24px" }}
              src="/assets/icons/left-icon.svg"
            />
          </ModalButton>
        </ButtonGroup>
        <Content>
          <ModalButton
            style={{
              borderRadius: "50%",
              width: "50px",
              height: "60px",
              marginLeft: "0",
              marginRight: "17px",
              opacity: `${viewButton.left ? 1 : 0}`,
            }}
            onClick={() => handleImageIndex("left")}
          >
            <img
              style={{
                transform: "rotate(180deg)",
                width: "30px",
                height: "30px",
              }}
              src="/assets/icons/chevron-right.svg"
            />
          </ModalButton>
          {children}
          <ModalButton
            style={{
              borderRadius: "50%",
              width: "50px",
              height: "60px",
              opacity: `${viewButton.right ? 1 : 0}`,
            }}
            onClick={() => handleImageIndex("right")}
          >
            <img
              style={{ width: "30px", height: "30px" }}
              src="/assets/icons/chevron-right.svg"
            />
          </ModalButton>
        </Content>
      </Padding>
    </ReactModal>
  );
}

const Padding = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  color: white;
  margin-top: 30px;
  margin-bottom: -25px;
  padding: 6px 12px;

  & > div {
    width: 33.3%;

    &:nth-of-type(2) {
      text-align: center;
    }
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: calc(100vh - 80px);
  // overflow-y: auto;
  overflow: hidden;
  padding: 12px 24px;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
`;

const SidebarButton = styled.img`
  cursor: pointer;
`;

export default ImageEnlargedModal;
