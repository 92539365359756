import styled from "@emotion/styled";
import dayjs from "dayjs";
import React, { forwardRef } from "react";
import { addCommas } from "../../../bin/common";
import { TablePrintStyle } from "../../../library/styled-components";

const tdCustomStyle = { textAlign: "right", paddingRight: "3px" };
const attendDayStyle = { width: "15px" };

const MonthlyPaymentStatementPagePrint = forwardRef((props, ref) => {
  const selectedDate = props.selectedDate;
  const selectedSiteNm = props.selectedSiteNm;
  const list = props.data;

  return (
    <Container ref={ref} className="print-landscape">
      <Header>
        <div>
          <Title>{dayjs(selectedDate).format("YYYY년 M월분")} 노무현황</Title>
          <HeaderGroup>
            <Badge>
              <Label>기간</Label>
              <Text>
                {`${dayjs(selectedDate)
                  .startOf("month")
                  .format("YYYY년 M월 D일")} 
                       ~ ${dayjs(selectedDate)
                         .endOf("month")
                         .format("YYYY년 M월 D일")}`}{" "}
              </Text>
            </Badge>

            <Badge>
              <Label>공사명</Label>
              <Text>
                {list.paymentStatementList.length > 1
                  ? ""
                  : list.paymentStatementList[0].site.const_nm}
              </Text>
            </Badge>

            <Badge>
              <Label>현장명</Label>
              <Text>{selectedSiteNm}</Text>
            </Badge>
          </HeaderGroup>
        </div>

        <div></div>
      </Header>

      <TablePrintStyle>
        <div className="table_wrapper">
          <table id={"list-table"}>
            <thead style={{ height: "unset" }}>
              <tr>
                <th rowSpan={2}>현장명</th>
                <th rowSpan={2}>년월</th>
                <th rowSpan={2}>인원</th>
                <th rowSpan={2}>일수</th>
                <th rowSpan={2}>공수</th>
                <th rowSpan={2}>노무비</th>

                <th rowSpan={1}> 소득세</th>
                <th rowSpan={1}> 고용보험</th>
                <th rowSpan={1}> 건강보험</th>
                <th rowSpan={1}> 기타</th>

                <th rowSpan={2}> 공제계</th>
                <th rowSpan={2}> 식대</th>
                <th rowSpan={2}> 실지급액</th>
              </tr>
              <tr>
                <th rowSpan={1}> 지방소득세</th>
                <th rowSpan={1}> 국민연금</th>
                <th rowSpan={1}> 요양보험</th>
                <th rowSpan={1} className={"print_border"}>
                  {" "}
                  노조/분회비
                </th>
              </tr>
            </thead>
            <tbody>
              {list?.paymentStatementList?.map((paymentStatus, index) => {
                return (
                  <React.Fragment key={index}>
                    <tr>
                      <td style={{ textAlign: "left" }} rowSpan={2}>
                        {paymentStatus?.site?.site_nm}
                      </td>
                      <td rowSpan={2}>
                        {dayjs(selectedDate).format("YY년 MM월")}
                      </td>
                      <td rowSpan={2}>{paymentStatus?.attendList.seq}</td>
                      <td rowSpan={2}>
                        {paymentStatus?.attendList.attend_cnt}
                      </td>
                      <td rowSpan={2}>{paymentStatus?.attendList.man_day}</td>
                      <td rowSpan={2} className={"table-right"}>
                        {addCommas(paymentStatus?.attendList.salary_amt)}
                      </td>
                      <td className={"table-right"}>
                        {addCommas(paymentStatus?.attendList.tax1)}
                      </td>
                      <td className={"table-right"}>
                        {addCommas(paymentStatus?.attendList.tax2)}
                      </td>
                      <td className={"table-right"}>
                        {addCommas(paymentStatus?.attendList.tax7)}
                      </td>
                      <td className={"table-right"}>
                        {addCommas(paymentStatus?.attendList.tax4)}
                      </td>
                      <td rowSpan={2} className={"table-right"}>
                        {addCommas(paymentStatus?.attendList.tax9)}
                      </td>
                      <td rowSpan={2} className={"table-right"}>
                        {addCommas(paymentStatus?.attendList.tax5)}
                      </td>
                      <td rowSpan={2} className={"table-right"}>
                        {addCommas(paymentStatus?.attendList.real_salary_amt)}
                      </td>
                    </tr>
                    <tr>
                      <td className={"table-right"}>
                        {addCommas(paymentStatus?.attendList.tax6)}
                      </td>
                      <td className={"table-right"}>
                        {addCommas(paymentStatus?.attendList.tax3)}
                      </td>
                      <td className={"table-right"}>
                        {addCommas(paymentStatus?.attendList.tax8)}
                      </td>
                      <td className={"print_border table-right"}>
                        {addCommas(paymentStatus?.attendList.tax4)}
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
              {
                <>
                  <tr className="none">
                    <th rowSpan={2} colSpan={2}>
                      합 계
                    </th>
                    <th rowSpan={2}>{list?.total?.seq}</th>
                    <th rowSpan={2}>{list?.total?.attend_cnt}</th>
                    <th rowSpan={2}>{list?.total?.man_day}</th>
                    <th rowSpan={2} className={"table-right"}>
                      {addCommas(list?.total?.salary_amt)}
                    </th>
                    <th
                      className={"table-right"}
                      style={{ borderBottom: "1px solid #000" }}
                    >
                      {addCommas(list?.total?.tax1)}
                    </th>
                    <th
                      className={"table-right"}
                      style={{ borderBottom: "1px solid #000" }}
                    >
                      {addCommas(list?.total?.tax2)}
                    </th>
                    <th
                      className={"table-right"}
                      style={{ borderBottom: "1px solid #000" }}
                    >
                      {addCommas(list?.total?.tax7)}
                    </th>
                    <th
                      className={"table-right"}
                      style={{ borderBottom: "1px solid #000" }}
                    >
                      {addCommas(list?.total?.tax4)}
                    </th>
                    <th rowSpan={2} className={"table-right"}>
                      {addCommas(list?.total?.tax9)}
                    </th>
                    <th rowSpan={2} className={"table-right"}>
                      {addCommas(list?.total?.tax5)}
                    </th>
                    <th rowSpan={2} className={"table-right "}>
                      {addCommas(list?.total?.real_salary_amt)}
                    </th>
                  </tr>
                  <tr className="none">
                    <th className={"table-right "}>
                      {addCommas(list?.total?.tax6)}
                    </th>
                    <th className={"table-right "}>
                      {addCommas(list?.total?.tax3)}
                    </th>
                    <th className={"table-right "}>
                      {addCommas(list?.total?.tax8)}
                    </th>
                    <th className={"table-right print_border"}>
                      {addCommas(list?.total?.tax4)}
                    </th>
                  </tr>
                </>
              }
            </tbody>
          </table>
        </div>
      </TablePrintStyle>
    </Container>
  );
});

const Container = styled.div`
  //border: 1px solid #E2E8F0;
  border: 1px solid #ffffff;
  background: #fff;
  padding: 20px 24px;

  @page {
    /* size: A4 landscape !important; */
    margin-top: 20px;
    margin-bottom: 25px;
    //size: landscape; // 가로 방향으로 설정
  }
  @media print {
    .table-wrapper {
      //break-inside: auto;
      break-after: page;
    }

    table th {
      background-color: #edf2f7 !important;
      -webkit-print-color-adjust: exact;
    }
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const Title = styled.div`
  color: #171923;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 6px;
`;

const HeaderGroup = styled.div`
  display: flex;
`;

const Badge = styled.div`
  display: flex;
  padding: 4px 6px;
  align-items: flex-start;
  border-radius: 6px;
  background: #f7fafc;

  &:not(:last-child) {
    margin-right: 4px;
  }
`;

const Label = styled.div`
  color: #171923;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 8px;
  margin-right: 4px;
`;

const Text = styled.div`
  color: #171923;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 8px;
`;

export default MonthlyPaymentStatementPagePrint;
